import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const customersAtom = atomWithStorage("customers", []);

export const selectedCustomersAtom = atom([]);

export const deleteCustomerAtom = atom(null, (get, set, customerId) => {
  const customers = get(customersAtom);
  const updatedCustomers = customers.filter(
    (customer) => customer.id !== customerId
  );
  set(customersAtom, updatedCustomers);
});

export const bulkDeleteCustomersAtom = atom(null, (get, set, customerIds) => {
  const customers = get(customersAtom);
  const updatedCustomers = customers.filter(
    (customer) => !customerIds.includes(customer.id)
  );
  set(customersAtom, updatedCustomers);
  set(selectedCustomersAtom, []); // Clear selections after bulk deletion
});

export const updateCustomerAtom = atom(null, (get, set, updatedCustomer) => {
  const customers = get(customersAtom);
  const updatedCustomers = customers.map((customer) =>
    customer.id === updatedCustomer.id ? updatedCustomer : customer
  );
  set(customersAtom, updatedCustomers);
});

export const rowsPerPageAtom = atomWithStorage("customerRowsPerPage", 5);
