import axiosWithAuth from "../../utils/axiosWithAuth";

export const getEstimates = async () => {
  const response = await axiosWithAuth.get("/api/estimate");
  return response.data;
};

export const getEstimate = async (id) => {
  const response = await axiosWithAuth.get(`/api/estimate/${id}`);
  return response.data;
};

export const createEstimate = async (estimateData) => {
  return await axiosWithAuth.post("/api/estimate", estimateData);
};

export const updateEstimate = async (id, estimateData) => {
  return await axiosWithAuth.put(`/api/estimate/${id}`, estimateData);
};

export const deleteEstimate = async (id) => {
  return await axiosWithAuth.delete(`/api/estimate/${id}`);
};

export const sendEstimate = async (estimateData) => {
  return await axiosWithAuth.post("/api/estimate/send", estimateData);
};

export const downloadEstimate = async (id) => {
  return await axiosWithAuth.get(`/api/estimate/${id}/download`, {
    responseType: "blob",
  });
};
