import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
	currentQRCodeAtom,
	qrCodeAtom,
	savedQRCodesAtom,
} from "../atoms/qrCodeAtoms";
import InstructionsAndFAQ from "../components/InstructionsAndFAQ";
import ColorCustomizer from "../customizers/ColorCustomizer";
import LogoCustomizer from "../customizers/LogoCustomizer";
import QRStyleCustomizer from "../customizers/QRStyleCustomizer";
import JPGDownloadButton from "../download/JPGDownloadButton";
import FrameWrapper from "../frames/FrameWrapper";
import QRCodePreview from "../QRCodePreview";

const QRCodeLayout = () => {
	const [searchParams] = useSearchParams();
	const [qrCodeState, setQRCodeState] = useAtom(qrCodeAtom);
	const [savedQRCodes] = useAtom(savedQRCodesAtom);
	const setCurrentQRCode = useSetAtom(currentQRCodeAtom);

	const frameRef = useRef(null);

	useEffect(() => {
		const qrId = searchParams.get("qrId");
		if (qrId) {
			const savedQRCode = savedQRCodes.find((qr) => qr.id === qrId);
			if (savedQRCode) {
				setCurrentQRCode(savedQRCode);
				setQRCodeState({
					currentData: savedQRCode,
					name: savedQRCode.name || "",
					style: savedQRCode.style || "dots",
					color: savedQRCode.color || "#000000",
					logo: null,
					frameName: savedQRCode.frameName || "none",
				});
			}
		}
	}, [searchParams, savedQRCodes, setCurrentQRCode, setQRCodeState]);

	const handleGenerateQRCode = (data) => {
		setQRCodeState((prevState) => ({
			...prevState,
			currentData: {
				...data,
				color: prevState.color,
				style: prevState.style,
			},
		}));
	};

	const handleFrameChange = (newFrameName) => {
		setQRCodeState((prevState) => ({
			...prevState,
			frameName: newFrameName,
		}));
	};

	const handleStyleChange = (newStyle) => {
		setQRCodeState((prevState) => ({
			...prevState,
			style: newStyle,
			currentData: prevState.currentData
				? {
						...prevState.currentData,
						style: newStyle,
				  }
				: null,
		}));
	};

	const handleColorChange = (newColor) => {
		setQRCodeState((prevState) => ({
			...prevState,
			color: newColor,
			currentData: prevState.currentData
				? {
						...prevState.currentData,
						color: newColor,
				  }
				: null,
		}));
	};

	const handleLogoChange = (logoDataURL) => {
		setQRCodeState((prevState) => ({
			...prevState,
			logo: logoDataURL,
		}));
	};

	return (
		<Row>
			<Col md={7}>
				<div className="qr-form-container">
					<Outlet
						context={{
							onGenerate: handleGenerateQRCode,
							initialData: qrCodeState?.currentData?.data,
						}}
					/>
				</div>
				<InstructionsAndFAQ />
			</Col>
			<Col md={5}>
				<Card className="preview-card">
					<CardBody>
						<h4 className="text-center mb-4">QR Code Preview</h4>
						<div className="preview-container">
							<FrameWrapper
								ref={frameRef}
								onFrameChange={handleFrameChange}
								initialFrameName={qrCodeState.frameName}
							>
								<QRCodePreview
									value={qrCodeState.currentData?.data}
									color={qrCodeState.color}
									logo={qrCodeState.logo}
									qrStyle={qrCodeState.style}
								/>
							</FrameWrapper>
						</div>

						<div className="accordion-style mt-4">
							<div className="customizer-section">
								<h5>FRAME</h5>
							</div>

							<div className="customizer-section">
								<h5>SHAPE & COLOR</h5>
								<QRStyleCustomizer
									qrStyle={qrCodeState.style}
									onQRStyleChange={handleStyleChange}
								/>
								<ColorCustomizer
									initialColor={qrCodeState.color}
									onColorChange={handleColorChange}
								/>
							</div>

							<div className="customizer-section">
								<h5>LOGO</h5>
								<LogoCustomizer
									onLogoChange={handleLogoChange}
								/>
							</div>
						</div>

						{qrCodeState.currentData && (
							<div className="download-section mt-4">
								<JPGDownloadButton />
							</div>
						)}
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default QRCodeLayout;
