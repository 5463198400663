import { toast } from "react-toastify";
import axiosWithAuth from "../../utils/axiosWithAuth";

export const fetchExpenses = async () => {
  try {
    const response = await axiosWithAuth.get("/api/expense");
    if (!response.data) throw new Error("Failed to fetch expenses");
    return response.data;
  } catch (error) {
    toast.error("Error fetching expenses:", error);
    throw error;
  }
};

export const createExpense = async (expenseData) => {
  try {
    const response = await axiosWithAuth.post(
      "/api/expense/create",
      expenseData
    );
    if (!response.data) throw new Error("Failed to create expense");
    return response.data;
  } catch (error) {
    toast.error("Error creating expense:", error);
    throw error;
  }
};

export const updateExpense = async (expenseId, expenseData) => {
  try {
    const response = await axiosWithAuth.put(
      `/api/expense/update/${expenseId}`,
      expenseData
    );
    if (!response.data) throw new Error("Failed to update expense");
    return response.data;
  } catch (error) {
    toast.error("Error updating expense:", error);
    throw error;
  }
};

export const deleteExpense = async (expenseId) => {
  try {
    const response = await axiosWithAuth.delete(
      `/api/expense/delete/${expenseId}`
    );
    if (!response.data) throw new Error("Failed to delete expense");
    return response.data;
  } catch (error) {
    toast.error("Error deleting expense:", error);
    throw error;
  }
};

export const uploadReceipt = async (expenseId, file, token) => {
  try {
    const formData = new FormData();
    formData.append("receipt", file);

    const response = await axiosWithAuth.post(
      `/api/expense/upload-receipt/${expenseId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to upload receipt");
    }

    return await response.json();
  } catch (error) {
    toast.error("Error uploading receipt: " + error.message);
    throw error;
  }
};
