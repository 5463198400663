import { Card, CardBody } from "reactstrap";
import NavigationGrid from "./components/NavigationGrid";
import QRCodeLoadForm from "./components/QRCodeLoadForm";
import QRCodeLayout from "./layouts/QRCodeLayout";
import "./styles/qr-generator.css";

const QR = () => {
  return (
    <div className="qr-generator">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="h3 mb-0">QR Code Generator</h1>
        <QRCodeLoadForm />
      </div>
      <Card className="shadow-sm">
        <CardBody>
          <NavigationGrid />
          <QRCodeLayout></QRCodeLayout>
        </CardBody>
      </Card>
    </div>
  );
};

export default QR;
