import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { useAuthContext } from "../../hooks/useAuthContext";

const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const { user } = useAuthContext();
  const stripeCustomerId = user?.stripeCustomerId || null;

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/auth/allSubscriptions?stripeCustomerId=${stripeCustomerId}`
        );
        setSubscriptions(response.data.data);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    if (stripeCustomerId) {
      fetchSubscriptions();
    }
  }, [stripeCustomerId]);

  const handleCancelSubscription = async (subscriptionId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/auth/cancel-subscription/${subscriptionId}`);
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.filter((sub) => sub.id !== subscriptionId)
      );
      alert("Subscription canceled successfully.");
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("Failed to cancel the subscription.");
    }
  };

  return (
    <Card>
      <CardBody>
        <h4>Active Subscriptions</h4>
        <hr />
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {subscriptions.length > 0 ? (
            subscriptions.map((subscription) => (
              <Card key={subscription.id} className="mb-3 p-3">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div>
                    <h5>{subscription.metadata?.productName || "No Product Name"}</h5>
                    <p><strong>Status:</strong> {subscription.status}</p>
                    <p><strong>Start Date:</strong> {new Date(subscription.start_date * 1000).toLocaleDateString()}</p>
                    <p><strong>Next Bill Date:</strong> {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                  </div>
                  <Button
                    color="danger"
                    onClick={() => handleCancelSubscription(subscription.id)}
                  >
                    Cancel Subscription
                  </Button>
                </div>
              </Card>
            ))
          ) : (
            <p>No active subscriptions found.</p>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Subscription;
