require("dotenv").config()
// let API_BASE_URL;
// if (process.env.NODE_ENV === 'development') {
//   API_BASE_URL = process.env.REACT_APP_API_BASE_URL_LOCAL;
// } else {
//   API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// }

// const API_BASE_URL =  "http://localhost:8000"
const API_BASE_URL =   process.env.REACT_APP_API_BASE_URL

export default API_BASE_URL;