import { useNavigate } from 'react-router-dom';
import Method from "../../components/Billing/Method";
import History from "../../components/Billing/History";
import Discounts from "../../components/Billing/Discounts";
import Subscription from "../../components/Billing/Subscription";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  Card,
  CardBody,
  Button,
  Col,
  Row,
  Alert
} from "reactstrap";


const Billing = () => {
  const navigate = useNavigate();  
  const { user } = useAuthContext();

  console.log("user paymentStatus", user.paymentStatus )

  const handleClick = () => {
    navigate('/support');
  };

  return (
    <>
      <h3>Billing Overview</h3>
      <hr />
      {user.paymentStatus === 'paid' ? (
        <Alert color="warning">
          <h5 className="alert-heading">
            
            NOTE: You will be charged at the time that you send messages, purchase a new phone number, or when registering your brand. For more details, 
            <a href="/billing-info" style={{ textDecoration: 'underline', marginLeft: '5px' }}>click here.</a>
          </h5>
        </Alert>
      ) : (
        <Alert color="danger">
          <h5 className="alert-heading">
          IMPORTANT: To get started with creating campaigns, please ensure that your payment method is set up first. This step is required to enable the sending and receiving of messages. 
          </h5>
        </Alert>
      )}

      <Row>
        <Col xs="6">
         
          <Method />
        
        </Col>

        <Col xs="6">
          <Subscription />

        </Col>
      </Row>

      <Row>
        <Col xs="12">
         <History />
        </Col>

       
      </Row>

      <Row>
          <Col xs="6">
            <Discounts />
          </Col>
          <Col xs="6">
            <Card style={{ flex: 1 }}>
              <CardBody className="custom-card-body">
                <h4>Help & Support</h4>
                <hr />
                <div>
                  Have questions about your billing? For assistance with invoices,
                  payment issues, or understanding your charges, we're here to help.
                </div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '40px'  }}>
                  <Button onClick={handleClick}>Contact Us</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default Billing