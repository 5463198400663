import { useContext, useState } from "react";

import axios from "axios";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../apiConfig";
import { AuthContext } from "../../context/AuthContext";
import { isEmail, isEmpty } from "../helper/validate";
import Input from "../Input/Input";
import "./login.css";
const placeholderImage = require('./blank_avatar.png');


const initialState = {
  name: "",
  password: "",
};

const Login = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const { email, password } = data;
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const login = async (e) => {
    e.preventDefault();
    // check fields

    if (isEmpty(email) || isEmpty(password))
      return toast("Please fill in all fields.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check email
    if (!isEmail(email))
      return toast("Please enter a valid email address.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    try {
      const res = await axios.post(`${API_BASE_URL}/api/auth/signing`, { email: email.toLowerCase(), password });
      if (res.status == 200) {
        const phoneNumber = res.data.data.phone

        const token = res.data.data.token;

        dispatch({ type: "AUTHENTICATION_VERIFIED" });

        // bypas 2FA
        localStorage.setItem("_appSignging", true);
        localStorage.setItem("_apprftoken", token);
        dispatch({ type: "SIGNING" });
        navigate("/");

        // Original Code
      //   navigate('/auth/verify', { state: { phoneNumber, token: token } });
      //   try {
      //     await axios.post(`${API_BASE_URL}/api/auth/verify`, { phoneNumber });
      //   } catch (error) {
      //     console.error(error);
      //   }
      }
    } catch (err) {
      toast(err.response.data.message, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="authInputArea">


        <div style={{ minHeight: '650px', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <img 
            src={placeholderImage} 
            alt="HelperFox Log In" 
            style={{
              width: '150px',
              height: '150px',
              borderRadius: '50%',
              objectFit: 'cover',
            }} 
          />
        
        </div>
        <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Log into your business account</h1>


        
            <form onSubmit={login}>
              <Input
                type="email"
                text="Email"
                name="email"
                handleChange={handleChange}
              />
              <Input
                name="password"
                type={visible ? "text" : "password"}
                icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                text="Password"
                handleClick={handleClick} 
                handleChange={handleChange}
              />
              <div className="login_btn">
                <button type="submit">login</button>
              </div>
            </form>
            </div>
    </div>
    </>
  ); 
};

export default Login;
