import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
} from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { useAuthContext } from "../../hooks/useAuthContext";
import Logo from "./Logo";
import LogoSmall from "./LogoSmall";

const placeholderImage = require("./blank_avatar.png");

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, dispatch } = useAuthContext();

  const [previewSource, setPreviewSource] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.avatar) {
      setPreviewSource(user?.avatar);
    } else {
      setPreviewSource(placeholderImage.default);
    }
  }, [user?.avatar]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.get(`${API_BASE_URL}/api/auth/signout`);
      localStorage.removeItem("_appSignging");
      localStorage.removeItem("_apprftoken");
      dispatch({ type: "SIGNOUT" });
      navigate("/auth");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Navbar
      color="white"
      light
      expand="md"
      className="fix-header position-sticky top-0 z-3 shadow-sm"
      style={{
        height: "63px",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="d-lg-block d-none me-5 pe-3">
          <Logo />
        </div>
        <div className="mobile-logo">
          <NavbarBrand href="/">
            <LogoSmall />
          </NavbarBrand>
        </div>
        <Button
          color="primary"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          Menu
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x">...</i>
          ) : (
            <i className="bi bi-three-dots-vertical">...</i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen} className="mobile-collapse">
        <Nav className="ms-auto" navbar>
          <NavItem>
            {user && user.first_name ? (
              <div>
                <span>
                  <strong>Hello </strong>
                  {user.first_name.charAt(0).toUpperCase() +
                    user.first_name.slice(1).toLowerCase()}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  <strong>Hello </strong>Guest
                </span>
              </div>
            )}
          </NavItem>
        </Nav>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
              src={placeholderImage}
              alt="profile"
              className="rounded-circle"
              style={{ width: "39px", height: "39px" }}
            />
          </DropdownToggle>
          <DropdownMenu style={{ width: "200px", padding: "10px" }}>
            <DropdownItem
              header
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Info
            </DropdownItem>

            <DropdownItem style={{ padding: "10px 20px" }}>
              <Link
                to="/business-details"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "16px",
                }}
              >
                Business Details
              </Link>
            </DropdownItem>

            <DropdownItem style={{ padding: "10px 20px" }}>
              <Link
                to="/account"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "16px",
                }}
              >
                Account
              </Link>
            </DropdownItem>

            <DropdownItem style={{ padding: "10px 20px" }}>
              <Link
                to="/billing"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "16px",
                }}
              >
                Billing
              </Link>
            </DropdownItem>

            <DropdownItem style={{ padding: "10px 20px" }}>
              <Link
                to="/support"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "16px",
                }}
              >
                Help
              </Link>
            </DropdownItem>

            <DropdownItem
              onClick={handleLogout}
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
      <ToastContainer />
    </Navbar>
  );
};

export default Header;
