import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const AddBusinessDetails = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          How to Add Your Business Details in HelperFox
        </CardTitle>

        <CardText tag="p" style={{ lineHeight: '1.6' }}>
          Setting up your business profile is essential for professional invoicing. Follow these steps to add your business details in HelperFox.
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          Steps to Add Business Details:
        </CardTitle>
        <CardText>
          <ol>
            <li>Go to **Settings** and select **Business Details**.</li>
            <li>Enter your **business name, address, phone number, and email**.</li>
            <li>Upload your **company logo** for branded invoices.</li>
            <li>Save changes to update your business profile.</li>
          </ol>
        </CardText>

        <CardText>
          Once set up, your details will automatically appear on invoices and estimates.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default AddBusinessDetails;
