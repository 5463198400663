import { Button } from "reactstrap";

const JPGDownloadButton = ({ getDataURL }) => {
	const handleDownload = async () => {
		const dataURL = await getDataURL();
		const link = document.createElement("a");
		link.href = dataURL;
		link.download = "qrcode.jpg";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Button color="primary" block className="mb-2" onClick={handleDownload}>
			<i className="bi bi-download me-2"></i>
			DOWNLOAD
			<small className="ms-2">JPG</small>
		</Button>
	);
};

export default JPGDownloadButton;
