import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { addExpenseAtom, updateExpenseAtom } from "../../atoms/expensesAtom";
import { useAuth } from "../../context/AuthContext";
import { uploadReceipt } from "../../lib/api/expense";

const ExpenseModal = ({ isOpen, toggle, expense, isEditing, onSave }) => {
  const [formData, setFormData] = useState({
    date: "",
    merchant: "",
    amount: "",
    receipt: null,
    category: "",
    card: "",
    client: "",
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [receiptPreview, setReceiptPreview] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [receiptFile, setReceiptFile] = useState(null);

  const [, addExpense] = useAtom(addExpenseAtom);
  const [, updateExpense] = useAtom(updateExpenseAtom);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: 5242880, // 5MB
    onDrop: handleFileDrop,
  });

  const { token } = useAuth();

  useEffect(() => {
    if (expense) {
      const formattedDate = expense.date
        ? new Date(expense.date).toISOString().split("T")[0]
        : "";

      setFormData({
        ...expense,
        date: formattedDate,
        amount: expense.amount ? expense.amount.toString() : "",
      });

      if (expense.receipt?.url) {
        setReceiptPreview(expense.receipt.url);
      }
    } else {
      resetForm();
    }
  }, [expense]);

  function resetForm() {
    setFormData({
      date: "",
      merchant: "",
      amount: "",
      receipt: null,
      category: "",
      card: "",
      client: "",
    });
    setReceiptPreview(null);
    setUploadProgress(0);
    setUploadError(null);
    setReceiptFile(null);
  }

  async function handleFileDrop(acceptedFiles) {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      setIsUploading(true);
      setUploadError(null);

      try {
        // Create a preview URL
        const previewUrl = URL.createObjectURL(file);
        setReceiptPreview(previewUrl);
        setReceiptFile(file);

        // For display purposes only
        setFormData((prev) => ({
          ...prev,
          receipt: { filename: file.name }, // Store filename for display
        }));

        setUploadProgress(100);
      } catch (error) {
        setUploadError("Failed to prepare receipt. Please try again.");
        console.error("File handling error:", error);
      } finally {
        setIsUploading(false);
      }
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const submissionData = { ...formData };
      setIsUploading(true);

      // First save the expense without the receipt
      let savedExpense;
      try {
        savedExpense = await onSave(submissionData);
      } catch (error) {
        console.error("Failed to save expense:", error);
        toast.error("Failed to save expense. Please try again.");
        return;
      }

      // Then handle receipt upload if there's a new file
      if (receiptFile && savedExpense?._id && token) {
        try {
          const result = await uploadReceipt(
            savedExpense._id,
            receiptFile,
            token
          );

          // Update the local expense with the receipt URL
          const updatedExpense = {
            ...savedExpense,
            receipt: {
              url: result.receiptUrl,
              publicId: result.publicId,
            },
          };

          // Update the expense with the receipt information
          await onSave(updatedExpense);

          toast.success("Expense and receipt saved successfully");
        } catch (uploadError) {
          console.error("Receipt upload error:", uploadError);
          toast.error(
            "Expense saved but failed to upload receipt. You can try uploading it again by editing the expense."
          );
        }
      } else {
        toast.success("Expense saved successfully");
      }

      resetForm();
      toggle();
    } catch (error) {
      console.error("Failed to save expense:", error);
      toast.error("Failed to save expense. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {isEditing ? "Edit Expense" : "Add Expense"}
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="date">Date</Label>
                <Input
                  type="date"
                  name="date"
                  id="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="merchant">Merchant</Label>
                <Input
                  type="text"
                  name="merchant"
                  id="merchant"
                  value={formData.merchant}
                  onChange={handleChange}
                  required
                  placeholder="Enter merchant name"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="amount">Amount</Label>
                <Input
                  type="number"
                  step="0.01"
                  name="amount"
                  id="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                  placeholder="0.00"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a category</option>
                  <option value="Food">Food</option>
                  <option value="Travel">Travel</option>
                  <option value="Office">Office</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="card">Card</Label>
                <Input
                  type="select"
                  name="card"
                  id="card"
                  value={formData.card}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a card</option>
                  <option value="Corporate">Corporate Card</option>
                  <option value="Personal">Personal Card</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="client">Client</Label>
                <Input
                  type="text"
                  name="client"
                  id="client"
                  value={formData.client}
                  onChange={handleChange}
                  placeholder="Enter client name (optional)"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Receipt</Label>
                <div
                  {...getRootProps()}
                  className={`border rounded p-3 text-center ${
                    isDragActive ? "bg-light" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <input {...getInputProps()} />
                  {isUploading ? (
                    <div>
                      <Spinner size="sm" /> Uploading...
                      <Progress value={uploadProgress} className="mt-2" />
                    </div>
                  ) : receiptPreview ? (
                    <div>
                      <p className="mb-2">
                        Receipt: {formData.receipt?.filename || "Uploaded"}
                      </p>
                      {receiptPreview.endsWith(".pdf") ? (
                        <iframe
                          src={receiptPreview}
                          style={{ width: "100%", height: "200px" }}
                          title="Receipt Preview"
                        />
                      ) : (
                        <img
                          src={receiptPreview}
                          alt="Receipt Preview"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                          className="mb-2"
                        />
                      )}
                      <br />
                      <Button
                        color="danger"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setReceiptPreview(null);
                          setReceiptFile(null);
                          setFormData((prev) => ({ ...prev, receipt: null }));
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ) : (
                    <p className="mb-0">
                      Drag & drop a receipt here, or click to select
                      <br />
                      <small className="text-muted">
                        Supported formats: JPG, PNG, PDF (max 5MB)
                      </small>
                    </p>
                  )}
                </div>
                {uploadError && (
                  <div className="text-danger mt-2">
                    <small>{uploadError}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isUploading}>
            {isEditing ? "Update" : "Add"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ExpenseModal;
