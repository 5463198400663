import { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

const ColorCustomizer = ({ onColorChange, initialColor = "#000000" }) => {
	const [color, setColor] = useState(initialColor);

	useEffect(() => {
		setColor(initialColor);
	}, [initialColor]);

	const handleColorChange = (e) => {
		const newColor = e.target.value;
		setColor(newColor);
		onColorChange(newColor);
	};

	return (
		<FormGroup>
			<Label for="qrColor" className="fw-bold mb-2">
				QR Code Color:
			</Label>
			<Input
				type="color"
				id="qrColor"
				name="qrColor"
				value={color}
				onChange={handleColorChange}
				className="mb-3"
			/>
		</FormGroup>
	);
};

export default ColorCustomizer;
