import React, { useState } from "react";
import "./tools.css";

const TenDLC = () => {
  const [userType, setUserType] = useState("");
  const [campaignType, setCampaignType] = useState("");
  const [formData, setFormData] = useState({});

  const campaignOptions = [
    { value: "marketing", label: "Marketing", description: "Promotional messages" },
    { value: "customer_service", label: "Customer Service", description: "Support and updates" },
    { value: "alerts", label: "Alerts", description: "Important notifications" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Registration Data:", { userType, campaignType, ...formData });
    alert("Registration submitted successfully!");
  };

  return (
    <div className="tools-container">
      <h1 className="tools-title">10DLC Registration</h1>

      <div className="tools-nav">
        {/* User Type Selection */}
        <div className="section">
          <h2 className="tools-nav-heading">User Type</h2>
          <label>
            <input
              type="radio"
              value="sole_proprietor"
              checked={userType === "sole_proprietor"}
              onChange={() => setUserType("sole_proprietor")}
            />
            Sole Proprietor (No EIN)
          </label>
          <label>
            <input
              type="radio"
              value="business"
              checked={userType === "business"}
              onChange={() => setUserType("business")}
            />
            Business with EIN
          </label>
        </div>

        {/* Dynamic Form Fields */}
        {userType && (
          <div className="invoice-container">
            <h2>
              {userType === "sole_proprietor" ? "Sole Proprietor Information" : "Business Information"}
            </h2>
            <form onSubmit={handleSubmit}>
              {userType === "business" && (
                <>
                  <div className="form-group">
                    <label>Business Name</label>
                    <input
                      className="input-field"
                      type="text"
                      name="businessName"
                      placeholder="Business Name"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>EIN</label>
                    <input
                      className="input-field"
                      type="text"
                      name="ein"
                      placeholder="EIN"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </>
              )}
              <div className="form-group">
                <label>Address</label>
                <input
                  className="input-field"
                  type="text"
                  name="address"
                  placeholder="Address"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Contact Person</label>
                <input
                  className="input-field"
                  type="text"
                  name="contact"
                  placeholder="Contact Person"
                  onChange={handleInputChange}
                  required
                />
              </div>

              {/* Campaign Type Selection */}
              <div className="form-group">
                <label>Campaign Type</label>
                <select
                  className="select-field"
                  value={campaignType}
                  onChange={(e) => setCampaignType(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Campaign Type
                  </option>
                  {campaignOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {campaignType && (
                  <p className="description">
                    {campaignOptions.find((option) => option.value === campaignType)?.description}
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <button className="generate-btn" type="submit">
                Submit Registration
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default TenDLC;
