const ScanMe1Frame = ({ children }) => {
	return (
		<div
			className="d-flex flex-column align-items-center"
			style={{ width: "fit-content" }}
		>
			<div
				className="bg-dark p-4 rounded-4"
				style={{
					width: "240px",
					minHeight: "240px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "relative",
				}}
			>
				{children}
			</div>
			<div
				className="bg-dark fs-2 text-white px-4 py-2 mt-n2 rounded-bottom-4"
				style={{
					fontWeight: "bold",
					letterSpacing: "1px",
				}}
			>
				SCAN ME
			</div>
		</div>
	);
};

export default ScanMe1Frame;
