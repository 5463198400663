import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import TimezoneDropdown from "../../components/TimezoneDropdown";
import {
  isLength,
  isMatch,
  hasDigit,
  hasSpecialCharacter,
  hasUppercase,
} from "../../components/helper/validate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import Spinner from "../../components/Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../apiConfig";
import {
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  CardSubtitle,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import {
  deleteTeamMember,
  getAllUsers,
  resetPassword,
  sendInvite,
  updateTeamMember,
  updateUser,
} from "../../lib/api/user";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const initialState = {
  first_name: "",
  last_name: "",
  password: "",
  cf_password: "",
  permissions: [],
};

const Account = () => {
  const [timezone, setTimezone] = useState("America/New_York");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditTeamMemberModalOpen, setIsEditTeamMemberModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});
  const { user, token, dispatch } = useContext(AuthContext);
  const [avatar, setAvatar] = useState(false);
  const [data, setData] = useState(initialState);
  const [permissions, setPermissions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);
  const { first_name, last_name, phone_number, password, cf_password } = data;

  const navigate = useNavigate();


  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setData({ ...data, phone_number: value });
  };

  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    const fetchTimeZone = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/auth/${user.account}/timezone`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        setTimezone(response?.data?.timezone || "America/New_York");
      } catch (error) {
        console.error('Error fetching timezone:', error);
      } finally {
        setLoading(false);
      }
    };
    if(user) {
      fetchTimeZone();
    }
  }, [token, user]);



  const handleTimezoneChange = (selectedTimezone) => {
    setTimezone(selectedTimezone);  // Update state
    console.log('User selected timezone:', selectedTimezone);  // Log the selected timezone
  };




  const fetchTeamMembers = async () => {
    const data = await getAllUsers(token);
    setTeamMembers(data?.data);
  };

  useEffect(() => {
    if (token) {
      fetchTeamMembers();
    }
  }, [token]);

  const handleDeactivate = () => {
    navigate("/support");
  };

  const updateInfo = async (e) => {
    e.preventDefault();

    const userData = {
      first_name: first_name || user.first_name,
      last_name: last_name || user.last_name,
      avatar: avatar || user.avatar,
      phone_number: phone_number || user.phone_number,
    };

    await updateUser(userData, token, dispatch);
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    console.log("Password", password);

    if (isLength(password)) {
      return toast("Password must be at least 12 characters.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

    if (!isMatch(password, cf_password)) {
      return toast("Passwords do not match.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

    if (!hasDigit(password)) {
      return toast("Please include a digit in the password (0 - 9).", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

    if (!/[!@#$%^&*]/.test(password)) {
      return toast(
        "Please include a special character in the password (! @ # $ % ^ & *).",
        {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        }
      );
    }

    if (!/[A-Z]/.test(password)) {
      return toast("Please include an uppercase letter in your password.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

    await resetPassword(password, token);
    setData({...data, password: "", cf_password: "" })
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    handleReset();  
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, password: "", cf_password: "" });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();

    const email = document.getElementById("UserEmail").value;
    

    if (!email) {
      return toast("Please, Enter Email.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

    let data = {
      email,
      permissions
    }
    await sendInvite(data, token);

    toggleModal();
  };

  const toggleEditTeamMemberModal = () => {
    setIsEditTeamMemberModalOpen(!isEditTeamMemberModalOpen);
  };

  const handleEditTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    toggleEditTeamMemberModal();
  };

  const handleDeleteMember = async (userId) => {
    await deleteTeamMember(userId, token);
    fetchTeamMembers();
  };

  const handleUpdateTeamMember = async () => {
    await updateTeamMember(selectedTeamMember, token);
    toggleEditTeamMemberModal();
    fetchTeamMembers();
  };

  const updateTimezone = async () => {
    try {
      setLoading(true); 
      setError(''); 
      setSuccess(''); 
  
      const response = await axios.patch(
        `${API_BASE_URL}/api/auth/update_timezone/${user.account}`, 
        { timezone }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      setSuccess('Timezone updated successfully.'); // Set success message
    } catch (error) {
      setError(error.response?.data?.message || 'Error updating timezone'); // Set error message
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <>
      <ToastContainer />
      <h3>Account Overview</h3>
      <Row>
        <Col md={user?.permissions?.includes("1") ? "12" : "6"}>
          <Card style={{ height: "400px" }}>
            <CardBody className="custom-card-body">
              <h4>User Profile</h4>
              <form onSubmit={updateInfo}>
                <FormGroup>
                  <hr />
                  <Label for="email">Email/Username :</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder={user.email}
                    disabled={true}
                  ></Input>
                  <Label for="firstname">First Name</Label>
                  <Input
                    id="firstName"
                    name="first_name"
                    placeholder={user.first_name}
                    type="first_name"
                    onChange={handleChange}
                  />
                  <Label for="lastname">Last Name</Label>
                  <Input
                    id="lastName"
                    name="last_name"
                    placeholder={user.last_name}
                    type="last_name"
                    onChange={handleChange}
                  />
                </FormGroup>
                <br />
                <div className="d-flex justify-content-end">
                  <Button>Update Profile</Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
{!user?.permissions?.includes("1") && (
        <Col md="6">
          <Card style={{ height: "400px" }}>
            <CardBody className="custom-card-body">
              <h4>Team Access</h4>
              <hr />
              <div
                className="mt-3"
                style={{
                  maxHeight: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                }}
              >
                {teamMembers && teamMembers[0]?.members?.length > 0 ? (
                  <Table striped>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamMembers &&
                        teamMembers[0]?.members?.map((member, index) => (
                          <tr key={index}>
                            <td>{member.first_name}</td>
                            <td>{member.last_name}</td>
                            <td>{member.roles}</td>
                            <td>
                              <Button
                                outline
                                color="secondary"
                                onClick={() =>
                                  handleEditTeamMemberClick(member)
                                }
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <p className="h-full">No team members added yet.</p>
                )}
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button onClick={toggleModal}>Add Team Member</Button>
              </div>
              {/* Modal for adding a team member */}
              <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Team Member</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="email">Email Address</Label>
                    <Input
                      type="email"
                      id="UserEmail"
                      placeholder="Enter email address"
                    />
                  </FormGroup>
                  <FormGroup>
                <Label>Set User Permissions</Label>
                <div>
                  {[
                    {
                      id: "1",
                      label: " Restrict Account Access. User Cannot Add Other Users.",
                    },
                    {
                      id: "2",
                      label: " Restrict Billing Access",
                    },
                    {
                      id: "3",
                      label: " Disallow Buying Phone Numbers",
                    },
                    {
                      id: "4",
                      label: " Disallow Running Campaigns",
                    },
                  ].map((permission) => (
                    <FormGroup check key={permission.id}>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={permissions?.includes(permission.id)}
                          onChange={(e) => {
                            let updatedPermissions;
                            if (e.target.checked) {
                              updatedPermissions = [...(permissions || []), permission.id];
                            } else {
                              updatedPermissions = (permissions || []).filter(
                                (p) => p !== permission.id
                              );
                            }
                            setPermissions(updatedPermissions);
                          }}
                        />
                        {permission.label}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              </FormGroup>

                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleInviteSubmit}>
                    Send Invite
                  </Button>
                  <Button color="secondary" onClick={toggleModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              {/* Edit Team Member Modal */}
              <Modal
                isOpen={isEditTeamMemberModalOpen}
                toggle={toggleEditTeamMemberModal}
              >
                <ModalHeader toggle={toggleEditTeamMemberModal}>
                  Edit Team Member
                </ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="editTeamMemberFirstName">First Name</Label>
                    <Input
                      type="text"
                      id="editTeamMemberFirstName"
                      value={selectedTeamMember.first_name || ""}
                      onChange={(e) =>
                        setSelectedTeamMember({
                          ...selectedTeamMember,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editTeamMemberLastName">Last Name</Label>
                    <Input
                      type="text"
                      id="editTeamMemberLastName"
                      value={selectedTeamMember.last_name || ""}
                      onChange={(e) =>
                        setSelectedTeamMember({
                          ...selectedTeamMember,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editTeamMemberLastName">Phone Number</Label>
                    <Input
                      type="text"
                      id="editTeamMemberLastName"
                      value={selectedTeamMember.phone_number || ""}
                      onChange={(e) =>
                        setSelectedTeamMember({
                          ...selectedTeamMember,
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editTeamMemberEmail">Email Address</Label>
                    <Input
                      type="email"
                      id="editTeamMemberEmail"
                      value={selectedTeamMember.email || ""}
                      onChange={(e) =>
                        setSelectedTeamMember({
                          ...selectedTeamMember,
                          email: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="editTeamMemberRole">Role</Label>
                    <Input
                      type="select"
                      id="editTeamMemberRole"
                      value={selectedTeamMember.roles || ""}
                      onChange={(e) =>
                        setSelectedTeamMember({
                          ...selectedTeamMember,
                          roles: e.target.value,
                        })
                      }
                    >
                      <option>Admin</option>
                      <option>Editor</option>
                      <option>Viewer</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Set User Permissions</Label>
                    <div>
                      {[
                        {
                          id: "1",
                          label: "Restrict Account Access. User Cannot Add Other Users.",
                        },
                        {
                          id: "2",
                          label: "Restrict Billing Access",
                        },
                        {
                          id: "3",
                          label: "Disallow Buying Phone Numbers",
                        },
                        {
                          id: "4",
                          label: "Disallow Running Campaigns",
                        },
                      ].map((permission) => (
                        <FormGroup check key={permission.id}>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={selectedTeamMember.permissions?.includes(
                                permission.id
                              )}
                              onChange={(e) => {
                                const permissions =
                                  selectedTeamMember.permissions || [];
                                if (e.target.checked) {
                                  permissions.push(permission.id);
                                } else {
                                  const index = permissions.indexOf(
                                    permission.id
                                  );
                                  if (index !== -1) {
                                    permissions.splice(index, 1);
                                  }
                                }
                                setSelectedTeamMember({
                                  ...selectedTeamMember,
                                  permissions,
                                });
                              }}
                            />
                            {permission.label}
                          </Label>
                        </FormGroup>
                      ))}
                    </div>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleUpdateTeamMember}>
                    Update
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => handleDeleteMember(selectedTeamMember._id)}
                  >
                    Delete User
                  </Button>
                  <Button color="secondary" onClick={toggleEditTeamMemberModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
        )}
      </Row>

      <Row>
        <Col xs="6">
        <Card style={{ height: "275px" }}>
      <CardBody className="custom-card-body">
        <h4>Change Password</h4>
        <form onSubmit={updatePassword}>
          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <div className="position-relative">
              <Input
                id="newPassword"
                name="password"
                placeholder="Type New Password"
                type={showNewPassword ? "text" : "password"}
                onChange={handleChange}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y me-2 mr-2"
                style={{ cursor: "pointer" }}
                onClick={toggleNewPasswordVisibility}
              >
                {showNewPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </span>
            </div>
            <Label for="verifynewPassword">Confirm New Password</Label>
            <div className="position-relative">
              <Input
                id="verifynewPassword"
                name="cf_password"
                placeholder="Re-Type New Password"
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleChange}
              />
              <span
                className="position-absolute end-0 top-50 translate-middle-y me-2"
                style={{ cursor: "pointer" }}
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </span>
            </div>
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button>Change Password</Button>
          </div>
        </form>
      </CardBody>
    </Card>
        </Col>

        <Col md="6">
          <Card style={{ height: "275px" }}>
            <CardBody className="custom-card-body">
              <h4>Account Phone Number</h4>
              <hr />

              <form onSubmit={updateInfo}>
                <FormGroup>
                  {/* <Input
                      id="phonenumber"
                      name="email"
                      placeholder={user.phone_number}
                      type="tel"
                    /> */}
                  <PhoneInput
                    type="tel"
                    placeholder={user.phone_number}
                    name="phone_number"
                    value={data.phone_number}
                    defaultCountry="US"
                    onChange={handlePhoneChange}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button>Update Phone Number</Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!user?.permissions?.includes("1") && (

      <Row>

        <Col md="6">
          <Card style={{ height: "275px" }}>
          <CardBody className="custom-card-body">
            <h4>Timezone</h4>
            <Form>
              <FormGroup>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="Vlt-col Vlt-col--A">
                    <TimezoneDropdown
                      value={timezone} // Keep the current timezone value
                      setValue={setTimezone} // Directly set the timezone state
                    />
                  </div>
                )}
                <br />
                <h6 style={{ fontStyle: 'italic' }}>
                  WARNING: This timezone setting will determine when your campaigns are sent.
                  Please make sure it aligns with your business hours.
                </h6>
              </FormGroup>
              {error && <p style={{ color: 'red' }}>{error}</p>} {/* Show error if any */}
              {success && <p style={{ color: 'green' }}>{success}</p>} {/* Show success if any */}
              <div className="d-flex justify-content-end">
                <Button onClick={updateTimezone}>
                  {loading ? 'Updating...' : 'Update'}
                </Button>
              </div>
            </Form>
          </CardBody>
          </Card>
        </Col>

        <Col xs="6">
          <Card style={{ height: "275px" }}>
            <CardBody className="custom-card-body">
              <h4>Deactivate Account</h4>
              <hr />
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                For security purposes, account deactivations can only be
                performed by our administrators upon your request. Please click
                the "Begin Deactivation" button below in order to instruct our
                administrators to deactivate your account.
              </CardSubtitle>
              <br />
              <div className="d-flex justify-content-end">
                <Button onClick={handleDeactivate}>Begin Deactivation</Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      )}
    </>
  );
};

export default Account;
