import { FormGroup, Input, Label } from "reactstrap";

const QRStyleCustomizer = ({ qrStyle, onQRStyleChange }) => {
	return (
		<FormGroup>
			<Label for="qrStyle">QR Code Style</Label>
			<Input
				type="select"
				name="qrStyle"
				id="qrStyle"
				value={qrStyle}
				onChange={(e) => onQRStyleChange(e.target.value)}
			>
				<option value="dots">Dots</option>
				<option value="squares">Squares</option>
			</Input>
		</FormGroup>
	);
};

export default QRStyleCustomizer;
