import { useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  Building,
  Envelope,
  Person,
  Phone,
  Plus,
  Search,
} from "react-bootstrap-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
} from "reactstrap";
import CustomerModal from "../../../../components/Modals/CustomerModal";
import { useCustomers } from "../../../../hooks/useCustomers";
import { useInvoice } from "../context/InvoiceContext";

const CUSTOMERS_PER_PAGE = 5;

export function CustomerStep({ onNext }) {
  const { invoiceData, updateInvoiceData } = useInvoice();
  const { customers, loading, error } = useCustomers();
  const [searchTerm, setSearchTerm] = useState("");
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Filter customers based on search term
  const filteredCustomers = customers?.filter((customer) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      customer.firstName.toLowerCase().includes(searchLower) ||
      customer.lastName.toLowerCase().includes(searchLower) ||
      (customer.company &&
        customer.company.toLowerCase().includes(searchLower)) ||
      customer.email.toLowerCase().includes(searchLower)
    );
  });

  // Calculate pagination
  const totalPages = Math.ceil(
    (filteredCustomers?.length || 0) / CUSTOMERS_PER_PAGE
  );
  const startIndex = (currentPage - 1) * CUSTOMERS_PER_PAGE;
  const endIndex = startIndex + CUSTOMERS_PER_PAGE;
  const currentCustomers = filteredCustomers?.slice(startIndex, endIndex);

  const handleSelectCustomer = (customer) => {
    updateInvoiceData("customer", customer);
  };

  const toggleCustomerModal = () => {
    setCustomerModalOpen(!customerModalOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className="text-center py-5">
        <Spinner className="text-primary mb-2" />
        <div className="text-muted">Loading customers...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-5 text-danger">
        <div className="mb-2">Error loading customers</div>
        <div className="text-muted small">{error.message}</div>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1">Select Customer</h5>
        <p className="text-muted mb-0">
          Choose an existing customer or create a new one for your invoice
        </p>
      </div>

      <div className="d-flex gap-2 mb-4">
        <div className="flex-grow-1">
          <InputGroup>
            <Input
              placeholder="Search customers..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
              className="border-end-0 ps-3"
              style={{ backgroundColor: "#f8f9fa", height: "38px" }}
            />
            <InputGroupText
              style={{
                backgroundColor: "#f8f9fa",
                height: "38px",
                padding: "0 12px",
              }}
              className="border-start-0"
            >
              <Search size={14} className="text-muted" />
            </InputGroupText>
          </InputGroup>
        </div>
        <Button
          color="primary"
          outline
          onClick={toggleCustomerModal}
          style={{
            height: "38px",
            padding: "0 16px",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <Plus size={14} className="me-1" />
          New Customer
        </Button>
      </div>

      {currentCustomers?.length === 0 ? (
        <div className="text-center py-5">
          <div className="text-muted mb-2">No customers found</div>
          <Button color="primary" outline onClick={toggleCustomerModal}>
            <Plus size={18} className="me-2" />
            Create New Customer
          </Button>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column gap-3 mb-4">
            {currentCustomers?.map((customer) => (
              <Card
                key={customer._id}
                className={`shadow-sm cursor-pointer ${
                  invoiceData.customer?._id === customer._id
                    ? "border-primary"
                    : "border"
                }`}
                onClick={() => handleSelectCustomer(customer)}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row className="align-items-center">
                    <Col>
                      <div className="d-flex align-items-center mb-2">
                        <Person size={18} className="me-2 text-primary" />
                        <strong>
                          {customer.firstName} {customer.lastName}
                        </strong>
                      </div>
                      {customer.company && (
                        <div className="d-flex align-items-center mb-2">
                          <Building size={18} className="me-2 text-muted" />
                          {customer.company}
                        </div>
                      )}
                      <div className="d-flex align-items-center mb-2">
                        <Envelope size={18} className="me-2 text-muted" />
                        {customer.email}
                      </div>
                      {customer.phoneNumber && (
                        <div className="d-flex align-items-center">
                          <Phone size={18} className="me-2 text-muted" />
                          {customer.phoneNumber}
                        </div>
                      )}
                    </Col>
                    {invoiceData.customer?._id === customer._id && (
                      <Col xs="auto">
                        <div className="bg-primary bg-opacity-10 text-primary px-3 py-2 rounded-3">
                          Selected
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="text-muted small">
                Showing {startIndex + 1} to{" "}
                {Math.min(endIndex, filteredCustomers.length)} of{" "}
                {filteredCustomers.length} customers
              </div>
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <ArrowLeft size={16} />
                  </PaginationLink>
                </PaginationItem>
                {[...Array(totalPages)].map((_, index) => (
                  <PaginationItem
                    key={index + 1}
                    active={currentPage === index + 1}
                  >
                    <PaginationLink onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <ArrowRight size={16} />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </>
      )}

      <div className="d-flex justify-content-end">
        <Button
          color="primary"
          onClick={onNext}
          disabled={!invoiceData.customer}
        >
          Next Step
        </Button>
      </div>

      <CustomerModal
        isOpen={customerModalOpen}
        toggle={toggleCustomerModal}
        onSuccess={(newCustomer) => {
          if (newCustomer) {
            handleSelectCustomer(newCustomer);
          }
        }}
      />
    </div>
  );
}
