import moment from "moment-timezone";
import Dropdown from "../Dropdown";
function TimezoneDropdown ({ label, value, setValue, disabled }) {
  const data = moment.tz.names();
  return (
    <Dropdown
    disabled={disabled}
      label={label}
      value={value ?? moment.tz.guess()}
      setValue={setValue}
    >
      {
        data.map(
          (tz) => {
            return (
              <option
                value={tz}
                key={tz}
              >
                {tz}
              </option>
            )
          }
        )
      }
    </Dropdown>
  )
}
export default TimezoneDropdown