import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import NavLogo from "../../assets/images/logos/helperfox-logo.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-vh-100 d-flex flex-column">
      <div className="authlayout_navbar">
        <div className="authlayout_logo">
          <img src={NavLogo} alt="logo" />
        </div>
        <div className="authlayout_link">
          <a href="https://helperfox.com">Back to Main Site</a>
        </div>
      </div>

      <Container
        fluid
        className="flex-grow-1 d-flex flex-column justify-content-center py-5"
      >
        <Row className="justify-content-center">
          <Col xs={12} className="text-center">
            <h1
              style={{
                fontSize: "12rem",
                fontWeight: "700",
                color: "#1E2B3A",
                lineHeight: "1",
              }}
            >
              404
            </h1>
            <div className="mt-4" style={{ color: "#1E2B3A" }}>
              <p className="mb-2" style={{ fontSize: "1.25rem" }}>
                Oops, something went wrong.
              </p>
              <p className="mb-4" style={{ fontSize: "1.25rem" }}>
                Sorry, we couldn't find your page.
              </p>
            </div>
            <Button
              color="primary"
              onClick={() => navigate("/")}
              className="px-4 py-2 rounded-1"
              style={{
                backgroundColor: "#2D6BFA",
                border: "none",
                fontSize: "1rem",
              }}
            >
              Back to Dashboard
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-3 border-top">
        <Row>
          <Col className="text-center">
            <p className="text-muted mb-0" style={{ fontSize: "0.875rem" }}>
              © All Rights Reserved. {new Date().getFullYear()}.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
