import {
  CashStack,
  FileEarmarkSpreadsheet,
  GraphUp,
  People,
  QrCode,
  Receipt,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import "./PublicTools.css";

const PublicTools = () => {
  const tools = [
    {
      title: "CSV Generator",
      description:
        "Generate, format, and validate CSV files. Perfect for data organization.",
      icon: <FileEarmarkSpreadsheet size={24} />,
      path: "/ft/tools/csv",
      isPremium: false,
    },
    {
      title: "QR Code Generator",
      description:
        "Create custom QR codes for your business. Add your logo and colors.",
      icon: <QrCode size={24} />,
      path: "/ft/tools/qr",
      isPremium: false,
    },
    {
      title: "Expense Tracker",
      description:
        "Track expenses, generate reports, and analyze spending patterns.",
      icon: <CashStack size={24} />,
      path: "/auth",
      isPremium: true,
      features: ["Analytics", "Reports", "Categories"],
    },
    {
      title: "Invoice Generator",
      description:
        "Create and manage professional invoices with customizable templates.",
      icon: <Receipt size={24} />,
      path: "/auth",
      isPremium: true,
      features: ["Analytics", "Templates", "Auto-numbering"],
    },
    {
      title: "CRM",
      description:
        "Manage customer relationships, track interactions, and boost sales.",
      icon: <People size={24} />,
      path: "/auth",
      isPremium: true,
      features: ["Analytics", "Contact Management", "Sales Pipeline"],
    },
  ];

  return (
    <div className="public-tools">
      {/* Main Content */}
      <Container className="px-10 py-5">
        <div className="max-w-960 mx-auto">
          <h1 className="h2 fw-black mb-4">Free Tools & Utilities</h1>

          <Row className="g-3">
            {tools.map((tool, index) => (
              <Col xs={12} sm={6} md={4} key={index}>
                <Link to={tool.path} className="text-decoration-none">
                  <Card className="h-100 border">
                    <CardBody className="d-flex flex-column gap-3 p-4">
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="text-dark">{tool.icon}</div>
                        {tool.isPremium && (
                          <Badge color="warning" pill className="premium-badge">
                            Premium
                          </Badge>
                        )}
                      </div>
                      <div>
                        <h3 className="h6 fw-bold mb-1 text-dark">
                          {tool.title}
                        </h3>
                        <p className="text-muted small mb-0">
                          {tool.description}
                        </p>
                      </div>
                      {tool.isPremium && (
                        <div className="features-list mt-2">
                          <div className="d-flex align-items-center gap-2">
                            <GraphUp size={14} className="text-primary" />
                            <span className="text-primary small">
                              Includes Analytics
                            </span>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default PublicTools;
