import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useSWR from "swr";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import ChartCard from "./ChartCard";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload?.length) {
    return null;
  }

  return (
    <div className="custom-tooltip bg-white p-3 shadow-sm rounded">
      <p className="mb-2">{label}</p>
      {payload.map((entry, index) => (
        <p key={index} className="mb-1" style={{ color: entry.color }}>
          {entry.name}: {formatCurrency(entry.value)}
        </p>
      ))}
    </div>
  );
};

const RevenueEstimatesChart = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/api/dashboard/charts/revenue-estimates",
    async (url) => {
      try {
        const response = await axiosWithAuth.get(url);
        return response.data;
      } catch (error) {
        throw error.response?.data || error.message;
      }
    },
    {
      refreshInterval: 5 * 60 * 1000, // 5 minutes
      revalidateOnFocus: false,
    }
  );

  return (
    <ChartCard
      title="Revenue vs Estimates"
      isLoading={isLoading}
      error={error}
      onRetry={() => mutate()}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data?.comparisons || []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="estimates" name="Estimates" fill="#8884d8" />
          <Bar dataKey="invoices" name="Invoices" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </ChartCard>
  );
};

export default RevenueEstimatesChart;
