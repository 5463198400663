import { forwardRef, useEffect, useState } from "react";
import ScanMe1Frame from "./ScanMe1Frame";
import ScanMe2Frame from "./ScanMe2Frame";
import ScanMe3Frame from "./ScanMe3Frame";
// import ScanMe4Frame from "./ScanMe4Frame";

const FRAMES = [
	{
		id: "none",
		name: "No Frame",
		component: ({ children, isPreview }) => {
			const scale = isPreview ? 0.13 : 1;
			const size = 240 * scale;

			return (
				<div
					style={{
						width: size,
						height: size,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						overflow: "hidden",
					}}
				>
					{children}
				</div>
			);
		},
	},
	{ id: "scan-me-1", name: "Scan Me 1", component: ScanMe1Frame },
	{ id: "scan-me-2", name: "Scan Me 2", component: ScanMe2Frame },
	{ id: "scan-me-3", name: "Scan Me 3", component: ScanMe3Frame },
	// { id: "scan-me-4", name: "Scan Me 4", component: ScanMe4Frame },
];

const PreviewContent = ({ isSelected }) => (
	<div
		style={{
			width: "200px",
			height: "200px",
			backgroundColor: isSelected ? "#e3e3e3" : "#f0f0f0",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			fontSize: "24px",
		}}
	>
		QR
	</div>
);

const FrameWrapper = forwardRef(
	({ children, onFrameChange, initialFrameName = "none" }, ref) => {
		const [selectedFrame, setSelectedFrame] = useState(initialFrameName);

		useEffect(() => {
			setSelectedFrame(initialFrameName);
		}, [initialFrameName]);

		const SelectedFrameComponent =
			FRAMES.find((frame) => frame.id === selectedFrame)?.component ||
			FRAMES[0].component;

		const handleFrameChange = (frameId) => {
			setSelectedFrame(frameId);
			onFrameChange(frameId);
		};

		return (
			<div className="d-flex flex-column align-items-center">
				<div className="mb-4" ref={ref}>
					<SelectedFrameComponent>{children}</SelectedFrameComponent>
				</div>
				<div
					className="d-flex gap-3 justify-content-center"
					style={{ maxWidth: "400px", width: "100%" }}
				>
					{FRAMES.map((frame) => (
						<div
							key={frame.id}
							onClick={() => handleFrameChange(frame.id)}
							className={`cursor-pointer rounded ${
								selectedFrame === frame.id
									? "border border-2 border-primary"
									: "border"
							}`}
							style={{
								backgroundColor: "white",
								transition: "all 0.2s ease",
								width: "50px",
								height: "50px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								overflow: "hidden",
								position: "relative",
								padding: "5px",
							}}
						>
							<div
								style={{
									transform: "scale(0.13)",
									transformOrigin: "center",
									position: "absolute",
								}}
							>
								<frame.component isPreview>
									<PreviewContent
										isSelected={selectedFrame === frame.id}
									/>
								</frame.component>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
);

FrameWrapper.displayName = "FrameWrapper";

export default FrameWrapper;
