import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { useAuthContext } from "../../hooks/useAuthContext";
import axiosWithAuth from "../../utils/axiosWithAuth";

const Method = () => {
  const [cardData, setCardData] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuthContext();
  const stripeCustomerId = user?.stripeCustomerId || null;
  const stripe = useStripe();
  const elements = useElements();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const toggleModal = () => setIsModalOpen(!isModalOpen);


  console.log("method stripeCustomerId ", stripeCustomerId)

  useEffect(() => {
    if (!stripeCustomerId) {
      console.warn("Stripe customer ID is not available");
      setLoading(false);
      return;
    }

    const fetchCardData = async () => {
      try {
        const { data } = await axiosWithAuth.get(
          `${API_BASE_URL}/api/auth/payinfo?stripeCustomerId=${stripeCustomerId}`
        );
        if (data && data.length > 0) {
          setCardData(data);
          setPaymentId(data[0].id);
        }
      } catch (error) {
        console.error("Error fetching card data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCardData();
  }, [stripeCustomerId]);

  const handleStartSubscription = async () => {
    setIsProcessing(true);
    try {
      const { data } = await axiosWithAuth.post(
        `${API_BASE_URL}/api/auth/session`,
        { customerId: stripeCustomerId }
      );
  
      console.log("Stripe session response:", data);
  
      if (data.url) {
        // Redirect the user to Stripe Checkout
        window.location.href = data.url;
      } else {
        console.error("No URL returned from backend");
      }
    } catch (error) {
      console.error("Error setting up payment method:", error.message);
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleDeletePaymentMethod = async (paymentMethodId) => {
    const updatedCardData = cardData.map((card) =>
      card.id === paymentMethodId ? { ...card, isProcessing: true } : card
    );
    setCardData(updatedCardData);

    try {
      const response = await axiosWithAuth.delete(
        `${API_BASE_URL}/api/auth/delete-payment-method/${paymentMethodId}`
      );

      if (response.status === 200) {
        // Remove the deleted card from the local state
        setCardData((prev) =>
          prev.filter((card) => card.id !== paymentMethodId)
        );
        // alert("Payment method deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting payment method:", error.message);
      alert("Failed to delete payment method. Please try again.");
    } finally {
      // Reset the isProcessing state for this card
      setCardData((prev) =>
        prev.map((card) =>
          card.id === paymentMethodId ? { ...card, isProcessing: false } : card
        )
      );
    }
  };

  const handleUpdatePaymentMethod = async () => {
    if (!paymentId) {
      alert("No payment method exists to update.");
      return;
    }

    try {
      // Step 1: Delete the existing payment method (fire and forget)
      axiosWithAuth
        .delete(`${API_BASE_URL}/api/auth/delete-payment-method/${paymentId}`)
        .catch((error) => {
          console.error("Error deleting payment method:", error.message);
        });

      // Step 2: Make the API request to create a session for the new payment method
      const updateResponse = await axiosWithAuth.post(
        `${API_BASE_URL}/api/auth/update-payment-method`,
        {
          customerId: stripeCustomerId,
        }
      );

      const sessionId = updateResponse.data.sessionId;

      // Step 3: Redirect the user to Stripe Checkout for updating the payment method
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Error redirecting to checkout:", error.message);
      } else {
        alert("Redirecting to Stripe Checkout...");
      }
    } catch (error) {
      console.error("Error updating payment method:", error.message);
      alert(
        "An error occurred while updating the payment method. Please try again."
      );
    }
  };

  if (!stripeCustomerId) {
    return (
      <Card style={{ minHeight: "300px" }}>
        <CardBody className="custom-card-body">
          <div>
            Please set up a Stripe customer account to manage payment methods.
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <div style={{ height: "250px" }}>
        <CardBody className="custom-card-body">
          <h4>Payment Method</h4>
          <hr />

          {loading ? (
            <Spinner>Loading...</Spinner>
          ) : cardData ? (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>Expiry Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cardData.map((card) => (
                    <tr key={card.id}>
                      <td>{`${card.card.brand} **** **** **** ${card.card.last4}`}</td>
                      <td>{`${card.card.exp_month} / ${card.card.exp_year}`}</td>
                      <td>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedCardId(card.id); // Set the ID of the card to be deleted
                            toggleModal(); // Open the modal
                          }}
                          style={{
                            color: "red",
                            cursor: card.isProcessing
                              ? "not-allowed"
                              : "pointer",
                            textDecoration: "underline",
                            pointerEvents: card.isProcessing ? "none" : "auto",
                          }}
                        >
                          {card.isProcessing ? "Processing..." : "Delete"}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <Modal isOpen={isModalOpen} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>
                    Confirm Deletion
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete? Without a method of
                    payment, you will not be able to send messages or keep your
                    phone numbers.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      onClick={() => {
                        handleDeletePaymentMethod(selectedCardId); // Call delete method
                        toggleModal(); // Close the modal
                      }}
                    >
                      Delete
                    </Button>{" "}
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Table>
              <div className="d-flex justify-content-end">
                <Button
                  outline
                  color="primary"
                  onClick={handleUpdatePaymentMethod}
                  disabled={isProcessing}
                >
                  {isProcessing ? <Spinner size="sm" /> : "Update"}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <p>You have not have an active subscription.</p>
              <div className="d-flex justify-content-end">
                <Button
                  outline
                  color="primary"
                  onClick={handleStartSubscription}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <Spinner size="sm" />
                  ) : (
                    "Create Subscription"
                  )}
                </Button>
              </div>
            </div>
          )}
        </CardBody>
      </div>
    </Card>
  );
};

export default Method;
