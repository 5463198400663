import { Card } from "reactstrap";
import "./ChartCard.css";

const ChartSkeleton = () => (
  <div className="chart-skeleton">
    <div className="skeleton-header">
      <div className="skeleton-title"></div>
    </div>
    <div className="skeleton-chart"></div>
  </div>
);

const ChartError = ({ onRetry }) => (
  <div className="chart-error">
    <p>Failed to load chart data</p>
    <button onClick={onRetry} className="btn btn-sm btn-outline-primary">
      Retry
    </button>
  </div>
);

const ChartCard = ({
  title,
  children,
  isLoading,
  error,
  onRetry,
  height = "400px",
}) => {
  if (isLoading) {
    return (
      <Card className="chart-card border-0 shadow-sm">
        <div className="card-body">
          <ChartSkeleton />
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="chart-card border-0 shadow-sm">
        <div className="card-body">
          <ChartError onRetry={onRetry} />
        </div>
      </Card>
    );
  }

  return (
    <Card className="chart-card border-0 shadow-sm">
      <div className="card-body">
        <h5 className="card-title mb-4">{title}</h5>
        <div style={{ height }}>{children}</div>
      </div>
    </Card>
  );
};

export default ChartCard;
