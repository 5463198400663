import Input from "../Input/Input";
import { isEmpty, isEmail } from "../helper/validate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useCallback, useState } from "react";
import API_BASE_URL from "../../apiConfig"; 
import {
  Card,
  CardBody
} from "reactstrap";

const Forgot = () => {
  const [email, setEmail] = useState("");
  // const [token, setToken] = useState(null);

  // const handleRecaptchaVerify = useCallback((token) => {
  //   setToken(token)
  // }, [])

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setEmail({ email: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // check fields
    // if(token) {
      if (isEmpty(email))
        return toast("Please fill in all fields.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // check email
      if (!isEmail(email))
        return toast("Please enter a valid email address.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      try {

        await axios.post(`${API_BASE_URL}/api/auth/forgot_pass`, { email: email.toLowerCase() });
        handleReset();
        return toast("Please check your email 📧", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
      } catch (err) {
        toast(err.response.data.msg, {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      // }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="authInputArea">
      <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Reset Your Password</h1>
            <div><label>What's the email associated with your account?</label></div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                text="Email"
                name="email"
                handleChange={handleChange}
              />
              <div className="login_btn">
                <button type="submit">send</button>
              </div>
            </form>
      </div>
      {/* <GoogleReCaptcha onVerify={handleRecaptchaVerify} /> */}
    </>
  );
};

export default Forgot;
