import { useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Container, Progress } from "reactstrap";
import { CustomerStep } from "./components/CustomerStep";
import { DetailsStep } from "./components/DetailsStep";
import { ReviewStep } from "./components/ReviewStep";
import { ServicesStep } from "./components/ServicesStep";
import { InvoiceContext } from "./context/InvoiceContext";

const STEPS = [
  {
    id: 1,
    title: "Customer",
    component: CustomerStep,
    description: "Select or create a new customer",
  },
  {
    id: 2,
    title: "Services",
    component: ServicesStep,
    description: "Add services to your invoice",
  },
  {
    id: 3,
    title: "Details",
    component: DetailsStep,
    description: "Fill in invoice details and terms",
  },
  {
    id: 4,
    title: "Review",
    component: ReviewStep,
    description: "Review and send your invoice",
  },
];

// Helper function to generate invoice number
const generateInvoiceNumber = () => {
  return `INV-${Date.now()}`;
};

// Helper function to get default due date (30 days from now)
const getDefaultDueDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  return date.toISOString().split("T")[0];
};

export function InvoiceGenerator() {
  const [currentStep, setCurrentStep] = useState(1);
  const [invoiceData, setInvoiceData] = useState({
    customer: null,
    services: [],
    details: {
      invoiceNumber: generateInvoiceNumber(),
      date: new Date().toISOString().split("T")[0],
      dueDate: getDefaultDueDate(),
      paymentTerms: "Net 30",
      notes: "",
      terms: "",
    },
  });

  const progress = (currentStep / STEPS.length) * 100;
  const CurrentStepComponent = STEPS[currentStep - 1].component;

  const handleNext = () => {
    if (currentStep < STEPS.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateInvoiceData = (section, data) => {
    setInvoiceData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  const canNavigateToStep = (stepId) => {
    // Can always go back
    if (stepId <= currentStep) return true;

    // Check if previous steps are completed
    if (stepId > 1 && !invoiceData.customer) return false;
    if (stepId > 2 && invoiceData.services.length === 0) return false;
    if (stepId > 3 && !invoiceData.details.invoiceNumber) return false;

    return true;
  };

  const handleStepClick = (stepId) => {
    if (canNavigateToStep(stepId)) {
      setCurrentStep(stepId);
    }
  };

  const navigate = useNavigate();

  return (
    <InvoiceContext.Provider value={{ invoiceData, updateInvoiceData }}>
      <Container fluid className="py-4">
        <Button
          color="link"
          className="p-0 text-muted mb-3"
          onClick={() => navigate("/invoices")}
        >
          <ArrowLeft size={18} className="me-2" />
          Back to Invoices
        </Button>

        <div className="d-flex flex-column flex-md-row gap-4">
          {/* Left sidebar with steps */}
          <div style={{ minWidth: "280px" }}>
            <Card className="shadow-sm position-sticky" style={{ top: "1rem" }}>
              <CardBody className="p-0">
                <div className="p-4 bg-primary bg-opacity-10 border-bottom">
                  <h5 className="mb-1">Create Invoice</h5>
                  <small className="text-muted">
                    Step {currentStep} of {STEPS.length}
                  </small>
                  <Progress
                    value={progress}
                    className="mt-3"
                    style={{ height: "4px" }}
                  />
                </div>

                <div className="p-2">
                  {STEPS.map((step) => {
                    const isClickable = canNavigateToStep(step.id);
                    return (
                      <div
                        key={step.id}
                        className={`p-3 rounded-3 ${
                          currentStep === step.id
                            ? "bg-primary bg-opacity-10"
                            : isClickable
                              ? "opacity-75 cursor-pointer"
                              : "opacity-50"
                        }`}
                        onClick={() => handleStepClick(step.id)}
                        style={{
                          cursor: isClickable ? "pointer" : "not-allowed",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className={`rounded-circle d-flex align-items-center justify-content-center me-3 ${
                              currentStep >= step.id && isClickable
                                ? "bg-primary text-white"
                                : "bg-light"
                            }`}
                            style={{ width: "24px", height: "24px" }}
                          >
                            {step.id}
                          </div>
                          <div>
                            <div className="fw-medium">{step.title}</div>
                            <small
                              className={
                                currentStep === step.id
                                  ? "text-primary"
                                  : "text-muted"
                              }
                            >
                              {step.description}
                            </small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* Main content area */}
          <div className="flex-grow-1">
            <Card className="shadow-sm">
              <CardBody>
                <CurrentStepComponent
                  onNext={handleNext}
                  onPrevious={handlePrevious}
                  isFirstStep={currentStep === 1}
                  isLastStep={currentStep === STEPS.length}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </InvoiceContext.Provider>
  );
}
