import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaCopy,
  FaEdit,
  FaLink,
  FaTrash,
} from "react-icons/fa";
import { toast } from "react-toastify";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledAccordion,
} from "reactstrap";
import useSWR from "swr";
import config from "../../config";
import { useAuth } from "../../context/AuthContext";
import axiosWithAuth from "../../utils/axiosWithAuth";

const faqData = [
  {
    question: "What is a URL shortener?",
    answer:
      "A URL shortener creates a short, unique URL that redirects to a longer URL. It's useful for sharing links on platforms with character limits or for tracking click-through rates.",
  },
  {
    question: "Are the shortened links permanent?",
    answer: "Yes, the shortened links are permanent unless manually deleted.",
  },
  {
    question: "Is there a limit to how many links I can shorten?",
    answer:
      "There is no set limit, but we recommend using the service responsibly.",
  },
  {
    question: "Can I customize the shortened URL?",
    answer:
      "Currently, custom URLs are not supported. The system generates a random short code for each URL.",
  },
  {
    question: "Is there any way to track clicks on my shortened URL?",
    answer:
      "At the moment, click tracking is not available. This feature may be added in future updates.",
  },
];

const shortLinksAtom = atomWithStorage("shortLinks", []);

const ShortLink = () => {
  const [longUrl, setLongUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [shortLinks, setShortLinks] = useAtom(shortLinksAtom);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingLink, setEditingLink] = useState(null);

  const { token } = useAuth();
  const isAuthenticated = token ? true : false;
  const { data: remoteShortLinks } = useSWR(
    isAuthenticated || token ? `${config.apiDomain}/api/shortlink` : null,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...(isAuthenticated && { credentials: "include" }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch short links");
      }
      return response.json();
    },
    {
      onSuccess: (data) => {
        setShortLinks(data);
      },
    }
  );

  const handleUrlChange = (e) => {
    setLongUrl(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!longUrl) {
      toast.error("Please enter a URL");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosWithAuth.post("/api/shortlink", { longUrl });
      setShortLinks([...shortLinks, response.data]);
      setLongUrl("");
      toast.success("Short link created successfully!");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to create short link"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosWithAuth.delete(`/api/shortlink/${id}`);
      setShortLinks(shortLinks.filter((link) => link._id !== id));
      toast.success("Short link deleted successfully!");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to delete short link"
      );
    }
  };

  const handleCopy = async (shortUrl) => {
    try {
      await navigator.clipboard.writeText(shortUrl);
      toast.success("Link copied to clipboard!");
    } catch (error) {
      toast.error("Failed to copy link");
    }
  };

  const openEditModal = (link) => {
    setEditingLink(link);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingLink(null);
    setIsEditModalOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isAuthenticated) {
        const response = await axiosWithAuth.put(
          `/api/shortlink/${editingLink._id}`,
          { longUrl: editingLink.longUrl }
        );
        setShortLinks((prevLinks) =>
          prevLinks.map((link) =>
            link._id === editingLink._id ? response.data : link
          )
        );
      } else {
        setShortLinks((prevLinks) =>
          prevLinks.map((link) =>
            link._id === editingLink._id
              ? { ...link, longUrl: editingLink.longUrl }
              : link
          )
        );
      }
      closeEditModal();
    } catch (error) {
      console.error("Error updating short link:", error);
      setError("Failed to update short link");
    }
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <Card>
            <CardHeader>
              <h2 className="mb-0 h4 font-weight-bold">
                <FaLink className="mr-2" /> ShortLink Generator
              </h2>
            </CardHeader>
            <CardBody>
              <Button
                color="link"
                className="mb-3 p-0 text-decoration-none"
                onClick={() => setIsInstructionsOpen(!isInstructionsOpen)}
              >
                Instructions{" "}
                {isInstructionsOpen ? <FaChevronUp /> : <FaChevronDown />}
              </Button>
              <Collapse isOpen={isInstructionsOpen}>
                <Card className="mb-3">
                  <CardBody>
                    <ol className="pl-3">
                      <li>
                        Enter the long URL you want to shorten in the input
                        field.
                      </li>
                      <li>Click the "Generate Short Link" button.</li>
                      <li>Wait for the short link to be generated.</li>
                      <li>
                        Copy the generated short link using the copy button.
                      </li>
                      <li>Share your shortened URL!</li>
                    </ol>
                  </CardBody>
                </Card>
              </Collapse>

              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    type="url"
                    placeholder="Enter long URL"
                    value={longUrl}
                    onChange={handleUrlChange}
                    required
                  />
                </FormGroup>
                <Button type="submit" disabled={isLoading} block>
                  {isLoading ? "Creating..." : "Create Short Link"}
                </Button>
              </Form>

              {error && (
                <Alert color="danger" className="mt-3">
                  {error}
                </Alert>
              )}

              {shortUrl && (
                <div className="mt-4">
                  <h4 className="h5">Shortened URL:</h4>
                  <InputGroup>
                    <Input value={shortUrl} readOnly />
                    <InputGroupText>
                      <Button
                        color="secondary"
                        onClick={() => handleCopy(shortUrl)}
                      >
                        <FaCopy />
                      </Button>
                    </InputGroupText>
                  </InputGroup>
                  <small className="text-muted mt-2 d-block">
                    Click the copy icon to copy the short URL to your clipboard.
                  </small>
                </div>
              )}

              <div className="mt-5">
                <h3 className="h4 mb-3">Your Short Links</h3>
                <div className="table-responsive">
                  <Table hover className="w-100">
                    <thead>
                      <tr>
                        <th>Short URL</th>
                        <th>Original URL</th>
                        <th>Clicks</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shortLinks.map((link) => (
                        <tr key={link._id}>
                          <td className="text-break">{link.shortUrl}</td>
                          <td className="text-break">{link.longUrl}</td>
                          <td>{link?.clicks || 0}</td>
                          <td>
                            <Button
                              color="info"
                              size="sm"
                              className="mr-1 mb-1"
                              onClick={() => handleCopy(link.shortUrl)}
                            >
                              <FaCopy />
                            </Button>
                            <Button
                              color="warning"
                              size="sm"
                              className="mr-1 mb-1"
                              onClick={() => openEditModal(link)}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              className="mb-1"
                              onClick={() => handleDelete(link._id)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="mt-5">
                <h3 className="h4 mb-4 text-center">
                  Frequently Asked Questions
                </h3>
                <UncontrolledAccordion>
                  {faqData.map((faq, index) => (
                    <AccordionItem key={index}>
                      <AccordionHeader targetId={index.toString()}>
                        {faq.question}
                      </AccordionHeader>
                      <AccordionBody accordionId={index.toString()}>
                        {faq.answer}
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </UncontrolledAccordion>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={isEditModalOpen} toggle={closeEditModal}>
        <ModalHeader toggle={closeEditModal}>Edit Short Link</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditSubmit}>
            <FormGroup>
              <Label for="editLongUrl">Long URL</Label>
              <Input
                type="url"
                name="editLongUrl"
                id="editLongUrl"
                value={editingLink?.longUrl || ""}
                onChange={(e) =>
                  setEditingLink({
                    ...editingLink,
                    longUrl: e.target.value,
                  })
                }
                required
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
          <Button color="secondary" onClick={closeEditModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ShortLink;
