import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const TrackExpenses = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          Keeping Track of Expenses Like a Pro
        </CardTitle>

        <CardText>
          Efficient expense tracking helps you stay financially organized. Here’s how.
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          How to Track Expenses:
        </CardTitle>
        <CardText>
          <ul>
            <li>Go to the **Expenses** section.</li>
            <li>Add expenses manually or **upload receipts**.</li>
            <li>Assign **categories** for better organization.</li>
            <li>Generate **reports** for financial insights.</li>
          </ul>
        </CardText>

        <CardText>
          Regular tracking helps with budgeting and tax deductions.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default TrackExpenses;
