import axios from "axios";
import API_BASE_URL from "../apiConfig";

const TOKEN_KEYS = {
  ACCESS: "_appSignging",
  REFRESH: "_apprftoken",
};

const AUTH_ENDPOINTS = {
  REFRESH: "/api/auth/access",
  AUTH: "/auth",
};

let isRefreshing = false;

/**
 * Queue to store pending requests while token refresh is in progress
 * @type {Array<{ resolve: Function, reject: Function }>}
 */
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const storeToken = (key, token) => {
  localStorage.setItem(key, token);
};

const retrieveToken = (key) => {
  return localStorage.getItem(key);
};

const clearTokens = () => {
  localStorage.removeItem(TOKEN_KEYS.ACCESS);
  localStorage.removeItem(TOKEN_KEYS.REFRESH);
};

const axiosWithAuth = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

axiosWithAuth.interceptors.request.use(
  (config) => {
    const token = retrieveToken(TOKEN_KEYS.ACCESS);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Handles token refresh on 401 responses
 */
axiosWithAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Only handle 401 errors that haven't been retried
    if (
      !error.response ||
      error.response.status !== 401 ||
      originalRequest._retry
    ) {
      return Promise.reject(error);
    }

    // Queue requests if refresh is in progress
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosWithAuth(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    // Attempt token refresh
    originalRequest._retry = true;
    isRefreshing = true;

    try {
      const refreshToken = retrieveToken(TOKEN_KEYS.REFRESH);
      if (!refreshToken) {
        throw new Error("No refresh token available");
      }

      // Get new access token
      const response = await axios.post(
        `${API_BASE_URL}${AUTH_ENDPOINTS.REFRESH}`,
        { _apprftoken: refreshToken },
        { withCredentials: true }
      );

      const newToken = response.data.data.ac_token;
      storeToken(TOKEN_KEYS.ACCESS, newToken);

      // Update authorization headers
      axiosWithAuth.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;

      processQueue(null, newToken);
      return axiosWithAuth(originalRequest);
    } catch (refreshError) {
      processQueue(refreshError, null);
      clearTokens();
      window.location.href = AUTH_ENDPOINTS.AUTH;
      return Promise.reject(refreshError);
    } finally {
      isRefreshing = false;
    }
  }
);

export default axiosWithAuth;
