import { Link } from "react-router-dom";
import HelperFoxLogoSmall from './h-clear.png';

const LogoSmall = () => {
  return (
    <Link to="/">
      <img 
      src={HelperFoxLogoSmall} 
      style={{ width: '145px', height: '30px', marginLeft: '10px', marginRight: '70px' }}
      alt="Logo" />
    </Link>
  );
};

export default LogoSmall;