import axiosWithAuth from "../utils/axiosWithAuth";

export const invoiceApi = {
  getAllInvoices: async () => {
    const response = await axiosWithAuth.get("/api/invoices");
    return response.data;
  },

  getInvoice: async (invoiceId) => {
    const response = await axiosWithAuth.get(`/api/invoice/${invoiceId}`);
    return response.data;
  },

  createInvoice: async (invoiceData) => {
    const response = await axiosWithAuth.post("/api/invoice", {
      invoiceNumber: invoiceData.details.invoiceNumber,
      invoiceDate: invoiceData.details.date,
      dueDate: invoiceData.details.dueDate,
      clientName: `${invoiceData.customer.firstName} ${invoiceData.customer.lastName}`,
      email: invoiceData.customer.email,
      phoneNumber: invoiceData.customer.phoneNumber,
      lineItems: invoiceData.services.map((service) => ({
        description: service.description,
        quantity: service.quantity,
        price: service.rate,
        taxable: service.taxable || false,
      })),
      subtotal: invoiceData.services.reduce(
        (sum, service) => sum + service.amount,
        0
      ),
      tax: invoiceData.services.reduce(
        (sum, service) => sum + (service.taxable ? service.amount * 0.1 : 0),
        0
      ),
      total: invoiceData.services.reduce(
        (sum, service) =>
          sum + service.amount + (service.taxable ? service.amount * 0.1 : 0),
        0
      ),
      notes: invoiceData.details.notes,
      paymentTerms: invoiceData.details.paymentTerms,
      terms: invoiceData.details.terms,
      status: "Draft",
    });
    return response.data;
  },

  sendInvoice: async (invoiceData) => {
    const response = await axiosWithAuth.post("/api/invoice/send", {
      documentType: "INVOICE",
      invoiceNumber: invoiceData.details.invoiceNumber,
      billingInfo: {
        from: "Your Company Name\n123 Business St\nCity, State 12345",
        billTo: `${invoiceData.customer.firstName} ${invoiceData.customer.lastName}\n${
          invoiceData.customer.company
            ? invoiceData.customer.company + "\n"
            : ""
        }${invoiceData.customer.email}`,
        date: invoiceData.details.date,
        dueDate: invoiceData.details.dueDate,
        paymentTerms: invoiceData.details.paymentTerms,
        email: invoiceData.customer.email,
      },
      currency: { symbol: "$", code: "USD" },
      lineItems: invoiceData.services.map((service) => ({
        item: service.description,
        quantity: service.quantity,
        rate: service.rate,
        amount: service.amount,
        taxable: service.taxable || false,
      })),
      notes: invoiceData.details.notes,
      terms: invoiceData.details.terms,
      subtotal: invoiceData.services.reduce(
        (sum, service) => sum + service.amount,
        0
      ),
      taxPercent: 10,
      discountPercent: 0,
      shipping: 0,
      total: invoiceData.services.reduce(
        (sum, service) =>
          sum + service.amount + (service.taxable ? service.amount * 0.1 : 0),
        0
      ),
      customerId: invoiceData.customer._id,
    });
    return response.data;
  },

  sendNotification: async (invoiceId) => {
    const response = await axiosWithAuth.post(
      `/api/invoice/notify/${invoiceId}`
    );
    return response.data;
  },

  updateInvoice: async (invoiceId, updateData) => {
    const response = await axiosWithAuth.put(
      `/api/invoice/${invoiceId}`,
      updateData
    );
    return response.data;
  },

  deleteInvoice: async (invoiceId) => {
    const response = await axiosWithAuth.delete(`/api/invoice/${invoiceId}`);
    return response.data;
  },
};
