import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useSWR from "swr";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import ChartCard from "./ChartCard";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload?.length) {
    return null;
  }

  const amount = payload[0].value;
  const data = payload[0].payload;
  const percentage = data.percentage || 0;

  return (
    <div className="custom-tooltip bg-white p-3 shadow-lg rounded border">
      <p className="font-semibold mb-2">{label}</p>
      <p className="mb-1">Amount: {formatCurrency(amount)}</p>
      <p className="mb-0 text-gray-600">Percentage: {percentage.toFixed(1)}%</p>
    </div>
  );
};

const ExpensesCategoryChart = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/api/dashboard/charts/expenses-categories",
    async (url) => {
      try {
        const response = await axiosWithAuth.get(url);
        // Calculate percentages
        const total = response.data.categories.reduce(
          (sum, item) => sum + item.amount,
          0
        );
        const categoriesWithPercentage = response.data.categories.map(
          (item) => ({
            ...item,
            percentage: (item.amount / total) * 100,
          })
        );
        return { ...response.data, categories: categoriesWithPercentage };
      } catch (error) {
        throw error.response?.data || error.message;
      }
    },
    {
      refreshInterval: 5 * 60 * 1000, // 5 minutes
      revalidateOnFocus: false,
    }
  );

  return (
    <ChartCard
      title="Expenses by Category"
      isLoading={isLoading}
      error={error}
      onRetry={() => mutate()}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data?.categories || []}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2563eb" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#3b82f6" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
          <XAxis
            dataKey="category"
            tick={{ fill: "#4b5563" }}
            tickLine={{ stroke: "#4b5563" }}
          />
          <YAxis
            tickFormatter={formatCurrency}
            tick={{ fill: "#4b5563" }}
            tickLine={{ stroke: "#4b5563" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey="amount"
            fill="url(#colorGradient)"
            name="Expense Amount"
            radius={[6, 6, 0, 0]}
            animationDuration={1500}
            animationBegin={0}
            background={{ fill: "#f3f4f6" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartCard>
  );
};

export default ExpensesCategoryChart;
