import { useAtom, useSetAtom } from "jotai";
import { useState } from "react";
import { FaArrowLeft, FaArrowRight, FaEllipsisV } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledAccordion,
  UncontrolledDropdown,
} from "reactstrap";
import useSWR from "swr";
import {
  addExpenseAtom,
  expensesAtom,
  updateExpenseAtom,
} from "../../atoms/expensesAtom";
import ExpenseModal from "../../components/Modals/ExpenseModal";
import { useAuth } from "../../context/AuthContext";
import {
  createExpense,
  deleteExpense,
  fetchExpenses,
  updateExpense,
} from "../../lib/api/expense";

// Add the config object
const EXPENSE_CONFIG = {
  instructions: [
    "Enter expense details including date, merchant, and amount",
    "Upload receipts for your records (optional)",
    "Categorize expenses for better organization",
    "Assign expenses to specific clients if applicable",
    "Track which card was used for each expense",
  ],
  faqs: [
    {
      question: "How do I sync my expenses across devices?",
      answer:
        "Sign in to your account to automatically sync expenses across all your devices. Your expenses will be securely stored in the cloud.",
    },
    {
      question: "Can I export my expenses?",
      answer:
        "Yes, you can export your expenses to CSV or PDF format using the export button (coming soon).",
    },
    {
      question: "How do I upload receipts?",
      answer:
        "When adding or editing an expense, click the receipt field to upload an image or PDF of your receipt.",
    },
    {
      question: "What happens to my expenses if I'm not signed in?",
      answer:
        "Your expenses are stored locally in your browser. To prevent data loss and enable cross-device access, please sign in.",
    },
    {
      question: "How do I categorize expenses?",
      answer:
        "Use the category field when adding expenses. Consistent categorization helps with expense reporting and analysis.",
    },
  ],
};

const Expenses = () => {
  const [expenses, setExpenses] = useAtom(expensesAtom);
  const updateLocalExpense = useSetAtom(updateExpenseAtom);
  const createLocalExpense = useSetAtom(addExpenseAtom);
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const rowsPerPage = 10;
  const [expenseModalOpen, setExpenseModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [previewReceipt, setPreviewReceipt] = useState(null);

  // SWR fetcher that uses our API function
  const fetcher = async (url) => {
    if (!token) return null;
    return fetchExpenses(token);
  };

  // Use SWR for data fetching with local cache fallback
  const { data, error, isLoading, mutate } = useSWR(
    token ? `/api/expense` : null,
    fetcher,
    {
      onSuccess: (data) => {
        if (data) setExpenses(data);
      },
      onError: (err) => {
        toast.error("Failed to sync with cloud. Using local data.");
        // Keep using local expenses
      },
      revalidateOnFocus: false,
      fallbackData: expenses,
      revalidateIfStale: true,
    }
  );

  const handleSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction:
        current.key === key && current.direction === "asc" ? "desc" : "asc",
    }));
  };

  const getSortedExpenses = (expenses) => {
    if (!expenses) return [];
    return [...expenses].sort((a, b) => {
      if (sortConfig.key === "date") {
        return sortConfig.direction === "asc"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
      if (sortConfig.key === "amount") {
        return sortConfig.direction === "asc"
          ? parseFloat(a.amount) - parseFloat(b.amount)
          : parseFloat(b.amount) - parseFloat(a.amount);
      }
      return sortConfig.direction === "asc"
        ? a[sortConfig.key].localeCompare(b[sortConfig.key])
        : b[sortConfig.key].localeCompare(a[sortConfig.key]);
    });
  };

  const getFilteredExpenses = (expenses) => {
    return expenses.filter((expense) => {
      const matchesSearch =
        !searchTerm ||
        expense.merchant.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.client?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCategory =
        !selectedCategory || expense.category === selectedCategory;

      const matchesDate =
        (!dateRange.start ||
          new Date(expense.date) >= new Date(dateRange.start)) &&
        (!dateRange.end || new Date(expense.date) <= new Date(dateRange.end));

      return matchesSearch && matchesCategory && matchesDate;
    });
  };

  const handleSaveExpense = async (expenseData) => {
    try {
      let savedExpense = null;

      // Try to sync with backend if authenticated
      if (token) {
        try {
          if (isEditing && currentExpense) {
            const response = await updateExpense(
              currentExpense._id,
              expenseData,
              token
            );
            savedExpense = response.expense;
            updateLocalExpense(currentExpense._id, savedExpense);
          } else {
            const response = await createExpense(expenseData, token);
            savedExpense = response.expense;
            createLocalExpense(savedExpense);
          }
          await mutate();
        } catch (err) {
          toast.error("Failed to sync with cloud. Changes saved locally.");
          // Continue with local changes only
          if (isEditing && currentExpense) {
            updateLocalExpense(currentExpense._id, expenseData);
            savedExpense = { ...currentExpense, ...expenseData };
          } else {
            const newExpense = { ...expenseData, _id: Date.now().toString() };
            createLocalExpense(newExpense);
            savedExpense = newExpense;
          }
        }
      } else {
        // Handle local-only changes
        if (isEditing && currentExpense) {
          updateLocalExpense(currentExpense._id, expenseData);
          savedExpense = { ...currentExpense, ...expenseData };
        } else {
          const newExpense = { ...expenseData, _id: Date.now().toString() };
          createLocalExpense(newExpense);
          savedExpense = newExpense;
        }
      }

      toggleExpenseModal();
      return savedExpense; // Return the saved expense data
    } catch (error) {
      toast.error("Failed to save expense");
      console.error("Failed to save expense:", error);
      throw error;
    }
  };

  const handleDeleteExpense = async (expenseId) => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        // Delete locally first
        const updatedExpenses = expenses.filter((e) => e._id !== expenseId);
        setExpenses(updatedExpenses);

        // Then try to sync with backend
        if (token) {
          try {
            await deleteExpense(expenseId, token);
            await mutate();
          } catch (err) {
            toast.error(
              "Failed to sync deletion with cloud. Changes saved locally."
            );
            // Keep the local deletion
          }
        }
      } catch (err) {
        toast.error("Failed to delete expense");
        console.error("Failed to delete expense:", err);
      }
    }
  };

  const toggleExpenseModal = () => {
    setExpenseModalOpen(!expenseModalOpen);
    if (expenseModalOpen) {
      setCurrentExpense(null);
      setIsEditing(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditExpense = (expense) => {
    setCurrentExpense(expense);
    setIsEditing(true);
    setExpenseModalOpen(true);
  };

  const handleAddExpense = () => {
    setCurrentExpense(null);
    setIsEditing(false);
    setExpenseModalOpen(true);
  };

  const handlePreviewReceipt = (receipt) => {
    setPreviewReceipt(receipt);
  };

  // Use the data from SWR if available, otherwise use local expenses
  const displayExpenses = data || expenses;
  const filteredExpenses = getFilteredExpenses(
    getSortedExpenses(displayExpenses)
  );
  const totalPages = Math.ceil(filteredExpenses.length / rowsPerPage);
  const currentExpenses = filteredExpenses.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const uniqueCategories = [...new Set(displayExpenses.map((e) => e.category))];

  return (
    <Container className="mt-4">
      <h3>Expenses</h3>
      <hr />
      {!token && (
        <Alert color="warning" className="mb-3">
          Your expenses are currently stored locally. Sign in to sync them with
          the cloud and access them across devices.
        </Alert>
      )}
      {isLoading && (
        <Alert color="info" className="mb-3">
          Syncing expenses with the cloud...
        </Alert>
      )}

      {/* Filters and Search */}
      <Row className="mb-4">
        <Col md={3}>
          <Input
            type="text"
            placeholder="Search expenses..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col md={3}>
          <Input
            type="select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Categories</option>
            {uniqueCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={3}>
          <Input
            type="date"
            value={dateRange.start}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, start: e.target.value }))
            }
            placeholder="Start Date"
          />
        </Col>
        <Col md={3}>
          <Input
            type="date"
            value={dateRange.end}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, end: e.target.value }))
            }
            placeholder="End Date"
          />
        </Col>
      </Row>

      {/* Expense Table Card */}
      <Row className="mb-4">
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table hover striped className="align-middle custom-table">
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSort("date")}
                      style={{ cursor: "pointer" }}
                    >
                      Date{" "}
                      {sortConfig.key === "date" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      onClick={() => handleSort("merchant")}
                      style={{ cursor: "pointer" }}
                    >
                      Merchant{" "}
                      {sortConfig.key === "merchant" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      onClick={() => handleSort("amount")}
                      style={{ cursor: "pointer" }}
                    >
                      Amount{" "}
                      {sortConfig.key === "amount" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Receipt</th>
                    <th
                      onClick={() => handleSort("category")}
                      style={{ cursor: "pointer" }}
                    >
                      Category{" "}
                      {sortConfig.key === "category" &&
                        (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th>Card</th>
                    <th>Client</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentExpenses.map((expense) => (
                    <tr key={expense._id}>
                      <td>{new Date(expense.date).toLocaleDateString()}</td>
                      <td>{expense.merchant}</td>
                      <td>${parseFloat(expense.amount).toFixed(2)}</td>
                      <td>
                        {expense.receipt?.url ? (
                          <Button
                            color="link"
                            className="p-0"
                            onClick={() =>
                              handlePreviewReceipt(expense.receipt.url)
                            }
                          >
                            View Receipt
                          </Button>
                        ) : (
                          <span className="text-muted">No receipt</span>
                        )}
                      </td>
                      <td>
                        <span className="badge bg-primary">
                          {expense.category}
                        </span>
                      </td>
                      <td>{expense.card}</td>
                      <td>{expense.client}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="div"
                            className="btn btn-link"
                            style={{ cursor: "pointer" }}
                          >
                            <FaEllipsisV />
                          </DropdownToggle>
                          <DropdownMenu end>
                            <DropdownItem
                              onClick={() => handleEditExpense(expense)}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              className="text-danger"
                              onClick={() => handleDeleteExpense(expense._id)}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button color="primary" onClick={handleAddExpense}>
                + Add Expense
              </Button>

              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <FaArrowLeft />
                  </PaginationLink>
                </PaginationItem>
                {Array.from({ length: totalPages }, (_, i) => (
                  <PaginationItem key={i} active={currentPage === i + 1}>
                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <FaArrowRight />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </Row>

      {/* Receipt Preview Modal */}
      <Modal
        isOpen={!!previewReceipt}
        toggle={() => setPreviewReceipt(null)}
        size="lg"
      >
        <ModalHeader toggle={() => setPreviewReceipt(null)}>
          Receipt Preview
        </ModalHeader>
        <ModalBody>
          {previewReceipt && (
            <div className="text-center">
              {previewReceipt.endsWith(".pdf") ? (
                <iframe
                  src={previewReceipt}
                  style={{ width: "100%", height: "500px" }}
                  title="Receipt PDF"
                />
              ) : (
                <img
                  src={previewReceipt}
                  alt="Receipt"
                  style={{ maxWidth: "100%", maxHeight: "500px" }}
                />
              )}
            </div>
          )}
        </ModalBody>
      </Modal>

      {/* Instructions and FAQs moved to bottom */}
      <Row>
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Help & Information</h5>
            </div>

            <div className="mb-4">
              <h6 className="mb-3">Quick Start Instructions</h6>
              <ul className="ps-4">
                {EXPENSE_CONFIG.instructions.map((instruction, index) => (
                  <li key={index} className="mb-2">
                    {instruction}
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h6 className="mb-3">Frequently Asked Questions</h6>
              <UncontrolledAccordion>
                {EXPENSE_CONFIG.faqs.map((faq, index) => (
                  <AccordionItem key={index}>
                    <AccordionHeader targetId={index.toString()}>
                      {faq.question}
                    </AccordionHeader>
                    <AccordionBody accordionId={index.toString()}>
                      {faq.answer}
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </UncontrolledAccordion>
            </div>
          </CardBody>
        </Card>
      </Row>

      <ExpenseModal
        isOpen={expenseModalOpen}
        toggle={toggleExpenseModal}
        expense={currentExpense}
        isEditing={isEditing}
        onSave={handleSaveExpense}
      />
    </Container>
  );
};

export default Expenses;
