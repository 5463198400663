import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { Spinner } from "reactstrap";

import CSV from "../views/tools/CSV";
import CustomersTool from "../views/tools/Customers";
import { EstimateHistory } from "../views/tools/estimate-generator/components/EstimateHistory";
import { EstimateView } from "../views/tools/estimate-generator/components/EstimateView";
import { EstimateGenerator } from "../views/tools/estimate-generator/EstimateGenerator";
import Expenses from "../views/tools/Expenses";
import { InvoiceList } from "../views/tools/invoice-generator/components/InvoiceList";
import { InvoiceView } from "../views/tools/invoice-generator/components/InvoiceView";
import { InvoiceGenerator } from "../views/tools/invoice-generator/InvoiceGenerator";
import QR from "../views/tools/qr";
import ShortLink from "../views/tools/ShortLink";
import TenDLC from "../views/tools/TenDLC";

// Lazy load all form components
const URLForm = lazy(() => import("../views/tools/qr/forms/URLForm"));
const TextForm = lazy(() => import("../views/tools/qr/forms/TextForm"));
const VCardForm = lazy(() => import("../views/tools/qr/forms/VCardForm"));
const EmailForm = lazy(() => import("../views/tools/qr/forms/EmailForm"));
const SMSForm = lazy(() => import("../views/tools/qr/forms/SMSForm"));
const WifiForm = lazy(() => import("../views/tools/qr/forms/WifiForm"));
const TwitterForm = lazy(() => import("../views/tools/qr/forms/TwitterForm"));
const BitcoinForm = lazy(() => import("../views/tools/qr/forms/BitcoinForm"));
const PhoneCallForm = lazy(
  () => import("../views/tools/qr/forms/PhoneCallForm")
);
const SocialMediaForm = lazy(
  () => import("../views/tools/qr/forms/SocialMediaForm")
);
const PDFForm = lazy(() => import("../views/tools/qr/forms/PDFForm"));

export const useToolRoutes = () => {
  const getLazyRoute = (Component) => (
    <Suspense fallback={<Spinner color="primary" />}>
      <Component />
    </Suspense>
  );

  return [
    <Route key="qr" path="qr" element={<QR />}>
      <Route index element={getLazyRoute(URLForm)} />
      <Route path="url" element={getLazyRoute(URLForm)} />
      <Route path="text" element={getLazyRoute(TextForm)} />
      <Route path="vcard" element={getLazyRoute(VCardForm)} />
      <Route path="email" element={getLazyRoute(EmailForm)} />
      <Route path="sms" element={getLazyRoute(SMSForm)} />
      <Route path="wifi" element={getLazyRoute(WifiForm)} />
      <Route path="twitter" element={getLazyRoute(TwitterForm)} />
      <Route path="bitcoin" element={getLazyRoute(BitcoinForm)} />
      <Route path="phone" element={getLazyRoute(PhoneCallForm)} />
      <Route path="social" element={getLazyRoute(SocialMediaForm)} />
      <Route path="pdf" element={getLazyRoute(PDFForm)} />
    </Route>,
    <Route key="csv" path="csv" element={<CSV />} />,
    <Route path="invoices">
      <Route index element={<InvoiceList />} />
      <Route path="new" element={<InvoiceGenerator />} />
      <Route path=":invoiceId" element={<InvoiceView />} />
    </Route>,

    <Route key="estimates" path="estimates" element={<EstimateHistory />} />,
    <Route
      key="estimate-generator"
      path="estimates/new"
      element={<EstimateGenerator />}
    />,
    <Route
      key="estimate-view"
      path="estimate/:estimateId"
      element={<EstimateView />}
    />,
    <Route key="shortlink" path="shortlink" element={<ShortLink />} />,
    <Route key="tendlc" path="tendlc" element={<TenDLC />} />,
    <Route key="customers" path="customers" element={<CustomersTool />} />,
    <Route key="expenses" path="expenses" element={<Expenses />} />,
  ];
};
