import { format } from "date-fns";
import { useState } from "react";
import {
  Building,
  Calendar,
  Eye,
  FileText,
  Plus,
  Search,
  Send,
  Trash2,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import useSWR, { mutate } from "swr";
import {
  deleteEstimate,
  getEstimates,
  sendEstimate,
} from "../../../../lib/api/estimate";

export function EstimateHistory() {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [estimates, setEstimates] = useState([]);
  const navigate = useNavigate();

  const convertOldEstimateToNew = (oldEstimate) => {
    // Check if it's already in new format
    if (oldEstimate.customer && oldEstimate.details) {
      return oldEstimate;
    }

    // Convert old format to new format
    return {
      ...oldEstimate,
      customer: {
        firstName: oldEstimate.customerName?.split(" ")[0] || "",
        lastName: oldEstimate.customerName?.split(" ").slice(1).join(" ") || "",
        email: oldEstimate.customerEmail || oldEstimate.email || "",
        phone: oldEstimate.customerPhone || oldEstimate.phoneNumber || "",
        company: oldEstimate.customerCompany || oldEstimate.company || "",
        address: oldEstimate.streetAddress || "",
        city: oldEstimate.city || "",
        state: oldEstimate.state || "",
        zipCode: oldEstimate.zipCode || "",
      },
      details: {
        estimateNumber:
          oldEstimate.estimateNumber ||
          oldEstimate.estimateId ||
          `EST-${Date.now()}`,
        date: oldEstimate.date || oldEstimate.createdAt,
        expiryDate: oldEstimate.expiryDate || oldEstimate.dueDate,
        notes: oldEstimate.notes || "",
        terms: oldEstimate.terms || "",
      },
      services: (oldEstimate.services || oldEstimate.lineItems || []).map(
        (service) => ({
          id: service.id || String(Date.now() + Math.random()),
          description: service.description || service.item || "",
          quantity: service.quantity || 0,
          rate: service.rate || service.price || 0,
          amount: service.amount || service.quantity * service.rate || 0,
        })
      ),
      total: oldEstimate.total || 0,
      status: oldEstimate.status || "Draft",
      createdAt: oldEstimate.createdAt || new Date().toISOString(),
      updatedAt: oldEstimate.updatedAt || new Date().toISOString(),
    };
  };

  const { data, error, isLoading } = useSWR(
    "/api/expenses",
    async () => {
      const estimatesRes = await getEstimates();
      const convertedEstimates = estimatesRes.map(convertOldEstimateToNew);
      setEstimates(convertedEstimates);
      return estimatesRes;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleDelete = async (estimateId) => {
    if (!window.confirm("Are you sure you want to delete this estimate?")) {
      return;
    }

    try {
      await deleteEstimate(estimateId);
      toast.success("Estimate deleted successfully");
      // Update local state and invalidate SWR cache
      setEstimates((prev) =>
        prev.filter(
          (estimate) => estimate.details.estimateNumber !== estimateId
        )
      );
      mutate("/api/expenses");
    } catch (error) {
      console.error("Error deleting estimate:", error);
      toast.error("Failed to delete estimate");
    }
  };

  const handleSendReminder = async (estimateId) => {
    try {
      await sendEstimate({ id: estimateId, type: "reminder" });
      toast.success("Reminder sent successfully");
      mutate("/api/expenses");
    } catch (error) {
      console.error("Error sending reminder:", error);
      toast.error("Failed to send reminder");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Draft":
        return "warning";
      case "Sent":
        return "primary";
      case "Viewed":
        return "info";
      case "Accepted":
        return "success";
      case "Declined":
        return "danger";
      case "Expired":
        return "secondary";
      default:
        return "secondary";
    }
  };

  const filteredEstimates = estimates
    .filter((estimate) => {
      const matchesSearch =
        `${estimate.customer?.firstName} ${estimate.customer?.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        estimate.details?.estimateNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      const matchesStatus =
        statusFilter === "all" || estimate.status === statusFilter;
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      if (sortBy === "date") {
        return sortOrder === "desc"
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : new Date(a.createdAt) - new Date(b.createdAt);
      }
      if (sortBy === "amount") {
        return sortOrder === "desc" ? b.total - a.total : a.total - b.total;
      }
      return 0;
    });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <Spinner className="me-2" />
        <span>Loading estimates...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error.message}
      </div>
    );
  }

  return (
    <div className="container-fluid py-4">
      <Card className="shadow-sm border-0">
        <CardBody>
          <Row className="align-items-center mb-4">
            <Col>
              <div className="d-flex align-items-center">
                <div
                  className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                  style={{ width: "48px", height: "48px" }}
                >
                  <FileText size={24} className="text-primary" />
                </div>
                <div>
                  <h4 className="mb-1">Estimates</h4>
                  <div className="text-muted">
                    Manage and track your estimates
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => navigate("/estimates/new")}
              >
                <Plus size={18} className="me-2" />
                New Estimate
              </Button>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={4}>
              <InputGroup>
                <InputGroupText
                  style={{
                    backgroundColor: "#f8f9fa",
                    height: "43.5px",
                    padding: "0 12px",
                  }}
                  className="bg-white border-end-0"
                >
                  <Search size={16} className="text-muted" />
                </InputGroupText>
                <Input
                  placeholder="Search estimates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border-start-0 ps-0"
                />
              </InputGroup>
            </Col>
            <Col md={8} className="d-flex justify-content-end gap-2">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <DropdownToggle caret color="light">
                  Status: {statusFilter === "all" ? "All" : statusFilter}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setStatusFilter("all")}>
                    All
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Draft")}>
                    Draft
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Sent")}>
                    Sent
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Viewed")}>
                    Viewed
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Accepted")}>
                    Accepted
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Declined")}>
                    Declined
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Expired")}>
                    Expired
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          <div className="table-responsive">
            <Table hover className="mb-0 align-middle custom-table">
              <thead className="bg-light">
                <tr>
                  <th className="border-0">Estimate</th>
                  <th className="border-0">Customer</th>
                  <th className="border-0">Date</th>
                  <th className="border-0">Expiry</th>
                  <th className="border-0 text-end">Amount</th>
                  <th className="border-0">Status</th>
                  <th className="border-0" style={{ width: "150px" }}></th>
                </tr>
              </thead>
              <tbody>
                {filteredEstimates.map((estimate) => (
                  <tr key={estimate.details.estimateNumber}>
                    <td className="border-0">
                      <div className="fw-medium">
                        {estimate.details.estimateNumber}
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="d-flex align-items-center">
                        <div
                          className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                          style={{ width: "32px", height: "32px" }}
                        >
                          <Building size={16} className="text-primary" />
                        </div>
                        <div>
                          <div className="fw-medium">
                            {`${estimate.customer.firstName} ${estimate.customer.lastName}`}
                          </div>
                          <small className="text-muted">
                            {estimate.customer.email}
                          </small>
                        </div>
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="d-flex align-items-center text-muted">
                        <Calendar size={14} className="me-2" />
                        {format(new Date(estimate.details.date), "MMM d, yyyy")}
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="d-flex align-items-center text-muted">
                        <Calendar size={14} className="me-2" />
                        {format(
                          new Date(estimate.details.expiryDate),
                          "MMM d, yyyy"
                        )}
                      </div>
                    </td>
                    <td className="border-0 text-end fw-medium">
                      ${estimate.total.toFixed(2)}
                    </td>
                    <td className="border-0">
                      <Badge color={getStatusColor(estimate.status)}>
                        {estimate.status}
                      </Badge>
                    </td>
                    <td className="border-0">
                      <div className="d-flex gap-2 justify-content-end">
                        <Button
                          color="light"
                          size="sm"
                          className="btn-icon"
                          onClick={() =>
                            navigate(
                              `/estimate/${estimate.details.estimateNumber}`
                            )
                          }
                          title="View"
                        >
                          <Eye size={14} />
                        </Button>
                        {estimate.status === "Sent" && (
                          <Button
                            color="light"
                            size="sm"
                            className="btn-icon"
                            onClick={() =>
                              handleSendReminder(
                                estimate.details.estimateNumber
                              )
                            }
                            title="Send Reminder"
                          >
                            <Send size={14} />
                          </Button>
                        )}
                        <Button
                          color="light"
                          size="sm"
                          className="btn-icon"
                          onClick={() =>
                            handleDelete(estimate.details.estimateNumber)
                          }
                          title="Delete"
                        >
                          <Trash2 size={14} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredEstimates.length === 0 && (
                  <tr>
                    <td
                      colSpan="7"
                      className="text-center py-5 text-muted border-0"
                    >
                      <FileText size={24} className="mb-2" />
                      <div>No estimates found</div>
                      <small>
                        {searchTerm || statusFilter !== "all"
                          ? "Try adjusting your filters"
                          : "Create your first estimate to get started"}
                      </small>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
