import { format } from "date-fns";
import { useState } from "react";
import { ArrowLeft, Send } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import useSWR, { mutate } from "swr";
import config from "../../../../config";
import { useAuth } from "../../../../context/AuthContext";

const fetcher = async (url, token) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to load estimate");
  }
  return response.json();
};

export function EstimateView() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { estimateId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  const {
    data: estimate,
    error,
    isLoading,
  } = useSWR(
    token ? [`${config.apiDomain}/api/estimate/${estimateId}`, token] : null,
    ([url, token]) => fetcher(url, token),
    {
      revalidateOnFocus: false,
    }
  );

  const handleSendReminder = async () => {
    if (!token || !estimateId) return;

    setIsSending(true);
    try {
      const response = await fetch(
        `${config.apiDomain}/api/estimate/notify/${estimateId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send reminder");
      }

      toast.success("Reminder sent successfully!");
      setIsModalOpen(false);
      // Revalidate the estimate data
      mutate([`${config.apiDomain}/api/estimate/${estimateId}`, token]);
    } catch (error) {
      console.error("Error sending reminder:", error);
      toast.error("Failed to send reminder");
    } finally {
      setIsSending(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Draft":
        return "warning";
      case "Sent":
        return "primary";
      case "Viewed":
        return "info";
      case "Accepted":
        return "success";
      case "Declined":
        return "danger";
      case "Expired":
        return "secondary";
      default:
        return "secondary";
    }
  };

  if (isLoading) {
    return (
      <div className="text-center py-5">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <Alert color="danger" className="my-3">
        {error.message}
      </Alert>
    );
  }

  if (!estimate) {
    return (
      <Alert color="warning" className="my-3">
        Estimate not found
      </Alert>
    );
  }

  return (
    <div className="container-fluid py-4">
      <div className="mb-4">
        <Button
          color="link"
          className="px-0"
          onClick={() => navigate("/estimates")}
        >
          <ArrowLeft size={18} className="me-2" />
          Back to Estimates
        </Button>
      </div>

      <Card className="shadow-sm border-0">
        <CardBody>
          <Row className="mb-4">
            <Col>
              <h4 className="mb-1">
                Estimate #{estimate.details.estimateNumber}
              </h4>
              <div className="text-muted">
                Created on{" "}
                {format(new Date(estimate.createdAt), "MMMM d, yyyy")}
              </div>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => setIsModalOpen(true)}
                disabled={estimate.status !== "Sent"}
              >
                <Send size={14} className="me-2" />
                Send Reminder
              </Button>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <Card className="h-100">
                <CardBody>
                  <h6 className="mb-3">Customer Information</h6>
                  <div className="mb-2">
                    <strong>
                      {estimate.customer.firstName} {estimate.customer.lastName}
                    </strong>
                  </div>
                  {estimate.customer.company && (
                    <div className="mb-2">{estimate.customer.company}</div>
                  )}
                  <div>{estimate.customer.address}</div>
                  <div>
                    {estimate.customer.city}, {estimate.customer.state}{" "}
                    {estimate.customer.zipCode}
                  </div>
                  <div className="mt-2">
                    <div>Email: {estimate.customer.email}</div>
                    {estimate.customer.phone && (
                      <div>Phone: {estimate.customer.phone}</div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="h-100">
                <CardBody>
                  <h6 className="mb-3">Estimate Details</h6>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-2">
                        <small className="text-muted">Status</small>
                        <div>
                          <Badge color={getStatusColor(estimate.status)}>
                            {estimate.status}
                          </Badge>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-2">
                        <small className="text-muted">Valid Until</small>
                        <div>
                          {format(
                            new Date(estimate.details.expiryDate),
                            "MMMM d, yyyy"
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="mb-2">
                        <small className="text-muted">Date</small>
                        <div>
                          {format(
                            new Date(estimate.details.date),
                            "MMMM d, yyyy"
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="mb-2">
                        <small className="text-muted">Total Amount</small>
                        <div className="h5 mb-0">
                          ${estimate.total.toFixed(2)}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card className="mb-4">
            <CardBody>
              <h6 className="mb-3">Services</h6>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th className="text-end">Quantity</th>
                    <th className="text-end">Rate</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {estimate.services.map((service) => (
                    <tr key={service.id}>
                      <td>{service.description}</td>
                      <td className="text-end">{service.quantity}</td>
                      <td className="text-end">${service.rate.toFixed(2)}</td>
                      <td className="text-end">${service.amount.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3" className="text-end">
                      <strong>Total</strong>
                    </td>
                    <td className="text-end">
                      <strong>${estimate.total.toFixed(2)}</strong>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </CardBody>
          </Card>

          {(estimate.details.notes || estimate.details.terms) && (
            <Row className="g-4">
              {estimate.details.notes && (
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <h6 className="mb-3">Notes</h6>
                      <div className="text-muted">{estimate.details.notes}</div>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {estimate.details.terms && (
                <Col md={estimate.details.notes ? 6 : 12}>
                  <Card>
                    <CardBody>
                      <h6 className="mb-3">Terms and Conditions</h6>
                      <div className="text-muted">{estimate.details.terms}</div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        size="md"
      >
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          Send Reminder
        </ModalHeader>
        <ModalBody>
          <Alert color="info" className="mb-0">
            <div className="d-flex">
              <div
                className="rounded-circle bg-info bg-opacity-10 d-flex align-items-center justify-content-center me-3 flex-shrink-0"
                style={{ width: "48px", height: "48px" }}
              >
                <Send size={20} className="text-info" />
              </div>
              <div>
                <div className="fw-medium mb-1">Confirm Sending Reminder</div>
                <div>
                  A reminder email will be sent to{" "}
                  <strong>{estimate.customer.email}</strong> about this
                  estimate.
                </div>
              </div>
            </div>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setIsModalOpen(false)}
            disabled={isSending}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSendReminder}
            disabled={isSending}
          >
            {isSending ? (
              <>
                <Spinner size="sm" className="me-2" /> Sending...
              </>
            ) : (
              "Send Reminder"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
