import { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useCustomers } from "../../hooks/useCustomers";

const CustomerModal = ({ isOpen, toggle, customerId, onSuccess }) => {
  const { updateCustomer, deleteCustomer, createCustomer, customers } =
    useCustomers();
 

  const [customer, setCustomer] = useState(() => {
    if (customerId) {
      const foundCustomer = customers.find((c) => c._id === customerId);
      if (foundCustomer) {
        const validStatuses = [
          ...stages.map((s) => s.key),
          "closed-won",
          "closed-lost",
        ];
        if (!validStatuses.includes(foundCustomer.status)) {
          return { ...foundCustomer, status: "new" };
        }
        return foundCustomer;
      }
    }
    return emptyCustomer;
  });
  const [errorMessage, setErrorMessage] = useState("");

  const isCreating = !customerId;


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      setErrorMessage(""); // Clear any previous errors
      const updates = { ...customer };

      if (
        customer.status &&
        (!customer?.statusHistory?.length ||
          customer.status !== customer?.status)
      ) {
        const newHistoryEntry = {
          status: customer.status,
          timestamp: new Date(),
          notes: "",
        };

        updates.statusHistory = [
          ...(customer?.statusHistory || []),
          newHistoryEntry,
        ];
      }

      if (isCreating) {
        const createdCustomer = await createCustomer(updates);
        toast.success("Customer created successfully!");
        onSuccess(createdCustomer);
      } else {
        const updatedCustomer = await updateCustomer(customer._id, updates);
        toast.success("Customer updated successfully!");
        onSuccess(updatedCustomer);
      }
      setCustomer(emptyCustomer);
      toggle();
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.response?.message ||
        error.message ||
        "An unexpected error occurred";
      setErrorMessage(message);
      console.log(error.response?.message);
      toast.error(
        isCreating ? "Failed to create customer" : "Failed to update customer"
      );
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await deleteCustomer(customer._id);
        toggle();
        onSuccess(null);
        toast.success("Customer deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete customer: ", error.message);
      }
    }
  };

  useEffect(() => {
    if (customerId) {
      const foundCustomer = customers.find((c) => c._id === customerId);
      if (foundCustomer) {
        const validStatuses = [
          ...stages.map((s) => s.key),
          "closed-won",
          "closed-lost",
        ];
        if (!validStatuses.includes(foundCustomer.status)) {
          setCustomer({ ...foundCustomer, status: "new" });
        } else {
          setCustomer(foundCustomer);
        }
      }
    }
  }, [customerId, customers]);

  return (
    <>
      <style>{styles.statusBar}</style>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {isCreating ? "Add New Customer" : "Edit Customer"}
        </ModalHeader>
        <ModalBody>
          {errorMessage && (
            <Alert color="danger" className="mb-4">
              {errorMessage}
            </Alert>
          )}
          <Form className="px-3">
            <StatusBar
              currentStatus={customer.status}
              onStatusChange={(newStatus) => {
                setCustomer((prev) => ({
                  ...prev,
                  status: newStatus,
                }));
              }}
            />
            <Row className="mt-5 ">
              <Col md={6}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={customer.firstName || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={customer.lastName || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="phoneNumber">Phone Number</Label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={customer.phoneNumber || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={customer.email || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="company">Company</Label>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    value={customer.company || ""}
                    onChange={handleInputChange}
                    placeholder="Company (Optional)"
                  />
                </FormGroup>
              </Col>
            </Row>
          
            <FormGroup>
              <Label for="notes">Notes (Optional)</Label>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                value={customer.notes || ""}
                onChange={handleInputChange}
                placeholder="Add any additional notes..."
                rows={4}
              />
            </FormGroup>
          </Form>
          <StatusTimeline history={customer.statusHistory} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {isCreating ? "Create Customer" : "Save Changes"}
          </Button>
          {!isCreating ? (
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
          ) : null}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomerModal;

const getStatusColor = (stageKey) => {
  switch (stageKey) {
    case "new":
      return "warning";
    case "contacted":
      return "info";
    case "in-progress":
      return "primary";
    case "close":
      return "secondary";
    case "closed-won":
      return "success";
    case "closed-lost":
      return "danger";
    default:
      return "secondary";
  }
};

const styles = {
  statusBar: `
    .status-bar button {
      min-height: 40px;
      transition: all 0.3s ease;
      position: relative;
    }
    
    .status-bar .status-line {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #e9ecef;
      z-index: 0;
    }
    
    .status-bar .status-buttons {
      position: relative;
      z-index: 1;
    }
    
    .status-bar .status-label {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 100%;
      left: 0;
      margin-top: 8px;
      font-size: 12px;
    }
  `,
};

const stages = [
  { key: "new", label: "New" },
  { key: "contacted", label: "Contacted" },
  { key: "in-progress", label: "In Progress" },
  { key: "close", label: "Close" },
];

const StatusBar = ({ currentStatus, onStatusChange }) => {
  const [showCloseDropdown, setShowCloseDropdown] = useState(false);
  const isDirtyRef = useRef(false);

  const closeOptions = [
    { key: "closed-won", label: "Won" },
    { key: "closed-lost", label: "Lost" },
  ];

  useEffect(() => {
    if (isDirtyRef.current) return;
    if (!currentStatus || !stages.some((s) => s.key === currentStatus)) {
      onStatusChange("new");
      isDirtyRef.current = true;
    }
  }, [currentStatus, onStatusChange]);

  const isCompleted = (stageKey) => {
    if (currentStatus === "closed-won" || currentStatus === "closed-lost") {
      if (stageKey === "close") return true;
      const stageIndex = stages.findIndex((s) => s.key === stageKey);
      const progressIndex = stages.findIndex((s) => s.key === "in-progress");
      return stageIndex <= progressIndex;
    }

    const stageIndex = stages.findIndex((s) => s.key === stageKey);
    const currentIndex = stages.findIndex((s) => s.key === currentStatus);
    return stageIndex <= currentIndex;
  };

  return (
    <div className="status-bar mb-5">
      <Label className="mb-2">Customer Status</Label>
      <div className="position-relative py-4">
        <div className="status-line" />
        <div className="d-flex justify-content-between status-buttons">
          {stages.map(({ key, label }) => (
            <div key={key} className="position-relative">
              <Button
                color={isCompleted(key) ? getStatusColor(key) : "light"}
                className="rounded-circle p-2"
                style={{ width: "40px", height: "40px" }}
                onClick={() => {
                  if (key === "close") {
                    setShowCloseDropdown(!showCloseDropdown);
                  } else {
                    if (
                      (currentStatus === "closed-won" ||
                        currentStatus === "closed-lost") &&
                      key !== currentStatus
                    ) {
                      return;
                    }
                    onStatusChange(key);
                    setShowCloseDropdown(false);
                  }
                }}
              >
                {isCompleted(key) && <FaCheck className="m-0" />}
                <div className="status-label text-dark">{label}</div>
              </Button>

              {key === "close" && showCloseDropdown && (
                <div
                  className="position-absolute bg-white shadow rounded p-2 mt-2"
                  style={{
                    zIndex: 1000,
                    width: "120px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {closeOptions.map((option) => (
                    <Button
                      key={option.key}
                      color={
                        currentStatus === option.key
                          ? getStatusColor(option.key)
                          : "light"
                      }
                      className="w-100 mb-1 d-flex align-items-center justify-content-between"
                      size="sm"
                      onClick={() => {
                        onStatusChange(option.key);
                        setShowCloseDropdown(false);
                      }}
                    >
                      {option.label}
                      {currentStatus === option.key && <FaCheck size={12} />}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StatusTimeline = ({ history }) => {
  if (!history?.length) return null;

  return (
    <div className="status-timeline mt-4">
      <Label className="mb-3">Status History</Label>
      <div className="timeline-items">
        {history.map((item, index) => (
          <div key={index} className="timeline-item d-flex mb-3">
            <div className="timeline-marker me-3">
              <div
                className={`badge bg-${getStatusColor(item.status)}`}
                style={{ width: "10px", height: "10px", borderRadius: "50%" }}
              />
            </div>
            <div className="timeline-content">
              <div className="d-flex justify-content-between">
                <strong>{item.status.replace(/-/g, " ").toUpperCase()}</strong>
                <small className="text-muted">
                  {new Date(item.timestamp).toLocaleString()}
                </small>
              </div>
              {item.notes && <p className="mb-0 text-muted">{item.notes}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const emptyCustomer = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  company: "",
  status: "",
  notes: "",
  statusHistory: [],
};
