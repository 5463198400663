import { useAtom, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  clearCurrentQRCodeAtom,
  currentQRCodeAtom,
  deleteQRCodeAtom,
  savedQRCodesAtom,
} from "../atoms/qrCodeAtoms";

const TYPE_TO_ROUTE = {
  url: "url",
  text: "text",
  vcard: "vcard",
  email: "email",
  sms: "sms",
  wifi: "wifi",
  twitter: "twitter",
  bitcoin: "bitcoin",
  "phone call": "phone",
  "social media": "social",
  pdf: "pdf",
};
const QRCodeLoadForm = () => {
  const navigate = useNavigate();
  const [savedQRCodes] = useAtom(savedQRCodesAtom);
  const [currentQRCode] = useAtom(currentQRCodeAtom);
  const clearCurrentQRCode = useSetAtom(clearCurrentQRCodeAtom);
  const updateCurrentQRCode = useSetAtom(currentQRCodeAtom);
  const deleteQRCode = useSetAtom(deleteQRCodeAtom);

  const handleLoadQRCode = (e) => {
    const selectedId = e.target.value;
    if (selectedId === "new") {
      clearCurrentQRCode();
      navigate("/qr/url");
    } else {
      const selectedQRCode = savedQRCodes.find((qr) => qr.id === selectedId);
      if (selectedQRCode) {
        const qrType = selectedQRCode.type.toLowerCase();
        const routePath = TYPE_TO_ROUTE[qrType] || "url";
        navigate(`/qr/${routePath}?qrId=${selectedQRCode.id}`);
        updateCurrentQRCode(selectedQRCode);
      }
    }
  };

  const handleDeleteQRCode = () => {
    if (currentQRCode) {
      deleteQRCode(currentQRCode.id);
      clearCurrentQRCode();
      navigate("/qr/url");
    }
  };

  return (
    <Form className="d-flex align-items-center gap-3">
      <FormGroup className="mb-0">
        <Label for="loadQRCode" className="mb-0 me-2">
          Load QR Code
        </Label>
        <Input
          type="select"
          id="loadQRCode"
          value={currentQRCode?.id || "new"}
          onChange={handleLoadQRCode}
          style={{ minWidth: "200px" }}
        >
          <option value="new">Create New</option>
          {savedQRCodes.map((qr) => (
            <option key={qr.id} value={qr.id}>
              {qr.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      {currentQRCode && (
        <Button color="danger" size="sm" onClick={handleDeleteQRCode}>
          Delete QR Code
        </Button>
      )}
    </Form>
  );
};

export default QRCodeLoadForm;
