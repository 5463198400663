import { createContext, useContext } from "react";

export const EstimateContext = createContext({
  estimateData: {
    customer: null,
    services: [],
    details: {
      estimateNumber: "",
      date: new Date().toISOString(),
      expiryDate: "",
      notes: "",
      terms: "",
    },
  },
  updateEstimateData: () => {},
});

export const useEstimate = () => {
  const context = useContext(EstimateContext);
  if (!context) {
    throw new Error("useEstimate must be used within an EstimateProvider");
  }
  return context;
};
