import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const InvoiceBestPractices = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          Invoice Best Practices: Get Paid on Time
        </CardTitle>

        <CardText>
          Maximize efficiency with these best practices for invoicing.
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          Best Practices:
        </CardTitle>
        <CardText>
          <ul>
            <li>Use **clear itemized descriptions** for services.</li>
            <li>Set **due dates** and include late payment terms.</li>
            <li>Enable **automatic reminders** for unpaid invoices.</li>
            <li>Offer multiple **payment methods** for convenience.</li>
          </ul>
        </CardText>

        <CardText>
          Following these tips ensures timely payments and better cash flow.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default InvoiceBestPractices;
