import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const ImportCustomers = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          Importing Customers: A Step-by-Step Guide
        </CardTitle>

        <CardText>
          Streamline your workflow by importing customer data into HelperFox. Follow these steps:
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          How to Import Customers:
        </CardTitle>
        <CardText>
          <ol>
            <li>Navigate to the **Customers** tab.</li>
            <li>Click on **Import Customers** and select your CSV file.</li>
            <li>Map the columns correctly (Name, Email, Phone, etc.).</li>
            <li>Review and confirm the import.</li>
          </ol>
        </CardText>

        <CardText>
          Your customers will now be available for invoicing and tracking.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default ImportCustomers;
