import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useAuthContext } from "../../hooks/useAuthContext";
import { businessDetailsApi } from "../../lib/api/businessDetails";
import { countries } from "./data/countries";
import { usStates } from "./data/states";

const BusinessDetails = () => {
  const { user, token } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [businessData, setBusinessData] = useState({
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "United States",
    provinceState: "",
    postalCode: "",
    timeZone: "America/New_York",
    phone: "",
    fax: "",
    mobile: "",
    tollFree: "",
    website: "",
    businessCurrency: "USD - U.S. dollar",
  });

  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const isUSA = businessData.country === "United States";

  useEffect(() => {
    if (token) {
      fetchBusinessDetails();
    }
  }, [token]);

  const fetchBusinessDetails = async () => {
    try {
      setLoading(true);
      const response = await businessDetailsApi.get(token);
      if (response.data) {
        setBusinessData(response.data);
        if (response.data.logo?.url) {
          setLogoPreview(response.data.logo.url);
        }
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        // Ignore 404 as it just means no business details exist yet
        toast.error("Failed to fetch business details");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const response = await businessDetailsApi.uploadLogo(file, token);
        setLogoPreview(response.data.logo.url);
        toast.success("Logo uploaded successfully");
      } catch (error) {
        toast.error("Failed to upload logo");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      toast.error("Please sign in to save business details");
      return;
    }

    try {
      setLoading(true);
      let response;

      // If we're editing existing details, use update endpoint
      if (businessData._id) {
        response = await businessDetailsApi.update(businessData, token);
      } else {
        // If no details exist yet, use create endpoint
        response = await businessDetailsApi.create(businessData, token);
      }

      setBusinessData(response.data);
      toast.success("Business details saved successfully");
    } catch (error) {
      toast.error("Failed to save business details");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h3>Business Details</h3>
      <hr />
      <Row className="justify-content-center">
        <Col lg={10} md={12}>
          {!token && (
            <Alert color="warning" className="mb-4">
              Please{" "}
              <Link to={"/auth"} className="link">
                sign in
              </Link>{" "}
              to manage your business details
            </Alert>
          )}

          <Card className="shadow-sm">
            <CardBody className="p-4">
              {loading && (
                <div className="text-center mb-3">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {/* <CardTitle tag="h4" className="mb-4">
                Business Details
              </CardTitle> */}
              <Form onSubmit={handleSubmit}>
                {/* Company Info Section */}
                <div className="mb-4">
                  <h5 className="text-muted mb-3">Company Information</h5>
                  <Row>
                    <Col md={8}>
                      <FormGroup>
                        <Label for="companyName" className="fw-bold">
                          Company Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="companyName"
                          id="companyName"
                          value={businessData.companyName}
                          onChange={handleInputChange}
                          required
                          className="form-control-lg"
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="fw-bold">Company Logo</Label>
                        <div className="d-flex flex-column align-items-center border rounded p-3">
                          {logoPreview ? (
                            <div className="mb-3 position-relative">
                              <img
                                src={logoPreview}
                                alt="Company logo preview"
                                className="img-fluid"
                                style={{
                                  maxWidth: "150px",
                                  maxHeight: "150px",
                                  objectFit: "contain",
                                }}
                              />
                              <Button
                                color="danger"
                                size="sm"
                                className="position-absolute top-0 end-0"
                                onClick={() => {
                                  setLogo(null);
                                  setLogoPreview(null);
                                }}
                                disabled={!token}
                              >
                                <i className="bi bi-x"></i>
                              </Button>
                            </div>
                          ) : (
                            <div className="text-center mb-3">
                              <i
                                className="bi bi-image text-muted"
                                style={{ fontSize: "2rem" }}
                              ></i>
                              <p className="small text-muted mb-0">
                                Recommended size: 200x200px
                              </p>
                            </div>
                          )}
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={handleLogoChange}
                            className="form-control"
                            disabled={!token}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                {/* Address Section */}
                <div className="mb-4">
                  <h5 className="text-muted mb-3">Business Address</h5>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="addressLine1" className="fw-bold">
                          Address Line 1
                        </Label>
                        <Input
                          type="text"
                          name="addressLine1"
                          id="addressLine1"
                          value={businessData.addressLine1}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="addressLine2" className="fw-bold">
                          Address Line 2
                        </Label>
                        <Input
                          type="text"
                          name="addressLine2"
                          id="addressLine2"
                          value={businessData.addressLine2}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="city" className="fw-bold">
                          City
                        </Label>
                        <Input
                          type="text"
                          name="city"
                          id="city"
                          value={businessData.city}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="country" className="fw-bold">
                          Country<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          name="country"
                          id="country"
                          value={businessData.country}
                          onChange={handleInputChange}
                          required
                          disabled={!token}
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="provinceState" className="fw-bold">
                          {isUSA ? "State" : "Province/State"}
                          <span className="text-danger">*</span>
                        </Label>
                        {isUSA ? (
                          <Input
                            type="select"
                            name="provinceState"
                            id="provinceState"
                            value={businessData.provinceState}
                            onChange={handleInputChange}
                            required
                            disabled={!token}
                          >
                            <option value="">Select State</option>
                            {usStates.map((state) => (
                              <option key={state.code} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </Input>
                        ) : (
                          <Input
                            type="text"
                            name="provinceState"
                            id="provinceState"
                            value={businessData.provinceState}
                            onChange={handleInputChange}
                            placeholder="Enter state or province"
                            required
                            disabled={!token}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="postalCode" className="fw-bold">
                          Postal/Zip Code
                        </Label>
                        <Input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          value={businessData.postalCode}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                {/* Contact Section */}
                <div className="mb-4">
                  <h5 className="text-muted mb-3">Contact Information</h5>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="phone" className="fw-bold">
                          Phone
                        </Label>
                        <Input
                          type="tel"
                          name="phone"
                          id="phone"
                          value={businessData.phone}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="mobile" className="fw-bold">
                          Mobile
                        </Label>
                        <Input
                          type="tel"
                          name="mobile"
                          id="mobile"
                          value={businessData.mobile}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="fax" className="fw-bold">
                          Fax
                        </Label>
                        <Input
                          type="tel"
                          name="fax"
                          id="fax"
                          value={businessData.fax}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="tollFree" className="fw-bold">
                          Toll Free
                        </Label>
                        <Input
                          type="tel"
                          name="tollFree"
                          id="tollFree"
                          value={businessData.tollFree}
                          onChange={handleInputChange}
                          disabled={!token}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="website" className="fw-bold">
                      Website
                    </Label>
                    <Input
                      type="url"
                      name="website"
                      id="website"
                      value={businessData.website}
                      onChange={handleInputChange}
                      placeholder="https://"
                      disabled={!token}
                    />
                  </FormGroup>
                </div>

                {/* Business Settings Section */}
                <div className="mb-4">
                  <h5 className="text-muted mb-3">Business Settings</h5>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="timeZone" className="fw-bold">
                          Time Zone
                        </Label>
                        <Input
                          type="select"
                          name="timeZone"
                          id="timeZone"
                          value={businessData.timeZone}
                          onChange={handleInputChange}
                          disabled={!token}
                        >
                          <option value="America/New_York">
                            America/New York
                          </option>
                          <option value="America/Chicago">
                            America/Chicago
                          </option>
                          <option value="America/Denver">America/Denver</option>
                          <option value="America/Los_Angeles">
                            America/Los Angeles
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="businessCurrency" className="fw-bold">
                          Business Currency
                        </Label>
                        <Input
                          type="text"
                          name="businessCurrency"
                          id="businessCurrency"
                          value={businessData.businessCurrency}
                          className="bg-light"
                          disabled={true}
                        />
                        <small className="text-muted d-block mt-1">
                          This is your reporting currency and cannot be changed.
                          You can still send invoices, track expenses and enter
                          transactions in any currency and an exchange rate is
                          applied for you.
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    color="secondary"
                    className="me-2"
                    type="button"
                    disabled={loading || !token}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={loading || !token}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BusinessDetails;
