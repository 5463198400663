import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Input from "../Input/Input";
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    CardBody
} from "reactstrap";
import { registerUserWithInvitation } from "../../lib/api/user";
import { hasDigit, hasSpecialCharacter, hasUppercase, isEmail, isEmpty, isLength, isMatch } from "../helper/validate";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  cf_password: "",
};

const RegisterWithInvite = () => {
  const { activation_token } = useParams();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const [isUS, setIsUS] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);
  const { first_name, last_name, email, phone_number, password, cf_password } = data;
  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  // const handleRecaptchaVerify = useCallback((token) => {
  //   setToken(token)
  // }, [])

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;

    if (!isUS) {
      toast("Please change the country back to US.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      isValid = false;
    }

    if (!isAgreed) {
      toast("Please agree to the terms and conditions.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      isValid = false;
    }

    return isValid;
  };

  const handlePhoneChange = (value) => {
    setData({ ...data, phone_number: value });
  };

  

  //Original Country Code Retriction
  const handleCountryChange = (countryCode) => {
    if (countryCode !== 'US' && countryCode !== 'AT') {
      toast("Our services are currently not available in this country.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
      setIsUS(false);
    } else {
      setIsUS(true);
    }
  };
  // Bypass Country Code
  // const handleCountryChange = (countryCode) => {
  //   setIsUS(true); 
  // };

  const handleClick = () => {
    setVisible(!visible);
  };

  const register = async (e) => {
    e.preventDefault();
  
    if (isEmpty(first_name))
      return toast("Please enter your first name.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (isEmpty(last_name))
      return toast("Please enter your last name.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (isEmpty(phone_number))
      return toast("Please enter your phone number.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (isEmpty(password))
      return toast("Please enter a password.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (isEmpty(cf_password))
      return toast("Please confirm your password.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (!isEmail(email))
      return toast("Please enter a valid email address.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (isLength(password))
      return toast("Password must be at least 12 characters.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (!isMatch(password, cf_password))
      return toast("Passwords do not match.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (!hasDigit(password))
      return toast("Please include a digit in the password (0 - 9).", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    if (hasSpecialCharacter(password))
      return toast(
        "Please include a special character in the password (! @ # $ % ^ & *).",
        {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        }
      );
    if (hasUppercase(password))
      return toast("Please include an upper case letter in your password.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
  
    if (!validateForm()) {
      return;
    }
  
    try {
      const userData = {
        first_name,
        last_name,
        email,
        phone_number,
        password,
      };
  
      const res = await registerUserWithInvitation(userData, activation_token);
  
      navigate("/account");
      toast.success(res.message, {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
    }
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, first_name: "", last_name: "", email: "", phone_number: "", password: "", cf_password: "" });
  };

  return (
    <>
      <ToastContainer />
      <div className="authInputArea">
        <h1>Register with Invitation</h1>
        <h5>Enter your account information</h5>
        <hr/>
        <Card>
          <CardBody className="custom-card-body">
            <form onSubmit={register}>
              <Input
                type="text"
                text="First Name"
                name="first_name"
                handleChange={handleChange}
              />
              <Input
                type="text"
                text="Last Name"
                name="last_name"
                handleChange={handleChange}
              />
              <Input
                type="text"
                text="Business Email"
                name="email"
                handleChange={handleChange}
              />
              <PhoneInput
                type="tel"
                placeholder="Phone Number"
                name="phone_number"
                value={data.phone_number}
                defaultCountry="US"
                onChange={handlePhoneChange}
                onCountryChange={handleCountryChange}
              />
              <Input
                name="password"
                type={visible ? "text" : "password"}
                icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                text="Password"
                handleClick={handleClick}
                handleChange={handleChange}
              />
              <Input
                name="cf_password"
                type={visible ? "text" : "password"}
                icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
                text="Confirm Password"
                handleClick={handleClick}
                handleChange={handleChange}
              />

              <div className="agreement_checkbox" style={{ width: '360px', display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={isAgreed}
                  onChange={() => setIsAgreed(!isAgreed)}
                  style={{ marginRight: '8px' }}
                />
                <label>
                  By checking this box, I agree to receive mobile messages on the phone number provided. Message frequency varies. Message and data rates may apply. 
                  View HelperFox's <a href="https://www.HelperFox.com/terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms and Conditions </a>
                  and
                  <a href="https://www.HelperFox.com/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                </label>
              </div>

              <div className="login_btn">
                <button type="submit">register</button>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
      {/* <GoogleReCaptcha onVerify={handleRecaptchaVerify} /> */}
    </>
  );
};

export default RegisterWithInvite;
