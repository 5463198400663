import { FormGroup, Input, Label } from "reactstrap";

const LogoCustomizer = ({ onLogoChange }) => {
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				onLogoChange(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<FormGroup>
			<Label for="logoUpload">Upload Logo</Label>
			<Input
				type="file"
				name="logo"
				id="logoUpload"
				accept="image/*"
				onChange={handleFileChange}
			/>
		</FormGroup>
	);
};

export default LogoCustomizer;
