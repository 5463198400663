import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import useSWR from "swr";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import ChartCard from "./ChartCard";

const COLORS = {
  Paid: "#22c55e", // Success green
  Viewed: "#3b82f6", // Info blue
  Sent: "#a855f7", // Purple
  Draft: "#64748b", // Slate gray
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload?.length) {
    return null;
  }

  const data = payload[0].payload;
  return (
    <div className="custom-tooltip bg-white p-3 shadow-lg rounded border">
      <p className="font-semibold mb-2" style={{ color: COLORS[data.name] }}>
        {data.name}
      </p>
      <p className="mb-1">Amount: {formatCurrency(data.amount)}</p>
      <p className="mb-0 text-gray-600">Percentage: {data.value}%</p>
      <p className="mb-0 text-gray-600">Count: {data.count || 0} invoices</p>
    </div>
  );
};

const InvoiceStatusChart = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/api/dashboard/charts/invoice-status",
    async (url) => {
      try {
        const response = await axiosWithAuth.get(url);
        return response.data;
      } catch (error) {
        throw error.response?.data || error.message;
      }
    },
    {
      refreshInterval: 5 * 60 * 1000, // 5 minutes
      revalidateOnFocus: false,
    }
  );

  return (
    <ChartCard
      title="Invoice Status"
      isLoading={isLoading}
      error={error}
      onRetry={() => mutate()}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data?.statuses || []}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="80%"
            paddingAngle={2}
            label={({ name, value }) => `${name} (${value}%)`}
            labelLine={{ stroke: "#4b5563", strokeWidth: 1 }}
            animationBegin={0}
            animationDuration={1500}
          >
            {(data?.statuses || []).map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.name]}
                stroke="#fff"
                strokeWidth={1}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="bottom"
            height={36}
            iconType="circle"
            formatter={(value) => (
              <span style={{ color: "#4b5563", fontSize: "0.875rem" }}>
                {value}
              </span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartCard>
  );
};

export default InvoiceStatusChart;
