// src/views/ui/tools/CSV.js
import React, { useState } from "react";
import { Button, Card, CardBody, Table, Input, FormGroup, Label } from "reactstrap";
import { saveAs } from "file-saver";


const CSV = () => {
  const [columns, setColumns] = useState(["First Name", "Last Name", "Phone Number"]);
  const [rows, setRows] = useState([["", "", ""]]);
  const [delimiter, setDelimiter] = useState(",");

  const handleAddColumn = () => {
    setColumns([...columns, `Column ${columns.length + 1}`]);
    setRows(rows.map((row) => [...row, ""]));
  };

  const handleAddRow = () => {
    setRows([...rows, Array(columns.length).fill("")]);
  };

  const handleColumnChange = (index, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = value;
    setColumns(updatedColumns);
  };

  const handleRowChange = (rowIndex, colIndex, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][colIndex] = value;
    setRows(updatedRows);
  };

  const handleDownload = () => {
    const csvContent = [
      columns.join(delimiter),
      ...rows.map((row) => row.join(delimiter)),
    ].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "sample.csv");
  };

  return (
    <div className="csv-generator-container">
      <Card>
          <CardBody>
            <div className="text-center mb-4">
              <h3>CSV Generator Tool</h3>
              <p>Easy to use CSV generator tool meant to help with CSV formatting.</p>
            </div>

            <div className="table-responsive">
              <Table bordered className="table-hover">
                <thead>
                  <tr style={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                    <th>#</th>
                    {columns.map((col, index) => (
                      <th key={index}>
                        <Input
                          type="text"
                          value={col}
                          onChange={(e) => handleColumnChange(index, e.target.value)}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{rowIndex + 1}</td>
                      {row.map((cell, colIndex) => (
                        <td key={colIndex}>
                          <Input
                            type="text"
                            value={cell}
                            onChange={(e) =>
                              handleRowChange(rowIndex, colIndex, e.target.value)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-3">
              <div>
                <Button color="secondary" onClick={handleAddColumn} className="me-2">
                  + Add Column
                </Button>
                <Button color="secondary" onClick={handleAddRow}>
                  + Add Row
                </Button>
              </div>
            </div>

            <div className="mt-4">
              <FormGroup>
                <Label for="delimiter" className="me-2">
                  Select Delimiter
                </Label>
                <Input
                  type="select"
                  id="delimiter"
                  value={delimiter}
                  onChange={(e) => setDelimiter(e.target.value)}
                >
                  <option value=",">Comma (,)</option>
                  <option value=";">Semicolon (;)</option>
                  <option value="|">Pipe (|)</option>
                </Input>
              </FormGroup>
            </div>

            <div className="mt-4">
              <Button color="success" onClick={handleDownload}>
                Generate CSV
              </Button>
            </div>
          </CardBody>
      </Card>
    </div>
  );
};

export default CSV;
