import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const qrCodeAtom = atom({
	currentData: null,
	name: "",
	style: "dots",
	color: "#000000",
	logo: null,
	frameName: "none",
});

export const savedQRCodesAtom = atomWithStorage("savedQRCodes", []);

export const currentQRCodeAtom = atomWithStorage("currentQRCode", null);

export const clearCurrentQRCodeAtom = atom(null, (get, set) => {
	set(currentQRCodeAtom, null);
});

export const deleteQRCodeAtom = atom(null, (get, set, id) => {
	const savedQRCodes = get(savedQRCodesAtom);
	set(
		savedQRCodesAtom,
		savedQRCodes.filter((qr) => qr.id !== id)
	);
});
