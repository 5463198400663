import { useEffect, useState } from 'react';
import "./authlayout.css";
import slide1 from './slide1.png';
import slide2 from './slide2.png';
import slide3 from './slide3.png';
import slide4 from './slide4.png';

const ImageSlider = () => {
  const images = [slide1, slide2, slide3, slide4];
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change images every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7500); // Change image every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="image-slider">
      <div className="image-container">
        <img 
          src={images[currentIndex]} 
          alt={`slide-${currentIndex}`} 
          className="slider-image" 
        />
      </div>
      {/* Navigator */}
      <div className="navigator">
        <span>{currentIndex + 1} / {images.length}</span>
      </div>
    </div>
  );
};

export default ImageSlider;
