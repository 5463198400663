import { useState } from "react";
import { Plus, Trash } from "react-bootstrap-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { useEstimate } from "../context/EstimateContext";

export function ServicesStep({ onNext, onPrevious }) {
  const { estimateData, updateEstimateData } = useEstimate();
  const [newService, setNewService] = useState({
    description: "",
    quantity: 1,
    rate: 0,
    amount: 0,
  });

  const handleAddService = () => {
    if (!newService.description || !newService.rate) return;

    const amount = newService.quantity * newService.rate;
    const service = {
      ...newService,
      amount,
      id: Date.now(), // temporary ID for frontend
    };

    updateEstimateData("services", [...estimateData.services, service]);
    setNewService({
      description: "",
      quantity: 1,
      rate: 0,
      amount: 0,
    });
  };

  const handleRemoveService = (serviceId) => {
    updateEstimateData(
      "services",
      estimateData.services.filter((s) => s.id !== serviceId)
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewService((prev) => {
      const updated = {
        ...prev,
        [name]: value,
      };
      if (name === "quantity" || name === "rate") {
        updated.amount = updated.quantity * updated.rate;
      }
      return updated;
    });
  };

  const calculateTotal = () => {
    return estimateData.services.reduce(
      (sum, service) => sum + service.amount,
      0
    );
  };

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1">Add Services</h5>
        <p className="text-muted mb-0">
          Add the services you'll provide in this estimate
        </p>
      </div>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              value={newService.description}
              onChange={handleInputChange}
              placeholder="Service description"
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="quantity">Quantity</Label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              value={newService.quantity}
              onChange={handleInputChange}
              min="1"
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="rate">Rate</Label>
            <Input
              type="number"
              id="rate"
              name="rate"
              value={newService.rate}
              onChange={handleInputChange}
              min="0"
              step="0.01"
            />
          </FormGroup>
        </Col>
        <Col md={2} className="d-flex align-items-end">
          <Button
            color="primary"
            className="w-100"
            onClick={handleAddService}
            disabled={!newService.description || !newService.rate}
          >
            <Plus size={18} className="me-2" />
            Add
          </Button>
        </Col>
      </Row>

      <Card className="mb-4">
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr>
                <th>Description</th>
                <th className="text-end">Quantity</th>
                <th className="text-end">Rate</th>
                <th className="text-end">Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {estimateData.services.map((service) => (
                <tr key={service.id}>
                  <td>{service.description}</td>
                  <td className="text-end">{service.quantity}</td>
                  <td className="text-end">
                    ${Number(service.rate).toFixed(2)}
                  </td>
                  <td className="text-end">
                    ${Number(service.amount).toFixed(2)}
                  </td>
                  <td className="text-end">
                    <Button
                      color="danger"
                      size="sm"
                      outline
                      onClick={() => handleRemoveService(service.id)}
                    >
                      <Trash size={14} />
                    </Button>
                  </td>
                </tr>
              ))}
              {estimateData.services.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center text-muted py-4">
                    No services added yet
                  </td>
                </tr>
              )}
            </tbody>
            {estimateData.services.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-end">
                    <strong>Total</strong>
                  </td>
                  <td className="text-end">
                    <strong>${calculateTotal().toFixed(2)}</strong>
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </Table>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <Button
          color="primary"
          onClick={onNext}
          disabled={estimateData.services.length === 0}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
}
