import { FileText, PlusCircle, Receipt } from "react-bootstrap-icons";
import {
  MdOutlineMessage,
  MdOutlineNumbers,
  MdOutlineSecurity,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import ExpensesCategoryChart from "../../components/dashboard/Charts/ExpensesCategoryChart";
import InvoiceStatusChart from "../../components/dashboard/Charts/InvoiceStatusChart";
import RevenueEstimatesChart from "../../components/dashboard/Charts/RevenueEstimatesChart";
import ExpensesCard from "../../components/dashboard/MetricsCards/ExpensesCard";
import PendingInvoicesCard from "../../components/dashboard/MetricsCards/PendingInvoicesCard";
import ProfitCard from "../../components/dashboard/MetricsCards/ProfitCard";
import RevenueCard from "../../components/dashboard/MetricsCards/RevenueCard";
import RecentTransactions from "../../components/dashboard/RecentTransactions";
import { useAuth } from "../../context/AuthContext";
import "./tools.css";

const Tools = ({ activityStatus }) => {
  const { token } = useAuth();

  if (!token) {
    return (
      <div className="dashboard-container">
        <Container fluid className="mt-4">
          <Card className="border-0 shadow-sm p-4 text-center">
            <h2>Please Log In</h2>
            <p className="mb-4">
              You need to be logged in to access the financial dashboard.
            </p>
            <div className="d-flex justify-content-center gap-3">
              <Link to="/auth">
                <Button color="primary">Log In</Button>
              </Link>
            </div>
          </Card>
        </Container>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="dashboard-container">
        <Container fluid className="mt-4">
          <div className="dashboard-content">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1>Dashboard</h1>

              {/* Quick Actions */}
              <div className="d-flex gap-2">
                <Link to="/invoices/new">
                  <Button
                    color="primary"
                    className="d-flex align-items-center gap-2"
                  >
                    <FileText size={18} />
                    New Invoice
                  </Button>
                </Link>
                <Link to="/expenses" style={{ textDecoration: "none" }}>
                  <Button
                    color="danger"
                    className="d-flex align-items-center gap-2"
                  >
                    <Receipt size={18} />
                    Add Expense
                  </Button>
                </Link>
                <Link to="/estimates/new" style={{ textDecoration: "none" }}>
                  <Button
                    color="success"
                    className="d-flex align-items-center gap-2"
                  >
                    <PlusCircle size={18} />
                    New Estimate
                  </Button>
                </Link>
              </div>
            </div>

            <div>
              {activityStatus === 1 ? (
                <>
                  {/* Metrics Cards */}
                  <Row className="mb-4">
                    <Col lg="3" md="6" className="mb-4">
                      <RevenueCard />
                    </Col>
                    <Col lg="3" md="6" className="mb-4">
                      <ExpensesCard />
                    </Col>
                    <Col lg="3" md="6" className="mb-4">
                      <ProfitCard />
                    </Col>
                    <Col lg="3" md="6" className="mb-4">
                      <PendingInvoicesCard />
                    </Col>
                  </Row>

                  {/* Charts Row 1 */}
                  <Row className="mb-4">
                    <Col lg="8">
                      <RevenueEstimatesChart />
                    </Col>
                    <Col lg="4">
                      <InvoiceStatusChart />
                    </Col>
                  </Row>

                  {/* Recent Transactions */}
                  <Row className="mb-4">
                    <Col>
                      <RecentTransactions />
                    </Col>
                  </Row>

                  {/* Charts Row 2 */}
                  <Row>
                    <Col lg="12">
                      <ExpensesCategoryChart />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardBody>
                          <CardTitle tag="h4">Getting Started</CardTitle>
                          <p>
                            Discover how easy getting started with HelperFox can
                            be 🚀
                          </p>
                          <hr />

                          <Row className="pt-5">
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                How to Add Your Business Details
                              </p>
                              <p>
                              Customize your business profile with logos, contact info, and tax details for professional invoices.{" "}
                                <Link to="/AddBusinessDetails">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                Importing Customers: A Step-by-Step Guide
                              </p>
                              <p>
                              Easily upload and manage your customer list to streamline invoicing and CRM tasks.{" "}
                                <Link to="/ImportCustomers">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                Setting Up Billing for Smooth Transactions
                              </p>
                              <p>
                              Configure payment methods, tax settings, and invoice terms to get paid faster.{" "}
                       
                                <Link to="/SetupBilling">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                          </Row>

                          <Row className="pt-5">
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                Invoice Best Practices: Get Paid on Time
                              </p>
                              <p>
                              Learn how to create professional invoices, set clear terms, and send reminders to ensure timely payments.{" "}
                      
                                <Link to="/InvoiceBestPractices">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                Keeping Track of Expenses Like a Pro
                              </p>
                              <p>
                              Categorize and log expenses effortlessly to maintain accurate financial records and optimize budgeting.
                              {" "}
                                <Link to="/TrackExpenses">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                            <Col md="4">
                              <p
                                style={{ fontWeight: "bold", fontSize: "2rem" }}
                              >
                                Generating Expense Reports
                              </p>
                              <p>
                              Create detailed expense reports to gain insights into your spending and stay financially organized.
                                <Link to="/GenerateExpenses">
                                  <span>→</span>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Card className="h-100">
                        <CardBody>
                          <CardTitle tag="h4">
                            Features & Restrictions
                          </CardTitle>
                          <hr />
                          <p>
                          Understanding best practices, restrictions, and registration requirements is essential for managing your account.
                          </p>
                          <div style={{ textAlign: "right" }}>
                            <Link to="/RestrictionsAndLimitations">
                              <Button color="primary" outline>
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md="6">
                      <Card className="h-100">
                        <CardBody>
                          <CardTitle tag="h4">Additional Resources</CardTitle>
                          <hr />
                          <p>
                            <MdOutlineNumbers
                              style={{
                                marginRight: "8px",
                                verticalAlign: "middle",
                              }}
                            />
                            <Link
                              to="/PhoneNumbers"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              Submit an expense
                            </Link>
                          </p>
                          <p>
                            <MdOutlineMessage
                              style={{
                                marginRight: "8px",
                                verticalAlign: "middle",
                              }}
                            />
                            <Link
                              to="/SendMessage"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              Send your first invoice
                            </Link>
                          </p>
                          <p>
                            <MdOutlineSecurity
                              style={{
                                marginRight: "8px",
                                verticalAlign: "middle",
                              }}
                            />
                            <Link
                              to="/PhoneNumbers"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              Begin registration process
                            </Link>
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Tools;
