import { useState } from "react";
import { Plus, Trash } from "react-bootstrap-icons";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { useInvoice } from "../context/InvoiceContext";

export function ServicesStep({ onNext, onPrevious }) {
  const { invoiceData, updateInvoiceData } = useInvoice();
  const [newService, setNewService] = useState({
    description: "",
    quantity: 1,
    rate: 0,
    amount: 0,
    taxable: true,
  });

  const handleAddService = () => {
    if (!newService.description || !newService.rate) return;

    const amount = newService.quantity * newService.rate;
    const service = {
      ...newService,
      amount,
      id: Date.now(), // temporary ID for frontend
    };

    updateInvoiceData("services", [...invoiceData.services, service]);
    setNewService({
      description: "",
      quantity: 1,
      rate: 0,
      amount: 0,
      taxable: true,
    });
  };

  const handleRemoveService = (serviceId) => {
    updateInvoiceData(
      "services",
      invoiceData.services.filter((s) => s.id !== serviceId)
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewService((prev) => {
      const updated = {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
      if (name === "quantity" || name === "rate") {
        updated.amount = updated.quantity * updated.rate;
      }
      return updated;
    });
  };

  const calculateSubtotal = () => {
    return invoiceData.services.reduce(
      (sum, service) => sum + service.amount,
      0
    );
  };

  const calculateTaxableAmount = () => {
    return invoiceData.services.reduce(
      (sum, service) => sum + (service.taxable ? service.amount : 0),
      0
    );
  };

  return (
    <Container fluid className="px-3">
      <div className="mb-4">
        <h5 className="mb-1">Add Services</h5>
        <p className="text-muted mb-0">
          Add the services you'll provide in this invoice
        </p>
      </div>

      <Row className="g-3">
        <Col xs={12} md={5}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              value={newService.description}
              onChange={handleInputChange}
              placeholder="Service description"
            />
          </FormGroup>
        </Col>
        <Col xs={6} md={2}>
          <FormGroup>
            <Label for="quantity">Quantity</Label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              value={newService.quantity}
              onChange={handleInputChange}
              min="1"
            />
          </FormGroup>
        </Col>
        <Col xs={6} md={2}>
          <FormGroup>
            <Label for="rate">Rate</Label>
            <Input
              type="number"
              id="rate"
              name="rate"
              value={newService.rate}
              onChange={handleInputChange}
              min="0"
              step="0.01"
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={3} className="d-flex align-items-end">
          <Button
            color="primary"
            className="w-100"
            onClick={handleAddService}
            disabled={!newService.description || !newService.rate}
          >
            <Plus size={18} className="me-2" />
            Add Service
          </Button>
        </Col>
      </Row>

      <Row className="mt-3 mb-4">
        <Col>
          <FormGroup check>
            <Input
              type="checkbox"
              id="taxable"
              name="taxable"
              checked={newService.taxable}
              onChange={handleInputChange}
            />
            <Label check for="taxable">
              Taxable
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <div className="table-responsive">
        <Card className="mb-4">
          <CardBody className="p-0">
            <Table hover className="mb-0">
              <thead className="bg-light">
                <tr>
                  <th className="py-3" style={{ width: "35%" }}>
                    Description
                  </th>
                  <th className="text-center py-3" style={{ width: "15%" }}>
                    Quantity
                  </th>
                  <th className="text-end py-3" style={{ width: "15%" }}>
                    Rate
                  </th>
                  <th className="text-end py-3" style={{ width: "15%" }}>
                    Amount
                  </th>
                  <th className="text-center py-3" style={{ width: "15%" }}>
                    Taxable
                  </th>
                  <th className="py-3" style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.services.map((service) => (
                  <tr key={service.id}>
                    <td className="py-3">{service.description}</td>
                    <td className="text-center py-3">{service.quantity}</td>
                    <td className="text-end py-3">
                      ${Number(service.rate).toFixed(2)}
                    </td>
                    <td className="text-end py-3">
                      ${Number(service.amount).toFixed(2)}
                    </td>
                    <td className="text-center py-3">
                      {service.taxable ? "Yes" : "No"}
                    </td>
                    <td className="text-end py-3">
                      <Button
                        color="link"
                        className="text-danger p-0"
                        onClick={() => handleRemoveService(service.id)}
                      >
                        <Trash size={18} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {invoiceData.services.length > 0 && (
                <tfoot className="bg-light">
                  <tr>
                    <td colSpan="3" className="text-end py-3">
                      <strong>Subtotal</strong>
                    </td>
                    <td className="text-end py-3">
                      <strong>${calculateSubtotal().toFixed(2)}</strong>
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-end py-3">
                      <strong>Taxable Amount</strong>
                    </td>
                    <td className="text-end py-3">
                      <strong>${calculateTaxableAmount().toFixed(2)}</strong>
                    </td>
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              )}
            </Table>
          </CardBody>
        </Card>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <Button
          color="primary"
          onClick={onNext}
          disabled={invoiceData.services.length === 0}
        >
          Next Step
        </Button>
      </div>
    </Container>
  );
}
