import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/scss/style.scss";
import { AuthContextProvider } from "./context/AuthContext";
import { PayModalProvider } from "./context/PayModalContext";
import { ProbindsContextProvider } from "./context/ProbindContext";

// Initialize Stripe with your publishable key
// const stripePromise = loadStripe("pk_live_51HJ3DvEMcupfyeZwZ1nxRhMc4azLlmyiwisSaDr1JdBc6iWAMsCiK41fwx7AESo2i99JQsxHH5zqxcb9H6syYVM10079YukyTJ");
const stripePromise = loadStripe("pk_test_51HJ3DvEMcupfyeZw5w4CIyY1Exe5XTfWqu8k8redFxJxvDGmXnK29IrHzPcQ30l7Lu3fSPndFMBIIRA5K9gWKxDm00RfhY6TKl");

ReactDOM.render(
  <AuthContextProvider>
    <ProbindsContextProvider>
      <PayModalProvider>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </PayModalProvider>
    </ProbindsContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
