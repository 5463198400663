import { useState } from "react";
import { Tools } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import Forgot from "../../components/Forgot/Forgot";
import Login from "../../components/Login/Login";
import Register from "../../components/Register/Register";
import "./authlayout.css";
import NavLogo from "./helperfox-logo.png";
import ImageSlider from "./ImageSlider";

const AuthLayout = () => {
  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);
  const [forgot, setForgot] = useState(false);

  const handleLogin = () => {
    setLogin(true);
    setRegister(false);
    setForgot(false);
  };
  const handleRegister = () => {
    setLogin(false);
    setRegister(true);
    setForgot(false);
  };
  const handleForgot = () => {
    setLogin(false);
    setRegister(false);
    setForgot(true);
  };

  return (
    <>
      <div className="authlayout_navbar">
        <div className="authlayout_logo">
          <img src={NavLogo} alt="logo" />
        </div>
        <div className="authlayout_link">
          <a href="https://helperfox.com">Back to Main Site</a>
        </div>
      </div>
      <div className="authlayout_content">
        <div className="authInputArea">
          {/* logo */}
          <Card>
            <CardBody className="custom-card-body">
              {/* form */}
              {login && <Login />}
              {register && <Register />}
              {forgot && <Forgot />}
              {/* actions */}
              <div className="authlayout_card-actions">
                <p onClick={login ? handleRegister : handleLogin}>
                  {login ? "Sign Up Here" : "Login"}
                </p>
                <p onClick={handleForgot}>Forgot Password?</p>
              </div>

              {/* Free Tools Section */}
              <div className="free-tools-section">
                <div className="divider">
                  <span>or</span>
                </div>
                <Link to="/ft/tools" className="free-tools-link">
                  <Tools className="tools-icon" />
                  <div>
                    <h4>Try Our Free Tools</h4>
                    <p>
                      No sign up required - CSV Generator, QR Codes, and more
                    </p>
                  </div>
                </Link>
              </div>

              <div className="authlayout_card-bottom">
                <h5>Need help?</h5>
                <a href="https://HelperFox.com/contact">Contact Us</a>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="authMarketing">
          {/* Conditional Marketing Messages */}
          {login && (
            <>
              <div style={{ minHeight: "600px" }}>
                <h2>Welcome Back to HelperFox!</h2>
                <h4>
                  Log in to easily manage invoices, estimates, expenses, and
                  customers.
                </h4>
                <ImageSlider />
                <ul>
                  <li>Generate and send professional invoices in seconds.</li>
                  <li>Create and track estimates with ease.</li>
                  <li>Monitor your business expenses effortlessly.</li>
                  <li>Manage customer relationships in one place.</li>
                </ul>
                <a href="https://HelperFox.com">Learn More</a>
              </div>
            </>
          )}

          {register && (
            <>
              <h2>Get Started with HelperFox</h2>
              <h4>
                Simplify your invoicing, estimates, and expense tracking in one
                platform.
              </h4>
              <ImageSlider />
              <ul>
                <li>Create and customize invoices in minutes.</li>
                <li>Send and manage estimates professionally.</li>
                <li>Keep track of all your business expenses.</li>
                <li>
                  Organize and nurture customer relationships with our CRM
                  tools.
                </li>
              </ul>
              <a href="https://HelperFox.com">Learn More</a>
            </>
          )}

          {forgot && (
            <>
              <h2>Forgot Your Password?</h2>
              <h4>Don't worry, let's get you back on track.</h4>
              <ul>
                <li>Follow the simple steps to reset your password.</li>
                <li>
                  Regain access to your invoices, estimates, and customer data.
                </li>
                <li>Stay on top of your business finances effortlessly.</li>
                <li>Need help? Our support team is here for you.</li>
              </ul>
              <a href="https://HelperFox.com">Learn More</a>
            </>
          )}
        </div>
      </div>
      <div className="authlayout_footer">
        <p>Copyright © 2025 - HelperFox - All Rights Reserved.</p>
      </div>
    </>
  );
};

export default AuthLayout;
