import { format } from "date-fns";
import { useState } from "react";
import {
  Building,
  Calendar,
  Eye,
  FileText,
  Plus,
  Search,
  Send,
  Trash2,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import useSWR from "swr";
import { invoiceApi } from "../../../../api/invoiceApi";
import { useAuth } from "../../../../context/AuthContext";

export function InvoiceList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { token } = useAuth();
  const navigate = useNavigate();

  const {
    data: invoices,
    error,
    mutate,
  } = useSWR("invoices", () => invoiceApi.getAllInvoices(token), {
    revalidateOnFocus: false,
    refreshInterval: 30000,
  });

  const handleDelete = async (invoiceId) => {
    if (!window.confirm("Are you sure you want to delete this invoice?")) {
      return;
    }

    try {
      await invoiceApi.deleteInvoice(token, invoiceId);
      toast.success("Invoice deleted successfully");
      mutate();
    } catch (err) {
      toast.error("Failed to delete invoice");
    }
  };

  const handleSendReminder = async (invoiceId) => {
    try {
      await invoiceApi.sendNotification(token, invoiceId);
      toast.success("Reminder sent successfully");
      mutate();
    } catch (err) {
      toast.error("Failed to send reminder");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Draft":
        return "warning";
      case "Sent":
        return "primary";
      case "Viewed":
        return "info";
      case "Paid":
        return "success";
      case "Overdue":
        return "danger";
      default:
        return "secondary";
    }
  };

  const filteredInvoices = (invoices || []).filter((invoice) => {
    const matchesSearch =
      invoice.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus =
      statusFilter === "all" || invoice.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  if (!invoices && !error) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <Spinner className="me-2" />
        <span>Loading invoices...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error.message}
      </div>
    );
  }

  return (
    <div className="container-fluid py-4">
      <Card className="shadow-sm ">
        <CardBody>
          <Row className="align-items-center mb-4">
            <Col>
              <div className="d-flex align-items-center">
                <div
                  className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                  style={{ width: "48px", height: "48px" }}
                >
                  <FileText size={24} className="text-primary" />
                </div>
                <div>
                  <h4 className="mb-1">Invoices</h4>
                  <div className="text-muted">
                    Manage and track your invoices
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="auto">
              <Button color="primary" onClick={() => navigate("/invoices/new")}>
                <Plus size={18} className="me-2" />
                New Invoice
              </Button>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={4}>
              <InputGroup>
                <InputGroupText
                  style={{
                    backgroundColor: "#f8f9fa",
                    height: "43.5px",
                    padding: "0 12px",
                  }}
                  className="bg-white border-end-0"
                >
                  <Search size={16} className="text-muted" />
                </InputGroupText>
                <Input
                  placeholder="Search invoices..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border-start-0 ps-0"
                />
              </InputGroup>
            </Col>
            <Col md={8} className="d-flex justify-content-end gap-2">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <DropdownToggle caret color="light">
                  Status: {statusFilter === "all" ? "All" : statusFilter}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setStatusFilter("all")}>
                    All
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Draft")}>
                    Draft
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Sent")}>
                    Sent
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Viewed")}>
                    Viewed
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Paid")}>
                    Paid
                  </DropdownItem>
                  <DropdownItem onClick={() => setStatusFilter("Overdue")}>
                    Overdue
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          <div className="table-responsive">
            <Table hover className="mb-0 align-middle custom-table">
              <thead className="bg-light">
                <tr>
                  <th className="">Invoice</th>
                  <th className="">Customer</th>
                  <th className="">Date</th>
                  <th className="">Due Date</th>
                  <th className=" text-end">Amount</th>
                  <th className="">Status</th>
                  <th className="" style={{ width: "150px" }}></th>
                </tr>
              </thead>
              <tbody>
                {filteredInvoices.map((invoice) => (
                  <tr key={invoice._id}>
                    <td className="">
                      <div className="fw-medium">{invoice.invoiceNumber}</div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center">
                        <div
                          className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                          style={{ width: "32px", height: "32px" }}
                        >
                          <Building size={16} className="text-primary" />
                        </div>
                        <div>
                          <div className="fw-medium">{invoice.clientName}</div>
                          <small className="text-muted">{invoice.email}</small>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center text-muted">
                        <Calendar size={14} className="me-2" />
                        {format(new Date(invoice.invoiceDate), "MMM d, yyyy")}
                      </div>
                    </td>
                    <td className="">
                      <div className="d-flex align-items-center text-muted">
                        <Calendar size={14} className="me-2" />
                        {format(new Date(invoice.dueDate), "MMM d, yyyy")}
                      </div>
                    </td>
                    <td className=" text-end fw-medium">
                      ${invoice.total.toFixed(2)}
                    </td>
                    <td className="">
                      <Badge color={getStatusColor(invoice.status)}>
                        {invoice.status}
                      </Badge>
                    </td>
                    <td className="">
                      <div className="d-flex gap-2 justify-content-end">
                        <Button
                          color="light"
                          size="sm"
                          className="btn-icon"
                          onClick={() =>
                            navigate(`/invoices/${invoice.invoiceID}`)
                          }
                          title="View"
                        >
                          <Eye size={14} />
                        </Button>
                        {invoice.status === "Sent" && (
                          <Button
                            color="light"
                            size="sm"
                            className="btn-icon"
                            onClick={() =>
                              handleSendReminder(invoice.invoiceID)
                            }
                            title="Send Reminder"
                          >
                            <Send size={14} />
                          </Button>
                        )}
                        <Button
                          color="light"
                          size="sm"
                          className="btn-icon"
                          onClick={() => handleDelete(invoice.invoiceID)}
                          title="Delete"
                        >
                          <Trash2 size={14} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredInvoices.length === 0 && (
                  <tr>
                    <td colSpan="7" className="text-center py-5 text-muted ">
                      <FileText size={24} className="mb-2" />
                      <div>No invoices found</div>
                      <small>
                        {searchTerm || statusFilter !== "all"
                          ? "Try adjusting your filters"
                          : "Create your first invoice to get started"}
                      </small>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
