import axios from "axios";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, NavItem } from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { AuthContext } from "../../context/AuthContext";
import { useAuthContext } from "../../hooks/useAuthContext";

const Sidebar = () => {
  const { dispatch } = useContext(AuthContext);
  const [expandedMenus, setExpandedMenus] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthContext();

  const navigation = [
    {
      title: "Dashboard",
      href: "/",
      icon: "bi bi-speedometer2",
      subMenu: [
        {
          title: "Business Details",
          href: "/business-details",
        },
      ],
    },
    {
      title: "Estimates",
      href: "/estimates",
      icon: "bi bi-receipt",
    },
    {
      title: "Invoices ",
      href: "/invoices",
      icon: "bi bi-receipt",
    },
    {
      title: "Expenses",
      href: "/expenses",
      icon: "bi bi-wallet2",
    },
    {
      title: "Customers",
      href: "/customers",
      icon: "bi bi-people",
    },
    {
      title: "My Account",
      href: "/account",
      icon: "bi bi-person",
    },
    !user?.permissions?.includes("2") && {
      title: "Billing",
      href: "/billing",
      icon: "bi bi-credit-card",
    },
    !user?.permissions?.includes("4") && {
      title: "Tools",
      icon: "bi bi-envelope",
      subMenu: [
        {
          title: "QR Generator",
          href: "/qr",
        },
        {
          title: "ShortLink",
          href: "/shortlink",
        },
        {
          title: "CSV Generator",
          href: "/csv",
        },
      ].filter(Boolean),
    },
    {
      title: "Knowledge Base",
      href: "/knowledgebase",
      icon: "bi bi-book",
    },
    {
      title: "Support",
      href: "/support",
      icon: "bi bi-question",
    },
  ].filter(Boolean);

  const toggleSidebar = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.get(`${API_BASE_URL}/api/auth/signout`);
      localStorage.removeItem("_appSignging");
      localStorage.removeItem("_apprftoken");
      dispatch({ type: "SIGNOUT" });
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = () => {
    navigate("/auth");
  };

  const toggleDropdown = (title) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  return (
    <div className="bg-dark">
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none toggle-button"
          onClick={toggleSidebar}
        >
          Close
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navi.href || "#"}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link py-3"
                }
                onClick={navi.subMenu ? () => toggleDropdown(navi.title) : null}
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
                {navi.subMenu && (
                  <i
                    className={`bi bi-chevron-${
                      expandedMenus[navi.title] ? "up" : "down"
                    } ms-auto`}
                    style={{ float: "right" }}
                  ></i>
                )}
              </Link>
              {navi.subMenu && expandedMenus[navi.title] && (
                <Nav vertical className="pl-3">
                  {navi.subMenu.map((subItem, subIndex) => (
                    <NavItem
                      key={subIndex}
                      className="sidenav-bg"
                      style={{ paddingLeft: "45px" }}
                    >
                      <Link
                        to={subItem.href}
                        className={
                          location.pathname === subItem.href
                            ? "active nav-link py-2"
                            : "nav-link py-2"
                        }
                      >
                        {subItem.title}
                      </Link>
                    </NavItem>
                  ))}
                </Nav>
              )}
            </NavItem>
          ))}
          <hr style={{ color: "grey" }} />
          {isAuthenticated ? (
            <Button color="secondary" onClick={handleLogout}>
              Log Out
            </Button>
          ) : (
            <Button color="secondary" onClick={handleLogin}>
              Log In
            </Button>
          )}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
