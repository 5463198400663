import { FaEllipsisV } from "react-icons/fa";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

const CustomerTable = ({
  customers,
  selectedCustomers,
  onSelectAll,
  onSelectOne,
  onEdit,
  onDelete,
  getStatusColor,
}) => {
  return (
    <div className="table-container">
      <Table responsive hover className="border bg-white custom-table">
        <thead>
          <tr>
            <th>
              <Input
                type="checkbox"
                onChange={onSelectAll}
                checked={
                  customers.length > 0 &&
                  customers.every((customer) =>
                    selectedCustomers.includes(customer._id)
                  )
                }
              />
            </th>
            <th className="fw-bold">Phone Number</th>
            <th className="fw-bold">First Name</th>
            <th className="fw-bold">Last Name</th>
            <th className="fw-bold">Company</th>
            <th className="fw-bold">Status</th>
            <th className="fw-bold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer._id} className="customer-row">
              <td>
                <Input
                  type="checkbox"
                  onChange={() => onSelectOne(customer._id)}
                  checked={selectedCustomers.includes(customer._id)}
                />
              </td>
              <td>{customer.phoneNumber}</td>
              <td className="text-capitalize text-3">{customer.firstName}</td>
              <td className="text-capitalize text-3">{customer.lastName}</td>
              <td>{customer.company}</td>
          
              <td>
                <span className={`badge bg-${getStatusColor(customer.status)}`}>
                  {customer.status}
                </span>
              </td>
              <td>
                <UncontrolledDropdown>
                  <DropdownToggle nav className="p-0">
                    <FaEllipsisV size={16} />
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={() => onEdit(customer)}>
                      Edit
                    </DropdownItem>
                    <DropdownItem onClick={() => onDelete(customer._id)}>
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomerTable;
