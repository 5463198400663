import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { useAuthContext } from "../../hooks/useAuthContext";




const Support = () => {
  const { user } = useAuthContext();
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [modal, setModal] = useState(false);
console.log("user ", user)
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (user && user.email) {
      fetchTickets();
    }
  }, [user]); // Ensure fetchTickets runs only when user is available

  const fetchTickets = async () => {
    if (!user || !user.email) {
      console.error("User email is missing, delaying fetch...");
      return;
    }

    try {
      console.log("Fetching tickets for:", user.email);
      const response = await axios.get(`${API_BASE_URL}/api/auth/getTicketlist`, {
        params: { userEmail: user.email },
      });
      console.log("Fetched Tickets:", response.data);
      setTickets(response.data);
    } catch (error) {
      console.error("Error fetching tickets:", error.response ? error.response.data : error);
    }
  };


  const generateTicketNumber = () => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    return `${date}${randomNum}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTicketNumber = generateTicketNumber();
    setTicketNumber(newTicketNumber);
  
    const ticketData = {
      to: "accounts@helperfox.com",
      from: user.email,
      subject: `HELPERFOX USER TICKET: ${user.email} - Ticket Number: ${newTicketNumber}`,
      description,
      ticketNumber: newTicketNumber,
    };
  
    try {
      await axios.post(`${API_BASE_URL}/api/auth/send-request`, ticketData);
      setSubject("");
      setDescription("");
      toggleModal();
      fetchTickets(); // Refresh tickets after creation
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  

  const loadTicketDetails = async (ticketNum) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/auth/getTicket/${ticketNum}`);
      const ticket = response.data;
      setTicketNumber(ticket.ticketNumber);
      setSubject(ticket.subject);
      setDescription(ticket.emailContent);
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    }
  };


  return (
    <>
     
    <Row>
    <Col md=""> 
       <Alert color="success">
        <h5 className="alert-heading">Before opening a ticket, consider checking out the <Link to="/knowledgebase">Knowledge Base</Link> articles.</h5>
    </Alert>     

    <Card style={{ height: "550px" }}>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <h3>Create Ticket</h3>
              <FormGroup>
                <Label>Subject</Label>
                <Input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Problem</Label>
                <Input type="select">
                  <option>Please choose one</option>
                  <option>Technical</option>
                  <option>Billing</option>
                  <option>Account Related</option>
                  <option>Order Short Code</option>
                  <option>Something Else</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>File</Label>
                <Input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
              </FormGroup>
              <Button type="submit">Submit</Button>
            </Form>
          </CardBody>
        </Card>


    </Col> 


    <Col md="4">
          <Card style={{ height: '215px' }}>
            <CardBody className="custom-card-body">
              <h4>Support Contact Info</h4>
              <hr />
              <div>
                <strong>PHONE NUMBER </strong>: 1 (617) 314-6667 Press *
              </div>
              <div>
                <strong>EMAIL </strong>: contact@helperfox.com 
              </div> 
             <br/>
              <div>
                <strong>SUPPORT HOURS </strong>: <br/>Monday - Friday 9:00am - 6:00pm EST
              </div>
            </CardBody>
          </Card>

          <Card style={{ height: '383px' }}>
            <CardBody className="custom-card-body">
              {/* <h4>Ticket History</h4>
              <hr /> */}
                <Table>
                  <thead>
                    <tr>
                      <th>Ticket Number</th>
                      <th>Date Opened</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length > 0 ? (
                      tickets.map(ticket => (
                        <tr
                            key={ticket.ticketNumber}
                            onClick={() => loadTicketDetails(ticket.ticketNumber)}
                            style={{ cursor: "pointer", backgroundColor: ticketNumber === ticket.ticketNumber ? "#f0f0f0" : "white" }}
                          >
                          <td>{ticket.ticketNumber}</td>
                          <td>{new Date(ticket.timestamp).toLocaleDateString()}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No tickets found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
            </CardBody>
          </Card>
        </Col>
        
        
    </Row>
    </>
  );
};

export default Support;

