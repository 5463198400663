import { Link } from "react-router-dom";
import HelperFoxLogo from './helperfox-logo.png';

const Logo = () => {
  return (
    <Link to="/">
      <img 
      src={HelperFoxLogo} 
      style={{ width: '206px', height: '37px', marginLeft: '15PX' }}
      alt="Logo" />
    </Link>
  );
};

export default Logo;
