import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const expensesAtom = atomWithStorage("expenses", []);

export const addExpenseAtom = atom(null, (get, set, newExpense) => {
	const expenses = get(expensesAtom);
	set(expensesAtom, [...expenses, { ...newExpense, id: Date.now() }]);
});

export const updateExpenseAtom = atom(null, (get, set, updatedExpense) => {
	const expenses = get(expensesAtom);
	set(
		expensesAtom,
		expenses.map((exp) =>
			exp.id === updatedExpense.id ? updatedExpense : exp
		)
	);
});
