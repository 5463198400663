import { format } from "date-fns";
import { useState } from "react";
import {
  Building,
  Calendar,
  Envelope,
  FileText,
  Person,
  Phone,
  Send,
  StickyFill,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useAuth } from "../../../../context/AuthContext";
import { createEstimate, sendEstimate } from "../../../../lib/api/estimate";
import { useEstimate } from "../context/EstimateContext";

export function ReviewStep({ onPrevious }) {
  const { estimateData } = useEstimate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { token } = useAuth();
  const navigate = useNavigate();

  const calculateTotal = () => {
    return estimateData.services.reduce(
      (sum, service) => sum + Number(service.amount),
      0
    );
  };

  const handleSendEstimate = async () => {
    if (!token) {
      toast.error("Please log in to send estimates");
      return;
    }

    setIsSending(true);
    try {
      await sendEstimate({
        customer: estimateData.customer,
        services: estimateData.services,
        details: estimateData.details,
        total: calculateTotal(),
        status: "Sent",
      });

      toast.success("Estimate sent successfully!");
      setIsModalOpen(false);
      navigate("/estimates");
    } catch (error) {
      console.error("Error sending estimate:", error);
      toast.error("Failed to send estimate");
    } finally {
      setIsSending(false);
    }
  };

  const handleSaveDraft = async () => {
    if (!token) {
      toast.error("Please log in to save estimates");
      return;
    }

    try {
      await createEstimate({
        customer: estimateData.customer,
        services: estimateData.services,
        ...estimateData.details,
        total: calculateTotal(),
        status: "Draft",
      });

      toast.success("Estimate saved as draft!");
      navigate("/estimates");
    } catch (error) {
      console.error("Error saving estimate:", error);
      toast.error("Failed to save estimate");
    }
  };

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1">Review Estimate</h5>
        <p className="text-muted mb-0">
          Review all details before sending the estimate
        </p>
      </div>

      <Row className="g-4">
        <Col md={6}>
          <Card className="shadow-sm border-0 h-100">
            <CardBody>
              <div className="d-flex align-items-center mb-4">
                <div
                  className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                  style={{ width: "32px", height: "32px" }}
                >
                  <Person size={16} className="text-primary" />
                </div>
                <h6 className="mb-0">Customer Information</h6>
              </div>
              <div className="d-flex flex-column gap-3">
                <div>
                  <div className="fw-medium mb-1">
                    {estimateData.customer?.firstName}{" "}
                    {estimateData.customer?.lastName}
                  </div>
                  {estimateData.customer?.company && (
                    <div className="d-flex align-items-center text-muted mb-1">
                      <Building size={14} className="me-2" />
                      {estimateData.customer?.company}
                    </div>
                  )}
                  <div className="d-flex align-items-center text-muted">
                    <Envelope size={14} className="me-2" />
                    {estimateData.customer?.email}
                  </div>
                  {estimateData.customer?.phone && (
                    <div className="d-flex align-items-center text-muted">
                      <Phone size={14} className="me-2" />
                      {estimateData.customer?.phone}
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="shadow-sm border-0 h-100">
            <CardBody>
              <div className="d-flex align-items-center mb-4">
                <div
                  className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                  style={{ width: "32px", height: "32px" }}
                >
                  <FileText size={16} className="text-primary" />
                </div>
                <h6 className="mb-0">Estimate Details</h6>
              </div>
              <div className="d-flex flex-column gap-3">
                <div>
                  <small className="text-muted d-block">Estimate Number</small>
                  <div>{estimateData.details.estimateNumber}</div>
                </div>
                <div>
                  <small className="text-muted d-block">Date</small>
                  <div className="d-flex align-items-center">
                    <Calendar size={14} className="me-2" />
                    {format(
                      new Date(estimateData.details.date),
                      "MMMM d, yyyy"
                    )}
                  </div>
                </div>
                <div>
                  <small className="text-muted d-block">Valid Until</small>
                  <div className="d-flex align-items-center">
                    <Calendar size={14} className="me-2" />
                    {format(
                      new Date(estimateData.details.expiryDate),
                      "MMMM d, yyyy"
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card className="shadow-sm border-0 my-4">
        <CardBody className="p-0">
          <div className="table-responsive">
            <Table hover className="mb-0 align-middle">
              <thead className="bg-light">
                <tr>
                  <th className="border-0">Description</th>
                  <th className="border-0 text-end" style={{ width: "120px" }}>
                    Quantity
                  </th>
                  <th className="border-0 text-end" style={{ width: "120px" }}>
                    Rate
                  </th>
                  <th className="border-0 text-end" style={{ width: "120px" }}>
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {estimateData.services.map((service) => (
                  <tr key={service.id}>
                    <td className="border-0">{service.description}</td>
                    <td className="border-0 text-end">{service.quantity}</td>
                    <td className="border-0 text-end">
                      ${Number(service.rate).toFixed(2)}
                    </td>
                    <td className="border-0 text-end">
                      ${Number(service.amount).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="bg-light">
                  <td colSpan="3" className="border-0 text-end">
                    <strong>Total</strong>
                  </td>
                  <td className="border-0 text-end">
                    <strong>${calculateTotal().toFixed(2)}</strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </CardBody>
      </Card>

      {(estimateData.details.notes || estimateData.details.terms) && (
        <Row className="g-4 mb-4">
          {estimateData.details.notes && (
            <Col md={6}>
              <Card className="shadow-sm border-0 h-100">
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div
                      className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                      style={{ width: "32px", height: "32px" }}
                    >
                      <StickyFill size={16} className="text-primary" />
                    </div>
                    <h6 className="mb-0">Notes</h6>
                  </div>
                  <div className="text-muted">{estimateData.details.notes}</div>
                </CardBody>
              </Card>
            </Col>
          )}

          {estimateData.details.terms && (
            <Col md={estimateData.details.notes ? 6 : 12}>
              <Card className="shadow-sm border-0 h-100">
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div
                      className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                      style={{ width: "32px", height: "32px" }}
                    >
                      <FileText size={16} className="text-primary" />
                    </div>
                    <h6 className="mb-0">Terms and Conditions</h6>
                  </div>
                  <div className="text-muted">{estimateData.details.terms}</div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}

      <div className="d-flex justify-content-between">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <div className="d-flex gap-2">
          <Button
            color="secondary"
            disabled={estimateData.customer === null}
            outline
            onClick={handleSaveDraft}
          >
            Save as Draft
          </Button>
          <Button
            color="primary"
            disabled={estimateData.customer === null}
            onClick={() => setIsModalOpen(true)}
          >
            Send Estimate
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        size="md"
      >
        <ModalHeader
          toggle={() => setIsModalOpen(false)}
          className="border-0 pb-0"
        >
          Send Estimate
        </ModalHeader>
        <ModalBody className="pt-0">
          <Alert color="info" className="mb-4">
            <div className="d-flex">
              <div
                className="rounded-circle bg-info bg-opacity-10 d-flex align-items-center justify-content-center me-3 flex-shrink-0"
                style={{ width: "48px", height: "48px" }}
              >
                <Send size={20} className="text-info" />
              </div>
              <div>
                <div className="fw-medium mb-1">Confirm Sending</div>
                <div>
                  The estimate will be sent to{" "}
                  <strong>{estimateData.customer?.email}</strong>. Make sure all
                  the details are correct before proceeding.
                </div>
              </div>
            </div>
          </Alert>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
          <Button
            color="secondary"
            outline
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSendEstimate}
            disabled={isSending}
          >
            {isSending ? (
              <>
                <Spinner size="sm" className="me-2" /> Sending...
              </>
            ) : (
              <>
                <Send size={14} className="me-2" /> Send Estimate
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
