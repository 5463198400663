import {
	FaAddressCard,
	FaAlignLeft,
	FaAppStore,
	FaBitcoin,
	FaCommentAlt,
	FaEnvelope,
	FaFacebookF,
	FaFilePdf,
	FaLink,
	FaMusic,
	FaTwitter,
	FaWifi,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

const navigationItems = [
	{ path: "url", icon: FaLink, label: "URL" },
	{ path: "vcard", icon: FaAddressCard, label: "VCARD" },
	{ path: "text", icon: FaAlignLeft, label: "TEXT" },
	{ path: "email", icon: FaEnvelope, label: "E-MAIL" },
	{ path: "sms", icon: FaCommentAlt, label: "SMS" },
	{ path: "wifi", icon: FaWifi, label: "WIFI" },
	{ path: "bitcoin", icon: FaBitcoin, label: "BITCOIN" },
	{ path: "twitter", icon: FaTwitter, label: "TWITTER" },
	{ path: "social?platform=facebook", icon: FaFacebookF, label: "FACEBOOK" },
	{ path: "pdf", icon: FaFilePdf, label: "PDF" },
	{ path: "", icon: FaMusic, label: "MP3" },
	{ path: "", icon: FaAppStore, label: "APP STORES" },
];

const NavigationGrid = () => {
	const location = useLocation();
	const currentPath = location.pathname.split("/").pop();

	return (
		<div className="navigation-grid mb-4">
			<Row className="g-2">
				{navigationItems.map((item) => (
					<Col key={item.label} xs={6} sm={4} md={3} lg={2}>
						<Link
							to={item.path}
							className={`nav-item-link ${
								currentPath === item.path ? "active" : ""
							}`}
						>
							<div className="text-center p-3">
								<item.icon className="fs-4 mb-2" />
								<div className="small">{item.label}</div>
							</div>
						</Link>
					</Col>
				))}
			</Row>
		</div>
	);
};

export default NavigationGrid;
