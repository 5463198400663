import { Card } from "reactstrap";
import "./MetricCard.css";

const MetricCardSkeleton = () => (
  <div className="metric-card-skeleton">
    <div className="skeleton-title"></div>
    <div className="skeleton-value"></div>
  </div>
);

const MetricCardError = ({ onRetry }) => (
  <div className="metric-card-error">
    <p>Failed to load data</p>
    <button onClick={onRetry} className="btn btn-sm btn-outline-primary">
      Retry
    </button>
  </div>
);

const MetricCard = ({
  title,
  value,
  icon: Icon,
  color = "primary",
  isLoading,
  error,
  onRetry,
}) => {
  if (isLoading) {
    return (
      <Card className="metric-card h-100 border-0 shadow-sm">
        <div className="card-body">
          <MetricCardSkeleton />
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="metric-card h-100 border-0 shadow-sm">
        <div className="card-body">
          <MetricCardError onRetry={onRetry} />
        </div>
      </Card>
    );
  }

  return (
    <Card className="metric-card h-100 border-0 shadow-sm">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="text-muted mb-2">{title}</h6>
            <h3 className="mb-0">{value}</h3>
          </div>
          <div className={`metric-icon text-${color}`}>
            <Icon size={40} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MetricCard;
