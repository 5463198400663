import { Wallet } from "react-bootstrap-icons";
import useSWR from "swr";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import MetricCard from "./MetricCard";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const PendingInvoicesCard = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/api/dashboard/metrics/pending-invoices",
    async (url) => {
      try {
        const response = await axiosWithAuth.get(url);
        return response.data;
      } catch (error) {
        throw error.response?.data || error.message;
      }
    },
    {
      refreshInterval: 5 * 60 * 1000, // 5 minutes
      revalidateOnFocus: false,
    }
  );

  return (
    <MetricCard
      title="Pending Invoices"
      value={data ? formatCurrency(data.pendingAmount) : "$0"}
      icon={Wallet}
      color="warning"
      isLoading={isLoading}
      error={error}
      onRetry={() => mutate()}
    />
  );
};

export default PendingInvoicesCard;
