import { Col, Input, Row } from "reactstrap";

const CustomerSearch = ({ searchCriteria, onSearchChange }) => {
  return (
    <div className="mb-4">
      <Row>
        <Col>
          <Input
            type="text"
            name="phoneNumber"
            placeholder="Search by Phone Number"
            value={searchCriteria.phoneNumber}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Col>
        <Col>
          <Input
            type="text"
            name="firstName"
            placeholder="Search by First Name"
            value={searchCriteria.firstName}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Col>
        <Col>
          <Input
            type="text"
            name="lastName"
            placeholder="Search by Last Name"
            value={searchCriteria.lastName}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Col>
        <Col>
          <Input
            type="text"
            name="company"
            placeholder="Search by Company"
            value={searchCriteria.company}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Col>
        
        <Col>
          <Input
            type="text"
            name="status"
            placeholder="Search by Status"
            value={searchCriteria.status}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomerSearch;
