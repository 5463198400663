import { useAtom } from "jotai";
import { useCallback, useMemo, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  rowsPerPageAtom,
  selectedCustomersAtom,
} from "../../atoms/customerAtoms";
import CustomerSearch from "../../components/CustomerSearch";
import CustomerTable from "../../components/CustomerTable";
import CustomerModal from "../../components/Modals/CustomerModal";
import { useCustomers } from "../../hooks/useCustomers";

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "new":
      return "warning";
    case "contacted":
      return "info";
    case "in-progress":
      return "primary";
    case "closed-won":
      return "success";
    case "closed-lost":
      return "danger";
    default:
      return "secondary";
  }
};
const CustomersTool = () => {
  const { customers, deleteCustomer, bulkDeleteCustomers } = useCustomers({
    onError: (error) => {
      toast(error.response.data.message, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    },
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useAtom(
    selectedCustomersAtom
  );
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    phoneNumber: "",
    firstName: "",
    lastName: "",
    company: "",
    status: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);

  const filteredCustomers = useMemo(() => {
    if (!customers) return [];
    return customers?.filter((customer) =>
      Object.keys(searchCriteria).every((key) => {
        const searchValue = searchCriteria[key].toLowerCase();
        if (!searchValue) return true;
        const customerValue = customer[key]?.toString().toLowerCase() || "";
        return customerValue.includes(searchValue);
      })
    );
  }, [customers, searchCriteria]);

  const currentCustomers = useMemo(() => {
    return filteredCustomers.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [filteredCustomers, currentPage, rowsPerPage]);

  const totalPages = useMemo(
    () => Math.ceil(filteredCustomers.length / rowsPerPage),
    [filteredCustomers.length, rowsPerPage]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleCustomerModal = () => setCustomerModalOpen(!customerModalOpen);

  const handleEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(true);
  };

  const handleAddCustomer = () => {
    setSelectedCustomer(null);
    setCustomerModalOpen(true);
  };

  const handleSearchChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleDeleteCustomer = async (customerId) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await deleteCustomer(customerId);
        toast.success("Customer deleted successfully!");
      } catch (error) {
        toast(error.response.data.message, {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      }
    }
  };

  const handleDeleteSelectedCustomers = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selectedCustomers.length} customer(s)?`
      )
    ) {
      try {
        setSelectedCustomers([]);
        await bulkDeleteCustomers(selectedCustomers);
        toast.success(
          `${selectedCustomers.length} customer(s) deleted successfully!`
        );
      } catch (error) {
        toast(error.response.data.message, {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });
      }
    }
  };

  const handleSelectAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedCustomers(currentCustomers.map((customer) => customer._id));
      } else {
        setSelectedCustomers([]);
      }
    },
    [currentCustomers, setSelectedCustomers]
  );

  const handleSelectOne = useCallback(
    (customerId) => {
      setSelectedCustomers((prev) =>
        prev.includes(customerId)
          ? prev.filter((id) => id !== customerId)
          : [...prev, customerId]
      );
    },
    [setSelectedCustomers]
  );

  const getPaginationButtons = useCallback(() => {
    const buttons = [];
    const maxButtons = 7;
    const sideButtons = 2; // Number of buttons on each side of current page

    if (totalPages <= maxButtons) {
      // Show all pages if total pages is less than maxButtons
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      // Always show first page
      buttons.push(1);

      // Calculate start and end of middle buttons
      let startPage = Math.max(2, currentPage - sideButtons);
      let endPage = Math.min(totalPages - 1, currentPage + sideButtons);

      // Adjust if current page is near the start
      if (currentPage <= sideButtons + 2) {
        endPage = maxButtons - 2;
      }
      // Adjust if current page is near the end
      else if (currentPage >= totalPages - (sideButtons + 1)) {
        startPage = totalPages - (maxButtons - 2);
      }

      // Add ellipsis after first page if needed
      if (startPage > 2) {
        buttons.push("...");
      }

      // Add middle pages
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      // Add ellipsis before last page if needed
      if (endPage < totalPages - 1) {
        buttons.push("...");
      }

      // Always show last page
      buttons.push(totalPages);
    }

    return buttons;
  }, [currentPage, totalPages]);
  return (
    <Container className="mt-4">
      
      <h3 className="mb-3">Customer List</h3>
      <hr />
      <Card className="mt-4">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
            <h2 className="mb-4">Customers</h2>
              {selectedCustomers.length > 0 && (
                <Button
                  color="danger"
                  size="sm"
                  className="me-2"
                  onClick={handleDeleteSelectedCustomers}
                >
                  Delete Selected ({selectedCustomers.length})
                </Button>
              )}
            </div>
            <Button color="primary" onClick={handleAddCustomer}>
              + Add Customer
            </Button>
          </div>

          <CustomerSearch
            searchCriteria={searchCriteria}
            onSearchChange={handleSearchChange}
          />

          <CustomerTable
            customers={currentCustomers}
            selectedCustomers={selectedCustomers}
            onSelectAll={handleSelectAll}
            onSelectOne={handleSelectOne}
            onEdit={handleEditCustomer}
            onDelete={handleDeleteCustomer}
            getStatusColor={getStatusColor}
          />

          <div className="d-flex justify-content-between align-items-center mt-4">
            <div className="d-flex align-items-center gap-3">
              <div className="text-muted">
                Showing {(currentPage - 1) * rowsPerPage + 1} to{" "}
                {Math.min(currentPage * rowsPerPage, filteredCustomers.length)}{" "}
                of {filteredCustomers.length} entries
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="rowsPerPage" className="text-muted mb-0">
                  Rows per page:
                </label>
                <select
                  id="rowsPerPage"
                  className="form-select form-select-sm"
                  style={{ width: "auto" }}
                  value={rowsPerPage}
                  onChange={(e) => {
                    const newRowsPerPage = Number(e.target.value);
                    setRowsPerPage(newRowsPerPage);
                    // Adjust current page to show correct data
                    const newTotalPages = Math.ceil(
                      filteredCustomers.length / newRowsPerPage
                    );
                    setCurrentPage(Math.min(currentPage, newTotalPages));
                  }}
                >
                  {[5, 10, 20, 50, 100].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Pagination>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <FaArrowLeft />
                </PaginationLink>
              </PaginationItem>
              {getPaginationButtons().map((page, index) => (
                <PaginationItem
                  key={index}
                  active={currentPage === page}
                  disabled={page === "..."}
                >
                  <PaginationLink
                    onClick={() => page !== "..." && handlePageChange(page)}
                    style={{ cursor: page === "..." ? "default" : "pointer" }}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <FaArrowRight />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        </CardBody>
      </Card>
      <CustomerModal
        isOpen={customerModalOpen}
        toggle={toggleCustomerModal}
        customerId={selectedCustomer?._id}
        onSuccess={() => {
          setSelectedCustomer(null);
        }}
      />
    </Container>
  );
};

export default CustomersTool;
