const ScanMe2Frame = ({ children }) => {
	return (
		<div
			className="d-flex flex-column align-items-center"
			style={{ width: "fit-content" }}
		>
			<div
				className="position-relative bg-dark mb-4 fs-2 rounded-4"
				style={{
					color: "#fff",
					padding: "12px 28px",
					fontSize: "18px",
					fontWeight: "bold",
					letterSpacing: "1px",
				}}
			>
				SCAN ME
				<div
					className="position-absolute "
					style={{
						width: "0",
						height: "0",
						borderLeft: "10px solid transparent",
						borderRight: "10px solid transparent",
						borderTop: "10px solid rgb(30,42,53)",
						bottom: "-10px",
						left: "50%",
						transform: "translateX(-50%)",
					}}
				/>
			</div>
			<div
				className="bg-dark p-4 rounded-4"
				style={{
					width: "240px",
					minHeight: "240px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "relative",
				}}
			>
				<div className="bg-white w-100  h-100 d-flex justify-content-center align-items-center">
					{children}
				</div>
			</div>
		</div>
	);
};

export default ScanMe2Frame;
