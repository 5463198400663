import { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ActivateLayout from "./Layouts/ActivateLayout/ActivateLayout";
import AuthLayout from "./Layouts/AuthLayout/AuthLayout";
import DashboardLayout from "./Layouts/DashboardLayout/DashboardLayout";
import PublicToolsLayout from "./Layouts/PublicToolsLayout/PublicToolsLayout";
import ResetLayout from "./Layouts/ResetLayout/ResetLayout";
import TwoFA from "./components/TwoFA/TwoFA";
import { AuthContext } from "./context/AuthContext";
import Account from "./views/ui/Account";
import Billing from "./views/ui/Billing";
import NotFound from "./views/ui/NotFound";
import AddBusinessDetails from "./views/ui/articles/AddBusinessDetails";
import GenerateExpenses from "./views/ui/articles/GenerateExpenses";
import ImportCustomers from "./views/ui/articles/ImportCustomers";
import InvoiceBestPractices from "./views/ui/articles/InvoiceBestPractices";
import SetupBilling from "./views/ui/articles/SetupBilling";
import TrackExpenses from "./views/ui/articles/TrackExpenses";
// import CampaignPage from "./pages/CampaignPage";
// import CampaignDetailPage from "./pages/CampaignDetailPage"
import RegisterWithInvite from "./components/RegisterWithInvite/RegisterWithInvite";
import { useToolRoutes } from "./hooks/useToolRoutes";
import axiosWithAuth from "./utils/axiosWithAuth";
import BusinessDetails from "./views/BusinessDetails/BusinessDetails";
import PublicTools from "./views/tools/PublicTools";
import Tools from "./views/tools/Tools";
import KnowledgeBase from "./views/ui/KnowledgeBase";
import Support from "./views/ui/Support";

function App() {
  const { dispatch, token, isLoggedIn, isAuthenticated } =
    useContext(AuthContext);

  // Get access token
  useEffect(() => {
    const _appSignging = localStorage.getItem("_appSignging");
    const _apprftoken = localStorage.getItem("_apprftoken");
    if (_appSignging) {
      const getToken = async () => {
        try {
          const res = await axiosWithAuth.post("/api/auth/access", {
            _apprftoken: _apprftoken,
          });
          dispatch({
            type: "GET_TOKEN",
            payload: res.data.data.ac_token,
          });
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      };
      getToken();
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        try {
          dispatch({ type: "SIGNING" });
          const res = await axiosWithAuth.get("/api/auth/user");

          const accountRes = await axiosWithAuth.get("/api/auth/account", {
            params: { accountId: res.data.data.account },
          });

          const accountData = accountRes.data.data;

          const updatedUser = {
            ...res.data.data,
            stripeCustomerId: accountData.stripeCustomerId,
            paymentStatus: accountData.payment_status,
          };

          dispatch({ type: "GET_USER", payload: updatedUser });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      getUser();
    }
  }, [dispatch, token]);

  const ToolRoutes = useToolRoutes();

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Tools Layout */}
        <Route path="/ft/tools" element={<PublicToolsLayout />}>
          <Route index element={<PublicTools />} />
          {ToolRoutes}
        </Route>

        {/* Protected Dashboard Layout */}
        <Route
          path="/"
          element={isLoggedIn ? <DashboardLayout /> : <Navigate to="/auth" />}
        >
          <Route index element={<Tools />} />
          {ToolRoutes}
          <Route path="knowledgebase" element={<KnowledgeBase />} />
          <Route path="support" element={<Support />} />
          <Route path="business-details" element={<BusinessDetails />} />
          <Route path="account" element={<Account />} />
          <Route path="billing" element={<Billing />} />
          <Route path="addbusinessdetails" element={<AddBusinessDetails  />} />
          <Route path="generateexpenses" element={<GenerateExpenses />} />
          <Route path="importcustomers" element={<ImportCustomers />} />
          <Route path="invoicebestpractices" element={<InvoiceBestPractices />} />
          <Route path="setupbilling" element={<SetupBilling />} />
          <Route path="trackexpenses" element={<TrackExpenses />} />

        </Route>

        {/* Auth routes */}
        {/*  Use separate route for login/register and 2FA to avoid redirecting to 2FA page when user is not logged in */}
        <Route path="/auth" element={<AuthLayout />} />
        <Route path="/auth/verify" element={<TwoFA />} />
        <Route path="/auth/reset-password/:token" element={<ResetLayout />} />
        <Route
          path="/api/auth/activate/:activation_token"
          element={<ActivateLayout />}
        />
        <Route
          path="api/auth/invite/:activation_token"
          element={<RegisterWithInvite />}
        />
        <Route path="*" element={<NotFound />} />

      
      </Routes>
    </BrowserRouter>
  );
}

export default App;
