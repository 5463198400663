import { format } from "date-fns";
import { useState } from "react";
import {
  Building,
  Calendar,
  Envelope,
  FileText,
  Person,
  Phone,
  Send,
  StickyFill,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { invoiceApi } from "../../../../api/invoiceApi";
import { useInvoice } from "../context/InvoiceContext";

export function ReviewStep({ onPrevious }) {
  const { invoiceData } = useInvoice();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();

  const calculateSubtotal = () => {
    return invoiceData.services.reduce(
      (sum, service) => sum + Number(service.amount),
      0
    );
  };

  const calculateTaxableAmount = () => {
    return invoiceData.services.reduce(
      (sum, service) => sum + (service.taxable ? Number(service.amount) : 0),
      0
    );
  };

  const calculateTax = () => {
    return calculateTaxableAmount() * 0.1; // 10% tax rate
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  const handleSaveDraft = async () => {
    try {
      await invoiceApi.createInvoice(invoiceData);
      toast.success("Invoice saved as draft");
      navigate("/invoices");
    } catch (error) {
      console.error("Error saving invoice:", error);
      toast.error("Failed to save invoice");
    }
  };

  const handleSendInvoice = async () => {
    setIsSending(true);
    try {
      await invoiceApi.sendInvoice(invoiceData);
      toast.success("Invoice sent successfully");
      navigate("/invoices");
    } catch (error) {
      console.error("Error sending invoice:", error);
      toast.error("Failed to send invoice");
    } finally {
      setIsSending(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1 text-3">Review Invoice</h5>
        <p className="text-muted mb-0 text-4">
          Review your invoice details before sending
        </p>
      </div>

      <Row className="g-4 mb-4">
        <Col md={6}>
          <div className="d-flex align-items-center mb-4">
            <div
              className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
              style={{ width: "32px", height: "32px" }}
            >
              <Person size={16} className="text-primary" />
            </div>
            <h6 className="mb-0">Customer Information</h6>
          </div>
          <div className="d-flex flex-column gap-3">
            <div>
              <strong>
                {invoiceData.customer.firstName} {invoiceData.customer.lastName}
              </strong>
            </div>
            {invoiceData.customer.company && (
              <div className="d-flex align-items-center">
                <Building size={14} className="me-2" />
                {invoiceData.customer.company}
              </div>
            )}
            <div className="d-flex align-items-center">
              <Envelope size={14} className="me-2" />
              {invoiceData.customer.email}
            </div>
            {invoiceData.customer.phoneNumber && (
              <div className="d-flex align-items-center">
                <Phone size={14} className="me-2" />
                {invoiceData.customer.phoneNumber}
              </div>
            )}
          </div>
        </Col>

        <Col md={6}>
          <div className="d-flex align-items-center mb-4">
            <div
              className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
              style={{ width: "32px", height: "32px" }}
            >
              <FileText size={16} className="text-primary" />
            </div>
            <h6 className="mb-0">Invoice Details</h6>
          </div>
          <div className="d-flex flex-column gap-3">
            <div>
              <small className="text-muted d-block">Invoice Number</small>
              <div>{invoiceData.details.invoiceNumber}</div>
            </div>
            <div>
              <small className="text-muted d-block">Invoice Date</small>
              <div className="d-flex align-items-center">
                <Calendar size={14} className="me-2" />
                {format(new Date(invoiceData.details.date), "MMMM d, yyyy")}
              </div>
            </div>
            <div>
              <small className="text-muted d-block">Payment Terms</small>
              <div>{invoiceData.details.paymentTerms}</div>
            </div>
            <div>
              <small className="text-muted d-block">Due Date</small>
              <div className="d-flex align-items-center">
                <Calendar size={14} className="me-2" />
                {format(new Date(invoiceData.details.dueDate), "MMMM d, yyyy")}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Card className="shadow-sm border-0 mb-4">
        <CardBody className="p-0">
          <Table hover responsive className="mb-0">
            <thead className="bg-light">
              <tr>
                <th className="py-3" style={{ minWidth: "300px" }}>
                  Description
                </th>
                <th className="text-center py-3" style={{ minWidth: "100px" }}>
                  Quantity
                </th>
                <th className="text-end py-3" style={{ minWidth: "120px" }}>
                  Rate
                </th>
                <th className="text-end py-3" style={{ minWidth: "120px" }}>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.services.map((service) => (
                <tr key={service.id}>
                  <td className="py-3">{service.description}</td>
                  <td className="text-center py-3">{service.quantity}</td>
                  <td className="text-end py-3">
                    ${Number(service.rate).toFixed(2)}
                  </td>
                  <td className="text-end py-3">
                    ${Number(service.amount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-light">
              <tr>
                <td colSpan="3" className="text-end py-3">
                  <strong>Subtotal</strong>
                </td>
                <td className="text-end py-3">
                  <strong>${calculateSubtotal().toFixed(2)}</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-end py-3">
                  <strong>Tax (10%)</strong>
                </td>
                <td className="text-end py-3">
                  <strong>${calculateTax().toFixed(2)}</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="text-end py-3">
                  <strong>Total</strong>
                </td>
                <td className="text-end py-3">
                  <strong>${calculateTotal().toFixed(2)}</strong>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>

      {(invoiceData.details.notes || invoiceData.details.terms) && (
        <Row className="g-4 mb-4">
          {invoiceData.details.notes && (
            <Col md={6}>
              <Card className="shadow-sm border-0">
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <StickyFill size={18} className="me-2 text-primary" />
                    <h6 className="mb-0">Notes</h6>
                  </div>
                  <div className="text-muted">{invoiceData.details.notes}</div>
                </CardBody>
              </Card>
            </Col>
          )}

          {invoiceData.details.terms && (
            <Col md={invoiceData.details.notes ? 6 : 12}>
              <Card className="shadow-sm border-0">
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <FileText size={18} className="me-2 text-primary" />
                    <h6 className="mb-0">Terms and Conditions</h6>
                  </div>
                  <div className="text-muted">{invoiceData.details.terms}</div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}

      <div className="d-flex justify-content-between">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <div className="d-flex gap-2">
          <Button
            color="secondary"
            disabled={!invoiceData.customer}
            outline
            onClick={handleSaveDraft}
          >
            Save as Draft
          </Button>
          <Button
            color="primary"
            disabled={!invoiceData.customer}
            onClick={() => setIsModalOpen(true)}
          >
            Send Invoice
          </Button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        size="md"
      >
        <ModalHeader
          toggle={() => setIsModalOpen(false)}
          className="border-0 pb-0"
        >
          Send Invoice
        </ModalHeader>
        <ModalBody className="pt-0">
          <Alert color="info" className="mb-4">
            <div className="d-flex">
              <div
                className="rounded-circle bg-info bg-opacity-10 d-flex align-items-center justify-content-center me-3 flex-shrink-0"
                style={{ width: "48px", height: "48px" }}
              >
                <Send size={20} className="text-info" />
              </div>
              <div>
                <div className="fw-medium mb-1">Confirm Sending</div>
                <div>
                  The invoice will be sent to{" "}
                  <strong>{invoiceData.customer.email}</strong>. The customer
                  will be notified and can view the invoice online.
                </div>
              </div>
            </div>
          </Alert>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
          <Button
            color="secondary"
            outline
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSendInvoice}
            disabled={isSending}
          >
            {isSending ? (
              <>
                <Spinner size="sm" className="me-2" /> Sending...
              </>
            ) : (
              <>
                <Send size={14} className="me-2" /> Send Invoice
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
