import { createContext, useContext } from "react";

export const InvoiceContext = createContext({
  invoiceData: {
    customer: null,
    services: [],
    details: {
      invoiceNumber: "",
      date: new Date().toISOString(),
      dueDate: "",
      paymentTerms: "Net 30",
      notes: "",
      terms: "",
    },
  },
  updateInvoiceData: () => {},
});

export const useInvoice = () => {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error("useInvoice must be used within an InvoiceProvider");
  }
  return context;
};
