import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useEstimate } from "../context/EstimateContext";

export function DetailsStep({ onNext, onPrevious }) {
  const { estimateData, updateEstimateData } = useEstimate();

  useEffect(() => {
    // Generate estimate number if not already set
    if (!estimateData.details.estimateNumber) {
      const estimateNumber = `EST-${Date.now()}`;
      handleInputChange({
        target: { name: "estimateNumber", value: estimateNumber },
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateEstimateData("details", {
      ...estimateData.details,
      [name]: value,
    });
  };

  const isValid = () => {
    const { estimateNumber, date, expiryDate } = estimateData.details;
    return estimateNumber && date && expiryDate;
  };

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1">Estimate Details</h5>
        <p className="text-muted mb-0">
          Add important details about your estimate
        </p>
      </div>

      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="estimateNumber">Estimate Number</Label>
                <Input
                  id="estimateNumber"
                  name="estimateNumber"
                  value={estimateData.details.estimateNumber}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="date">Date</Label>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  value={estimateData.details.date.split("T")[0]}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="expiryDate">Valid Until</Label>
                <Input
                  type="date"
                  id="expiryDate"
                  name="expiryDate"
                  value={estimateData.details.expiryDate}
                  onChange={handleInputChange}
                  required
                  min={estimateData.details.date.split("T")[0]}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              type="textarea"
              id="notes"
              name="notes"
              value={estimateData.details.notes}
              onChange={handleInputChange}
              rows={4}
              placeholder="Add any additional notes or special instructions..."
            />
          </FormGroup>

          <FormGroup>
            <Label for="terms">Terms and Conditions</Label>
            <Input
              type="textarea"
              id="terms"
              name="terms"
              value={estimateData.details.terms}
              onChange={handleInputChange}
              rows={4}
              placeholder="Add your terms and conditions..."
            />
          </FormGroup>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <Button color="primary" onClick={onNext} disabled={!isValid()}>
          Review Estimate
        </Button>
      </div>
    </div>
  );
}
