import axiosWithAuth from "../../utils/axiosWithAuth";

export const businessDetailsApi = {
  get: async () => {
    const response = await axiosWithAuth.get("/api/business");
    return response.data;
  },

  create: async (data) => {
    const response = await axiosWithAuth.post("/api/business", data);
    return response.data;
  },

  update: async (data) => {
    const response = await axiosWithAuth.put("/api/business", data);
    return response.data;
  },

  uploadLogo: async (file) => {
    const formData = new FormData();
    formData.append("logo", file);
    const response = await axiosWithAuth.post("/api/business/logo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  deleteLogo: async () => {
    const response = await axiosWithAuth.delete("/api/business/logo");
    return response.data;
  },
};
