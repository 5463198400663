import { QRCode } from "react-qrcode-logo";

const QRCodePreview = ({ value, color, logo, qrStyle, size = 200 }) => {
	return (
		<div
			className="bg-white"
			style={{
				width: `${size}px`,
				height: `${size}px`,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{value ? (
				<QRCode
					value={value}
					size={size}
					quietZone={10}
					qrStyle={qrStyle === "squares" ? "squares" : "dots"}
					fgColor={color}
					logoImage={logo}
					logoWidth={size * 0.2}
					logoHeight={size * 0.2}
					logoOpacity={1}
					removeQrCodeBehindLogo={true}
				/>
			) : (
				<p className="text-muted text-center mb-0">
					Enter data and click "Generate QR Code" to see the preview
				</p>
			)}
		</div>
	);
};

export default QRCodePreview;
