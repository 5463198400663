import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useInvoice } from "../context/InvoiceContext";

const PAYMENT_TERMS_OPTIONS = [
  { value: "Net 30", label: "Net 30 (Due in 30 days)" },
  { value: "Net 15", label: "Net 15 (Due in 15 days)" },
  { value: "Net 7", label: "Net 7 (Due in 7 days)" },
  { value: "Due on Receipt", label: "Due on Receipt" },
  { value: "Custom", label: "Custom" },
];

export function DetailsStep({ onNext, onPrevious }) {
  const { invoiceData, updateInvoiceData } = useInvoice();

  useEffect(() => {
    // Generate invoice number if not already set
    if (!invoiceData.details.invoiceNumber) {
      const invoiceNumber = `INV-${Date.now()}`;
      handleInputChange({
        target: { name: "invoiceNumber", value: invoiceNumber },
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateInvoiceData("details", {
      ...invoiceData.details,
      [name]: value,
    });

    // Update due date based on payment terms
    if (name === "paymentTerms" && value !== "Custom") {
      const dueDate = new Date();
      switch (value) {
        case "Net 30":
          dueDate.setDate(dueDate.getDate() + 30);
          break;
        case "Net 15":
          dueDate.setDate(dueDate.getDate() + 15);
          break;
        case "Net 7":
          dueDate.setDate(dueDate.getDate() + 7);
          break;
        case "Due on Receipt":
          // Due date is today
          break;
        default:
          break;
      }
      updateInvoiceData("details", {
        ...invoiceData.details,
        [name]: value,
        dueDate: dueDate.toISOString().split("T")[0],
      });
    }
  };

  const isValid = () => {
    const { invoiceNumber, date, dueDate, paymentTerms } = invoiceData.details;
    return invoiceNumber && date && dueDate && paymentTerms;
  };

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-1">Invoice Details</h5>
        <p className="text-muted mb-0">
          Add important details about your invoice
        </p>
      </div>

      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="invoiceNumber">Invoice Number</Label>
                <Input
                  id="invoiceNumber"
                  name="invoiceNumber"
                  value={invoiceData.details.invoiceNumber}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="date">Invoice Date</Label>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  value={invoiceData.details.date.split("T")[0]}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="paymentTerms">Payment Terms</Label>
                <Input
                  type="select"
                  id="paymentTerms"
                  name="paymentTerms"
                  value={invoiceData.details.paymentTerms}
                  onChange={handleInputChange}
                  required
                >
                  {PAYMENT_TERMS_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="dueDate">Due Date</Label>
                <Input
                  type="date"
                  id="dueDate"
                  name="dueDate"
                  value={invoiceData.details.dueDate}
                  onChange={handleInputChange}
                  required
                  min={invoiceData.details.date.split("T")[0]}
                  disabled={invoiceData.details.paymentTerms !== "Custom"}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              type="textarea"
              id="notes"
              name="notes"
              value={invoiceData.details.notes}
              onChange={handleInputChange}
              rows={4}
              placeholder="Add any additional notes or special instructions..."
            />
          </FormGroup>

          <FormGroup>
            <Label for="terms">Terms and Conditions</Label>
            <Input
              type="textarea"
              id="terms"
              name="terms"
              value={invoiceData.details.terms}
              onChange={handleInputChange}
              rows={4}
              placeholder="Add your payment terms and conditions..."
            />
          </FormGroup>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between mt-4">
        <Button color="secondary" outline onClick={onPrevious}>
          Previous Step
        </Button>
        <Button color="primary" onClick={onNext} disabled={!isValid()}>
          Review Invoice
        </Button>
      </div>
    </div>
  );
}
