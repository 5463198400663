import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const GenerateExpenses = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          Generating Expense Reports in HelperFox
        </CardTitle>

        <CardText>
          Get insights into your spending with expense reports. Here’s how to generate them.
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          How to Generate an Expense Report:
        </CardTitle>
        <CardText>
          <ul>
            <li>Go to **Reports** and select **Expense Report**.</li>
            <li>Choose a **date range** and expense categories.</li>
            <li>Review the summary and **export as PDF or CSV**.</li>
            <li>Use reports to optimize spending and tax preparation.</li>
          </ul>
        </CardText>

        <CardText>
          Accurate reporting helps keep your finances under control.
        </CardText>
      </CardBody>
    </Card>
  );
};

export default GenerateExpenses;
