import { useState } from "react";
import { Card, CardBody, Container, Progress } from "reactstrap";
import { CustomerStep } from "./components/CustomerStep";
import { DetailsStep } from "./components/DetailsStep";
import { ReviewStep } from "./components/ReviewStep";
import { ServicesStep } from "./components/ServicesStep";
import { EstimateContext } from "./context/EstimateContext";

const STEPS = [
  {
    id: 1,
    title: "Customer",
    component: CustomerStep,
    description: "Select or create a new customer",
  },
  {
    id: 2,
    title: "Services",
    component: ServicesStep,
    description: "Add services to your estimate",
  },
  {
    id: 3,
    title: "Details",
    component: DetailsStep,
    description: "Fill in estimate details and terms",
  },
  {
    id: 4,
    title: "Review",
    component: ReviewStep,
    description: "Review and send your estimate",
  },
];

// Helper function to generate estimate number
const generateEstimateNumber = () => {
  return `EST-${Date.now()}`;
};

// Helper function to get default expiry date (30 days from now)
const getDefaultExpiryDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  return date.toISOString();
};

export function EstimateGenerator() {
  const [currentStep, setCurrentStep] = useState(1);
  const [estimateData, setEstimateData] = useState({
    customer: null,
    services: [],
    details: {
      estimateNumber: generateEstimateNumber(),
      date: new Date().toISOString(),
      expiryDate: getDefaultExpiryDate(),
      notes: "",
      terms: "",
    },
  });

  const progress = (currentStep / STEPS.length) * 100;
  const CurrentStepComponent = STEPS[currentStep - 1].component;

  const handleNext = () => {
    if (currentStep < STEPS.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateEstimateData = (section, data) => {
    setEstimateData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  return (
    <EstimateContext.Provider value={{ estimateData, updateEstimateData }}>
      <Container fluid className="py-4">
        <div className="d-flex flex-column flex-md-row gap-4">
          {/* Left sidebar with steps */}
          <div style={{ minWidth: "280px" }}>
            <Card className="shadow-sm position-sticky" style={{ top: "1rem" }}>
              <CardBody className="p-0">
                <div className="p-4 bg-primary bg-opacity-10 border-bottom">
                  <h5 className="mb-1">Create Estimate</h5>
                  <small className="text-muted">
                    Step {currentStep} of {STEPS.length}
                  </small>
                  <Progress
                    value={progress}
                    className="mt-3"
                    style={{ height: "4px" }}
                  />
                </div>
                <div className="py-2">
                  {STEPS.map((step) => (
                    <div
                      key={step.id}
                      className={`d-flex align-items-center p-3 border-bottom cursor-pointer ${
                        currentStep === step.id ? "bg-light" : ""
                      }`}
                      onClick={() => setCurrentStep(step.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className={`rounded-circle d-flex align-items-center justify-content-center me-3 ${
                          currentStep >= step.id ? "bg-primary" : "bg-secondary"
                        }`}
                        style={{
                          width: "28px",
                          height: "28px",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        {step.id}
                      </div>
                      <div>
                        <div
                          className={`fw-medium ${
                            currentStep >= step.id ? "text-primary" : ""
                          }`}
                        >
                          {step.title}
                        </div>
                        <small className="text-muted d-block">
                          {step.description}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* Main content area */}
          <div className="flex-grow-1">
            <Card className="shadow-sm">
              <CardBody>
                <CurrentStepComponent
                  onNext={handleNext}
                  onPrevious={handlePrevious}
                  isFirstStep={currentStep === 1}
                  isLastStep={currentStep === STEPS.length}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </EstimateContext.Provider>
  );
}
