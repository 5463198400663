import { toast } from "react-toastify";
import axiosWithAuth from "../../utils/axiosWithAuth";

export const getAllUsers = async () => {
  try {
    const response = await axiosWithAuth.get("api/auth/user/get_all_user");
    return response.data;
  } catch (error) {
    console.error("Error fetching members:", error.message);
    toast.error(
      error.response?.data?.message || "Failed to fetch team members",
      {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      }
    );
  }
};

export const getUser = async () => {
  try {
    const response = await axiosWithAuth.get("api/auth/user");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.message || "Failed to fetch user", {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axiosWithAuth.patch(
      `api/auth/update/${userId}`,
      userData
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
    throw new Error(errorMessage);
  }
};

export const updateUserAvatar = async (userId, avatarUrl) => {
  try {
    const response = await axiosWithAuth.patch(`api/auth/updateurl/${userId}`, {
      avatar: avatarUrl,
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
    throw new Error(errorMessage);
  }
};

export const registerUserWithInvitation = async (userData, activationToken) => {
  try {
    const response = await axiosWithAuth.post(
      "api/auth/register_with_invitation",
      {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email.toLowerCase(),
        phone_number: userData.phone_number,
        password: userData.password,
        invitation_token: activationToken,
      },
      {
        headers: {
          Authorization: `Bearer ${activationToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
    throw new Error(errorMessage);
  }
};

export const getRegisteredTFN = async (accountId) => {
  try {
    const response = await axiosWithAuth.get(
      `api/compliance/tfn/registered/${accountId}`
    );
    return response.data || [];
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch registered TFNs";
    toast.error(errorMessage, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
    return [];
  }
};

export const getPhoneNumbers = async (account) => {
  try {
    const res = await axiosWithAuth.get(`api/phoneNumberList/${account}`);
    return res.data || [];
  } catch (err) {
    toast.error(
      err.response?.data?.message || "Failed to fetch phone numbers",
      {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      }
    );
    return [];
  }
};

export const getLinkedPhoneNumbers = async () => {
  try {
    const res = await axiosWithAuth.get(`api/phone_number_link`);
    return res.data || [];
  } catch (err) {
    toast.error(
      err.response?.data?.message || "Failed to fetch linked phone numbers",
      {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      }
    );
    return [];
  }
};

export const updateTeamMember = async (teamMember, token) => {
  try {
    const userId = teamMember?._id;
    await axiosWithAuth.put(
      `api/auth/user/update_user_profile/${userId}`,
      {
        first_name: teamMember.first_name,
        last_name: teamMember.last_name,
        email: teamMember.email,
        roles: teamMember.roles,
        phone_number: teamMember.phone_number,
        permissions: teamMember.permissions,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success("Team member details updated successfully.", {
      className: "toast-success",
      bodyClassName: "toast-success",
    });
  } catch (err) {
    if (err.response?.statusCode === 403) {
      toast.error("Access denied: Only admins can update user information.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    } else {
      const errorMessage = err.response?.data?.message || err.message;
      toast.error(`Failed to update team member: ${errorMessage}`, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  }
};

export const uploadPhoto = async (selectedFile, token) => {
  try {
    const formData = new FormData();
    formData.append("avatar", selectedFile);

    const response = await axiosWithAuth.post(
      `api/auth/uploadPhoto`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
    throw new Error(errorMessage);
  }
};

export const deleteTeamMember = async (userId) => {
  try {
    await axiosWithAuth.delete(`api/auth/user/delete_user_profile/${userId}`);

    toast.success("User deleted successfully.", {
      className: "toast-success",
      bodyClassName: "toast-success",
    });
  } catch (err) {
    if (err.response?.statusCode === 403) {
      toast.error("Access denied: Only admins can delete user accounts", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    } else {
      const errorMessage = err.response?.data?.message || err.message;
      toast.error(`Failed to delete user: ${errorMessage}`, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  }
};

export const resetPassword = async (password, token) => {
  try {
    const res = await axiosWithAuth.post(
      "/api/auth/reset_pass",
      { password },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.success(res.data.message, {
      className: "toast-success",
      bodyClassName: "toast-success",
    });
  } catch (err) {
    toast.error(err.response?.data?.message || "Failed to reset password", {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
  }
};

export const sendInvite = async (data) => {
  try {
    await axiosWithAuth.post("/api/auth/invite_member", data);

    toast.success(
      "The invite has been sent over email. You will be notified when the team member activates their account.",
      {
        className: "toast-success",
        bodyClassName: "toast-success",
      }
    );
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      className: "toast-failed",
      bodyClassName: "toast-failed",
    });
  }
};
