import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Spinner, // Import the Spinner component for the loader
  Table,
} from "reactstrap";
import API_BASE_URL from "../../apiConfig";
import { useAuthContext } from "../../hooks/useAuthContext";
import axiosWithAuth from "../../utils/axiosWithAuth";

const History = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { user } = useAuthContext();

  const stripeCustomerId = user.stripeCustomerId;

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setIsLoading(true); // Set loading to true before fetching data
        const response = await axiosWithAuth.get(
          `${API_BASE_URL}/api/auth/invoices`,
          {
            params: { stripeCustomerId },
          }
        );
        const invoiceData = response.data.data;
        setInvoices(invoiceData);
      } catch (error) {
        console.error("Error fetching invoices:", error);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchInvoices();
  }, [stripeCustomerId]);

  return (
    <Card>
      <CardBody className="custom-card-body">
        <h4>Payment History</h4>
        <hr />
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {isLoading ? (
            // Display a spinner while loading
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
              <p>Loading...</p>
            </div>
          ) : (
            // Render the table once data is loaded
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{new Date(invoice.created * 1000).toLocaleString()}</td>
                    <td>{(invoice.amount / 100).toFixed(2)}</td>
                    <td>{invoice.currency.toUpperCase()}</td>
                    <td>{invoice.status}</td>
                    <td>{invoice.type}</td>
                    <td>
                      {invoice.hosted_invoice_url ? (
                        <a
                          href={invoice.hosted_invoice_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Invoice
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default History;
