import React from "react";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Card,
	CardBody,
	CardTitle,
} from "reactstrap";

const faqData = [
	{
		question: "What types of QR codes can I create?",
		answer: "You can create QR codes for URLs, text, vCards, email, SMS, WiFi, Twitter, Bitcoin, phone calls, social media profiles, and PDFs.",
	},
	{
		question: "Can I customize the appearance of my QR code?",
		answer: "Yes, you can change the color, style (dots or squares), and add a logo to your QR code.",
	},
	{
		question: "How do I save my QR code?",
		answer: "After generating your QR code, use the 'Download JPG' button to save it as an image file.",
	},
	{
		question: "Can I edit a QR code after creating it?",
		answer: "Yes, you can modify the content and appearance of your QR code at any time. Simply make your changes and regenerate the QR code.",
	},
	{
		question: "Are there any size limitations for the QR code?",
		answer: "The generated QR codes are designed to be easily scannable. However, if you're planning to print the QR code, ensure it's at least 2 x 2 cm (0.8 x 0.8 inches) for reliable scanning.",
	},
];

const InstructionsAndFAQ = () => {
	const [openFAQ, setOpenFAQ] = React.useState("");
	const toggleFAQ = (id) => {
		setOpenFAQ(openFAQ === id ? "" : id);
	};

	return (
		<div className="mt-4">
			<Card className="mb-4">
				<CardBody>
					<CardTitle tag="h5">Instructions</CardTitle>
					<ol>
						<li>
							Choose the type of QR code you want to create from
							the navigation grid.
						</li>
						<li>Fill in the required information in the form.</li>
						<li>
							Customize your QR code using the options in the
							preview panel.
						</li>
						<li>
							Click the "Generate QR Code" button to create your
							QR code.
						</li>
						<li>
							Use the download button to save your QR code as a
							JPG file.
						</li>
					</ol>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">Frequently Asked Questions</CardTitle>
					<Accordion open={openFAQ} toggle={toggleFAQ}>
						{faqData.map((faq, index) => (
							<AccordionItem key={index}>
								<AccordionHeader targetId={index.toString()}>
									{faq.question}
								</AccordionHeader>
								<AccordionBody accordionId={index.toString()}>
									{faq.answer}
								</AccordionBody>
							</AccordionItem>
						))}
					</Accordion>
				</CardBody>
			</Card>
		</div>
	);
};

export default InstructionsAndFAQ;
