import {
  ArrowDown,
  ArrowUp,
  Calendar3,
  CreditCard,
  FileText,
  Search,
} from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Card, Input, InputGroup, Table } from "reactstrap";
import useSWR from "swr";
import axiosWithAuth from "../../utils/axiosWithAuth";
import "./RecentTransactions.css";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return `Today, ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
  } else if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday, ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`;
  } else {
    return date.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      year: date.getFullYear() !== today.getFullYear() ? "numeric" : undefined,
    });
  }
};

const TransactionIcon = ({ type }) => {
  switch (type) {
    case "Invoice":
      return <FileText className="text-primary" size={20} />;
    case "Expense":
      return <CreditCard className="text-danger" size={20} />;
    default:
      return null;
  }
};

const TransactionAmount = ({ type, amount }) => {
  const Icon = type === "Invoice" ? ArrowUp : ArrowDown;
  const color = type === "Invoice" ? "success" : "danger";
  return (
    <div
      className={`d-flex align-items-center justify-content-end text-${color} fw-semibold`}
    >
      <Icon className="me-2" />
      {formatCurrency(amount)}
    </div>
  );
};

const TransactionStatus = ({ type, status }) => {
  let color = "secondary";

  if (type === "Invoice") {
    switch (status) {
      case "Paid":
        color = "success";
        break;
      case "Viewed":
        color = "info";
        break;
      case "Sent":
        color = "primary";
        break;
      case "Draft":
        color = "secondary";
        break;
      default:
        color = "secondary";
    }
  } else {
    switch (status) {
      case "Completed":
        color = "success";
        break;
      case "Pending":
        color = "warning";
        break;
      case "Failed":
        color = "danger";
        break;
      default:
        color = "secondary";
    }
  }

  return (
    <Badge color={color} className="rounded-pill" pill>
      {status}
    </Badge>
  );
};

const RecentTransactions = () => {
  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    "/api/transactions/recent",
    async (url) => {
      try {
        const response = await axiosWithAuth.get(url);
        return response.data;
      } catch (error) {
        throw error.response?.data || error.message;
      }
    },
    {
      refreshInterval: 5 * 60 * 1000,
      revalidateOnFocus: false,
    }
  );

  const handleTransactionClick = (transaction) => {
    if (transaction.type === "Invoice") {
      navigate(`/invoices/${transaction.id}`);
    } else {
      navigate(`/expenses?id=${transaction.id}`);
    }
  };

  if (isLoading) {
    return (
      <Card className="border-0 shadow-sm">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="card-title mb-0">Recent Transactions</h5>
          </div>
          <div className="text-center p-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="border-0 shadow-sm">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="card-title mb-0">Recent Transactions</h5>
          </div>
          <div className="text-center p-4">
            <p className="text-danger mb-0">Error loading transactions</p>
            <Button
              color="link"
              className="mt-2"
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  if (!data?.transactions?.length) {
    return (
      <Card className="border-0 shadow-sm">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="card-title mb-0">Recent Transactions</h5>
          </div>
          <div className="text-center p-4">
            <p className="text-muted mb-3">No recent transactions found</p>
            <div className="d-flex justify-content-center gap-2">
              <Link to="/invoices/new">
                <Button color="primary" outline>
                  <FileText className="me-2" />
                  Create Invoice
                </Button>
              </Link>
              <Link to="/expenses/new">
                <Button color="danger" outline>
                  <CreditCard className="me-2" />
                  Add Expense
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="border-0 shadow-sm">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h5 className="card-title mb-1">Recent Transactions</h5>
            <small className="text-muted">
              Last updated: {formatDate(new Date().toISOString())}
            </small>
          </div>
          <div className="d-flex gap-2">
            <Link to="/invoices">
              <Button color="primary" outline size="sm">
                <FileText className="me-1" />
                Invoices
              </Button>
            </Link>
            <Link to="/expenses">
              <Button color="danger" outline size="sm">
                <CreditCard className="me-1" />
                Expenses
              </Button>
            </Link>
          </div>
        </div>

        <div className="mb-3">
          <InputGroup size="sm">
            <Input
              placeholder="Search transactions..."
              className="border-end-0"
            />
            <Button color="light" className="border border-start-0">
              <Search size={14} />
            </Button>
          </InputGroup>
        </div>

        <div className="table-responsive">
          <Table hover className="mb-0 align-middle">
            <thead className="bg-light">
              <tr>
                <th className="border-0">Type</th>
                <th className="border-0">Description</th>
                <th className="border-0">Status</th>
                <th className="border-0">Date</th>
                <th className="border-0 text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.transactions.map((transaction) => (
                <tr
                  key={transaction.id}
                  onClick={() => handleTransactionClick(transaction)}
                  className="hover-shadow"
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <TransactionIcon type={transaction.type} />
                  </td>
                  <td>
                    <div className="fw-medium">{transaction.description}</div>
                    <small className="text-muted">#{transaction.id}</small>
                  </td>
                  <td>
                    <TransactionStatus
                      type={transaction.type}
                      status={transaction.status}
                    />
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-muted">
                      <Calendar3 size={14} className="me-1" />
                      {formatDate(transaction.date)}
                    </div>
                  </td>
                  <td className="text-end">
                    <TransactionAmount
                      type={transaction.type}
                      amount={transaction.amount}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

export default RecentTransactions;
