import { useLayoutEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button, Container, Nav, Navbar, NavItem } from "reactstrap";
import NavLogo from "../../assets/images/logos/helperfox-logo.png";
import { useAuth } from "../../context/AuthContext";
import "./PublicToolsLayout.css";

const PublicToolsLayout = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <div className="public-tools-layout">
      <Navbar
        className="border-bottom px-10 py-3"
        color="white"
        container
        light
      >
        <div className="d-flex align-items-center gap-3">
          <Link to="/" className="navbar-brand d-flex align-items-center gap-3">
            <img src={NavLogo} alt="HelperFox" height="32" />
          </Link>
          <a href="https://helperfox.com" className="back-link">
            Back to Main Site
          </a>
        </div>
        <Nav className="ms-auto gap-4 align-items-center">
          <NavItem>
            <Button color="primary" tag={Link} to="/auth" className="me-2">
              Sign up
            </Button>
          </NavItem>
          <NavItem>
            <Button color="light" tag={Link} to="/auth">
              Log in
            </Button>
          </NavItem>
        </Nav>
      </Navbar>

      <div className="page-content py-5">
        <Container>
          <Outlet />
        </Container>
      </div>

      <footer className="footer border-top py-4 mt-auto">
        <Container>
          <div className="row align-items-center">
            <div className="col-md-6">
              <p className="mb-0">
                © {new Date().getFullYear()} HelperFox. All rights reserved.
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <Nav className="justify-content-md-end">
                <Link to="/privacy" className="nav-link px-2">
                  Privacy Policy
                </Link>
                <Link to="/terms" className="nav-link px-2">
                  Terms of Service
                </Link>
                <Link to="/contact" className="nav-link px-2">
                  Contact
                </Link>
              </Nav>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default PublicToolsLayout;
