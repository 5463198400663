import { useState } from "react";
import {
  ArrowLeft,
  Building,
  Calendar,
  Download,
  Envelope,
  FileText,
  Person,
  Phone,
  Send,
  ThreeDots,
  Trash,
} from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import useSWR from "swr";
import { invoiceApi } from "../../../../api/invoiceApi";
import { useAuth } from "../../../../context/AuthContext";

const STATUS_COLORS = {
  Draft: "secondary",
  Sent: "primary",
  Viewed: "info",
  Accepted: "success",
  Declined: "danger",
  Paid: "success",
};

export function InvoiceView() {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { invoiceId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  const {
    data: invoice,
    error,
    mutate,
  } = useSWR(["invoice", invoiceId], () => invoiceApi.getInvoice(invoiceId), {
    revalidateOnFocus: false,
  });

  const handleDelete = async () => {
    try {
      await invoiceApi.deleteInvoice(invoiceId);
      toast.success("Invoice deleted successfully");
      navigate("/invoices");
    } catch (err) {
      toast.error("Failed to delete invoice");
    }
  };

  const handleSendNotification = async () => {
    setIsSendingNotification(true);
    try {
      await invoiceApi.sendNotification(invoiceId);
      toast.success("Notification sent successfully");
      mutate();
    } catch (err) {
      toast.error("Failed to send notification");
    } finally {
      setIsSendingNotification(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  if (!invoice && !error) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <div className="text-center">
          <Spinner color="primary" className="mb-3" />
          <div className="text-muted">Loading invoice...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-5">
        <FileText size={48} className="text-danger mb-3" />
        <h5 className="text-danger mb-1">Failed to load invoice</h5>
        <p className="text-muted mb-4">{error.message}</p>
        <Button
          color="primary"
          outline
          onClick={() => navigate("/invoices")}
          size="lg"
          className="px-4"
        >
          <ArrowLeft size={18} className="me-2" />
          Back to Invoices
        </Button>
      </div>
    );
  }

  return (
    <Container fluid className="py-4">
      <Card className="shadow-sm mb-4">
        <CardBody>
          <Row className="align-items-center mb-4">
            <Col>
              <Button
                color="link"
                className="p-0 text-muted"
                onClick={() => navigate("/invoices")}
              >
                <ArrowLeft size={18} className="me-2" />
                Back to Invoices
              </Button>
            </Col>
            <Col xs="auto">
              <div className="d-flex gap-2">
                <Button
                  color="primary"
                  outline
                  onClick={handleSendNotification}
                  disabled={isSendingNotification}
                  size="lg"
                >
                  {isSendingNotification ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Sending...
                    </>
                  ) : (
                    <>
                      <Send size={18} className="me-2" />
                      Send Reminder
                    </>
                  )}
                </Button>
                <Button color="primary" outline size="lg">
                  <Download size={18} className="me-2" />
                  Download PDF
                </Button>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                >
                  <DropdownToggle
                    color="link"
                    className="btn-lg p-0 text-muted"
                  >
                    <ThreeDots size={24} />
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem
                      className="text-danger"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      <Trash size={14} className="me-2" />
                      Delete Invoice
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={6}>
              <h4 className="mb-2">Invoice #{invoice.invoiceNumber}</h4>
              <Badge
                color={STATUS_COLORS[invoice.status]}
                pill
                className="px-3 py-2"
              >
                {invoice.status}
              </Badge>
            </Col>
            <Col md={6} className="text-md-end">
              <div className="text-muted mb-1">Amount Due</div>
              <h3 className="mb-0">{formatCurrency(invoice.total)}</h3>
            </Col>
          </Row>

          <Row className="g-4 mb-4">
            <Col md={6}>
              <Card className="shadow-sm border-0 h-100">
                <CardBody>
                  <div className="d-flex align-items-center mb-4">
                    <div
                      className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <Person size={20} className="text-primary" />
                    </div>
                    <div>
                      <h6 className="mb-1">Customer Information</h6>
                      <div className="text-muted">Billing details</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div>
                      <strong className="d-block mb-1">
                        {invoice.clientName}
                      </strong>
                      {invoice.company && (
                        <div className="d-flex align-items-center text-muted">
                          <Building size={14} className="me-2" />
                          {invoice.company}
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center text-muted">
                      <Envelope size={14} className="me-2" />
                      {invoice.email}
                    </div>
                    {invoice.phoneNumber && (
                      <div className="d-flex align-items-center text-muted">
                        <Phone size={14} className="me-2" />
                        {invoice.phoneNumber}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="shadow-sm border-0 h-100">
                <CardBody>
                  <div className="d-flex align-items-center mb-4">
                    <div
                      className="rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center me-3"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <FileText size={20} className="text-primary" />
                    </div>
                    <div>
                      <h6 className="mb-1">Invoice Details</h6>
                      <div className="text-muted">Payment information</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div>
                      <small className="text-muted d-block mb-1">
                        Invoice Date
                      </small>
                      <div className="d-flex align-items-center">
                        <Calendar size={14} className="me-2" />
                        {formatDate(invoice.invoiceDate)}
                      </div>
                    </div>
                    <div>
                      <small className="text-muted d-block mb-1">
                        Due Date
                      </small>
                      <div className="d-flex align-items-center">
                        <Calendar size={14} className="me-2" />
                        {formatDate(invoice.dueDate)}
                      </div>
                    </div>
                    <div>
                      <small className="text-muted d-block mb-1">
                        Payment Terms
                      </small>
                      <div>{invoice.paymentTerms}</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card className="shadow-sm border-0 mb-4">
            <CardBody className="p-0">
              <Table hover responsive className="mb-0">
                <thead className="bg-light">
                  <tr>
                    <th className="py-3" style={{ minWidth: "300px" }}>
                      Description
                    </th>
                    <th
                      className="text-center py-3"
                      style={{ minWidth: "100px" }}
                    >
                      Quantity
                    </th>
                    <th className="text-end py-3" style={{ minWidth: "120px" }}>
                      Rate
                    </th>
                    <th className="text-end py-3" style={{ minWidth: "120px" }}>
                      Amount
                    </th>
                    <th
                      className="text-center py-3"
                      style={{ minWidth: "100px" }}
                    >
                      Taxable
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.lineItems.map((item, index) => (
                    <tr key={index}>
                      <td className="py-3">
                        <div className="fw-medium">{item.description}</div>
                      </td>
                      <td className="text-center py-3">{item.quantity}</td>
                      <td className="text-end py-3">
                        {formatCurrency(item.price)}
                      </td>
                      <td className="text-end py-3">
                        {formatCurrency(item.quantity * item.price)}
                      </td>
                      <td className="text-center py-3">
                        {item.taxable ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-light">
                  <tr>
                    <td colSpan="3" className="text-end py-3">
                      <strong>Subtotal</strong>
                    </td>
                    <td className="text-end py-3">
                      <strong>{formatCurrency(invoice.subtotal)}</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-end py-3">
                      <strong>Tax (10%)</strong>
                    </td>
                    <td className="text-end py-3">
                      <strong>{formatCurrency(invoice.tax)}</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-end py-3">
                      <strong>Total</strong>
                    </td>
                    <td className="text-end py-3">
                      <strong>{formatCurrency(invoice.total)}</strong>
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </CardBody>
          </Card>

          {(invoice.notes || invoice.terms) && (
            <Row className="g-4">
              {invoice.notes && (
                <Col md={6}>
                  <Card className="shadow-sm border-0">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <FileText size={18} className="me-2 text-primary" />
                        <h6 className="mb-0">Notes</h6>
                      </div>
                      <div className="text-muted">{invoice.notes}</div>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {invoice.terms && (
                <Col md={invoice.notes ? 6 : 12}>
                  <Card className="shadow-sm border-0">
                    <CardBody>
                      <div className="d-flex align-items-center mb-3">
                        <FileText size={18} className="me-2 text-primary" />
                        <h6 className="mb-0">Terms and Conditions</h6>
                      </div>
                      <div className="text-muted">{invoice.terms}</div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(false)}
        size="md"
      >
        <ModalHeader
          toggle={() => setIsDeleteModalOpen(false)}
          className="border-0 pb-0"
        >
          Delete Invoice
        </ModalHeader>
        <ModalBody className="pt-0">
          <Alert color="danger" className="mb-4">
            <div className="d-flex">
              <div
                className="rounded-circle bg-danger bg-opacity-10 d-flex align-items-center justify-content-center me-3 flex-shrink-0"
                style={{ width: "48px", height: "48px" }}
              >
                <Trash size={20} className="text-danger" />
              </div>
              <div>
                <div className="fw-medium mb-1">Confirm Deletion</div>
                <div>
                  Are you sure you want to delete invoice{" "}
                  <strong>#{invoice.invoiceNumber}</strong>? This action cannot
                  be undone.
                </div>
              </div>
            </div>
          </Alert>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
          <Button
            color="secondary"
            outline
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button color="danger" onClick={handleDelete}>
            <Trash size={14} className="me-2" />
            Delete Invoice
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
