import { useAtom } from "jotai";
import { toast } from "react-toastify";
import useSWR from "swr";
import { customersAtom } from "../atoms/customerAtoms";
import config from "../config";
import { useAuth } from "../context/AuthContext";
import axiosWithAuth from "../utils/axiosWithAuth";

const API_URL = `${config.apiDomain}/api/customer`;

const useCustomers = ({ onError } = {}) => {
  const { token } = useAuth();
  const [customers, setCustomers] = useAtom(customersAtom);

  const fetcher = async (url) => {
    const response = await axiosWithAuth.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  };

  const { error, isLoading, mutate } = useSWR(token ? API_URL : null, fetcher, {
    onSuccess: (data) => {
      setCustomers(data);
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  const createCustomer = async (newCustomer) => {
    try {
      // Optimistically add the new customer
      const optimisticCustomer = { ...newCustomer, _id: Date.now().toString() };
      const updatedCustomers = [...customers, optimisticCustomer];

      // Update local state and trigger revalidation
      setCustomers(updatedCustomers);
      mutate(updatedCustomers, false);

      const response = await axiosWithAuth.post(
        `${API_URL}/create`,
        newCustomer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Trigger revalidation to get the actual server data
      mutate();
      return response.data;
    } catch (error) {
      // Rollback on error
      mutate();
      throw error;
    }
  };

  const updateCustomer = async (customerId, updatedData) => {
    try {
      // Update local cache first with new values
      const updatedCustomers = customers.map((customer) =>
        customer._id === customerId ? { ...customer, ...updatedData } : customer
      );

      // Update local state and trigger revalidation
      setCustomers(updatedCustomers);
      mutate(updatedCustomers, false);

      const response = await axiosWithAuth.put(
        `${API_URL}/update/${customerId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Trigger revalidation to get the actual server data
      mutate();
      return response.data;
    } catch (error) {
      // Rollback on error
      mutate();
      toast(error.response.data.message, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };

  const deleteCustomer = async (customerId) => {
    try {
      // Optimistically remove the customer
      const updatedCustomers = customers.filter(
        (customer) => customer._id !== customerId
      );

      // Update local state and trigger revalidation
      setCustomers(updatedCustomers);
      mutate(updatedCustomers, false);

      await axiosWithAuth.delete(`${API_URL}/delete/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Trigger revalidation to get the actual server data
      mutate();
    } catch (error) {
      // Rollback on error
      mutate();
      throw error;
    }
  };

  const bulkDeleteCustomers = async (customerIds) => {
    try {
      // Optimistically remove the customers
      const updatedCustomers = customers.filter(
        (customer) => !customerIds.includes(customer._id)
      );

      // Update local state and trigger revalidation
      setCustomers(updatedCustomers);
      mutate(updatedCustomers, false);

      await Promise.all(
        customerIds.map((id) =>
          axiosWithAuth.delete(`${API_URL}/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
      );

      // Trigger revalidation to get the actual server data
      mutate();
    } catch (error) {
      // Rollback on error
      mutate();
      throw error;
    }
  };

  return {
    customers,
    isLoading,
    error,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    bulkDeleteCustomers,
    mutateCustomers: mutate,
  };
};

export { useCustomers };
