import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const SetupBilling = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h1" className="mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
          Setting Up Billing for Smooth Transactions
        </CardTitle>

        <CardText>
          Proper billing setup ensures you get paid on time. Here’s how to configure it in HelperFox.
        </CardText>

        <CardTitle tag="h3" className="mb-3" style={{ color: '#007bff' }}>
          Steps to Set Up Billing:
        </CardTitle>
        <CardText>
          <ol>
            <li>Go to **Settings** and click on **Billing & Payments**.</li>
            <li>Choose your preferred **payment methods** (Bank, PayPal, Stripe, etc.).</li>
            <li>Set **payment terms and late fees**.</li>
            <li>Save settings and start invoicing seamlessly.</li>
          </ol>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default SetupBilling;
